import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

// For fetching security questions
export const fetchSecurityQuestionsRequest = (payload: dataTypes.FetchSecurityQuestionsPayload) => ({
    type: actionTypes.FETCH_SECURITY_QUESTIONS_REQUEST,
    payload: payload,
});

export const fetchSecurityQuestionsSuccess = (data: dataTypes.SecurityQuestions[]) => ({
    type: actionTypes.FETCH_SECURITY_QUESTIONS_SUCCESS,
    payload: data,
});