import React, { useEffect, useState } from "react";
import {
  Box,
  Avatar,
  IconButton,
  Typography,
  Grid,
  Modal,
  TextField,
  Button,
  Divider,
  Link,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleApiError } from "../common/Api-error-handler";
import { toast } from "react-toastify";
import { logout } from "../../store/auth/actions";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";

interface PostCardProps {
  post: {
    id: number;
    title: string;
    userId: string;
    description: string;
    createdAt: string;
    user: {
      email: string;
      firstName: string;
      lastName: string;
    };
    Group: {
      name: string;
    };
    PostImages: any[];
    Comments: any[];
  };
  showComments: boolean;
  onDelete?: (postId: number) => void;
}
const MAX_LENGTH = 50;

const PostCard: React.FC<PostCardProps> = ({
  post: initialPost,
  showComments,
  onDelete,
}) => {
  const [liked, setLiked] = useState(false);
  const [post, setPost] = useState(initialPost);
  const [isReadMore, setIsReadMore] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletePostId, setDeletePostId] = useState<number | null>(null); // Track which post to delete
  const [newComment, setNewComment] = useState<string>("");
  const { t } = useTranslation() as { t: (key: string) => string };

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  useEffect(() => {
    // Check if the user has liked this post
    const checkUserLike = async () => {
      try {
        if (currentUser) {
          const response = await axios.post(
            `${baseUrl}/api/community/posts/hasLiked`,
            {
              userId: currentUser?.user?.id,
              postId: post.id,
            }
          );
          setLiked(response.data.liked);
        }
        const responseLikes = await axios.get(
          `${baseUrl}/api/community/posts/${post.id}/likes`
        );
        setLikeCount(responseLikes.data?.length);
      } catch (error) {
        const { message, navigateTo } = handleApiError(error);
        toast.error(message);
        if (navigateTo) {
          if (navigateTo == "login") {
            dispatch(logout());
          }
          navigate(`/${navigateTo}`);
        }
      }
    };

    checkUserLike();
  }, [post.id]);

  const openDeleteModal = (postId: number) => {
    setDeleteModalOpen(true);
    setDeletePostId(postId);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setDeletePostId(null);
  };

  const confirmDelete = async () => {
    if (deletePostId !== null) {
      try {
        const response = await axios.delete(
          `${baseUrl}/api/community/posts/${deletePostId}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser?.token}`,
            },
          }
        );
        toast.success(t("post_deleted_successfully"));
        if (onDelete) {
          onDelete(deletePostId);
        }
        navigate(`/community/posts`);
      } catch (error) {
        toast.error("Error deleting post.");
      } finally {
        closeDeleteModal();
      }
    }
  };

  const handleLikeToggle = async (id: number) => {
    if (!currentUser) {
      toast.error(t("community.please_log_in_to_like_the_post"));
      return;
    } else {
      try {
        const response = await axios.post(
          `${baseUrl}/api/community/posts/like`,
          {
            userId: currentUser?.user?.id,
            postId: id,
          },
          {
            headers: {
              Authorization: `Bearer ${currentUser?.token}`,
            },
          }
        );
        setLiked(response.data.message === "Post liked");
        setLikeCount(!liked ? likeCount + 1 : likeCount - 1);
      } catch (error) {
        const { message, navigateTo } = handleApiError(error);
        toast.error(message);
        if (navigateTo) {
          if (navigateTo == "login") {
            dispatch(logout());
          }
          navigate(`/${navigateTo}`);
        }
      }
    }
  };

  const handlePostComment = () => {
    if (!currentUser) {
      toast.error(t("community.please_log_in_to_comment"));
      return;
      // navigate(`/login`);
    } else {
      if (newComment.trim()) {
        axios
          .post(
            `${baseUrl}/api/community/comments`,
            {
              userId: currentUser.user.id,
              postId: post.id,
              content: newComment,
            },
            {
              headers: {
                Authorization: `Bearer ${currentUser?.token}`,
              },
            }
          )
          .then((response) => {
            const newCommentData = response.data;
            setPost((prev) => ({
              ...prev,
              Comments: [...prev.Comments, newCommentData],
            }));
            setNewComment("");
          })
          .catch((error) => {
            const { message, navigateTo } = handleApiError(error);
            toast.error(message);
            if (navigateTo) {
              if (navigateTo == "login") {
                dispatch(logout());
              }
              navigate(`/${navigateTo}`);
            }
          });
      } else {
        toast.error(t("community.comment_cannot_be_empty"));
      }
    }
  };

  const handleImageClick = (index: number) => {
    setSelectedImageIndex(index);
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
    setSelectedImageIndex(null);
  };

  const renderImages = () => {
    const displayedImages = post.PostImages.slice(0, 4);
    const remainingImagesCount = post.PostImages.length - 4;

    return (
      <Grid container spacing={1} sx={{ position: "relative" }}>
        {displayedImages.map((image, index) => (
          <Grid item xs={index === 0 ? 12 : 6} key={index}>
            <Box
              component="img"
              src={image.url}
              alt={`Image ${index + 1}`}
              onClick={() => handleImageClick(index)}
              sx={{
                width: "100%",
                height: {
                  xs: index === 0 ? "100px" : "70px",
                  lg: index === 0 ? "240px" : "200px",
                },
                objectFit: "cover",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            />
          </Grid>
        ))}

        {remainingImagesCount > 0 && (
          <Grid item xs={6}>
            <Box
              sx={{
                width: "100%",
                height: "150px",
                borderRadius: "8px",
                position: "relative",
                background: `url(${post.PostImages[4].url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                fontSize: "24px",
                fontWeight: "bold",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                cursor: "pointer",
              }}
              onClick={() => handleImageClick(4)}
            >
              +{remainingImagesCount} {t("community.more")}
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderImagePreview = () => {
    if (selectedImageIndex === null) return null;

    // Function to navigate through images
    const handleNextImage = () => {
      if (selectedImageIndex < post.PostImages.length - 1) {
        setSelectedImageIndex(selectedImageIndex + 1);
      }
    };

    const handlePrevImage = () => {
      if (selectedImageIndex > 0) {
        setSelectedImageIndex(selectedImageIndex - 1);
      }
    };

    return (
      <Modal open={openPreview} onClose={handleClosePreview}>
        <Box
          sx={{
            width: "80%",
            height: "80%",
            top: "8%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "background.paper",
            borderRadius: "8px",
            outline: "none",
            margin: "auto",
            position: "relative",
          }}
        >
          {/* Close Button */}
          <IconButton
            onClick={handleClosePreview}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              zIndex: 2,
              color: "black",
            }}
          >
            &times;
          </IconButton>

          {/* Previous Button */}
          <IconButton
            onClick={handlePrevImage}
            sx={{
              position: "absolute",
              left: 10,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
            disabled={selectedImageIndex === 0}
          >
            &lt;
          </IconButton>

          {/* Image Preview */}
          <Box
            component="img"
            src={post.PostImages[selectedImageIndex].url}
            alt="Preview"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "8px",
            }}
          />

          {/* Next Button */}
          <IconButton
            onClick={handleNextImage}
            sx={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
            }}
            disabled={selectedImageIndex === post.PostImages.length - 1}
          >
            &gt;
          </IconButton>
        </Box>
      </Modal>
    );
  };

  return (
    <Grid item xs={12}>
      <Box>
        <Box display="flex" alignItems="center" mt={1} mb={2}>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => navigate(`/community/posts/author/${post.userId}`)}
          >
            <Avatar sx={{ marginRight: "1rem" }}>
              {post.user && post.user.firstName
                ? ` ${post.user.firstName.charAt(0).toUpperCase() ?? ""}`
                : post.user?.email.charAt(0).toUpperCase() || ""}
            </Avatar>
            <Typography variant="body2">
              By:
              {(post.user && post.user.firstName) || post.user.lastName
                ? ` ${post.user.firstName ?? ""} ${post.user.lastName ?? ""}`
                : post.user?.email || ""}
            </Typography>
          </Box>

          <Typography variant="body2" sx={{ marginLeft: "auto" }}>
            {new Date(post.createdAt).toLocaleDateString()}
          </Typography>
        </Box>
        <Typography
          onClick={() => navigate(`/community/posts/${post.id}`)}
          variant="h6"
          mb={2}
          sx={{ cursor: "pointer", fontWeight: "bold", lineBreak: "anywhere" }}
        >
          {post.title}
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          paragraph
          sx={{ lineBreak: "anywhere" }}
        >
          {isReadMore
            ? post.description
            : `${post.description.slice(0, MAX_LENGTH)}...`}
          {post.description.length > MAX_LENGTH && (
            <Link
              component="button"
              variant="body2"
              onClick={toggleReadMore}
              sx={{ ml: 1, color: "primary.main", cursor: "pointer" }}
            >
              {isReadMore ? "Read Less" : "Read More"}
            </Link>
          )}
        </Typography>
        {/* Post Images Section (If any) */}
        {post.PostImages.length > 0 && renderImages()}
        <Divider
          sx={{
            borderRadius: 2,
            border: "1px solid",
            marginTop: 3,
            borderColor: "divider",
          }}
          variant="middle"
        />{" "}
        {/* Post Stats (Sample icons and counts) */}
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton onClick={() => handleLikeToggle(post.id)}>
              {liked ? (
                <FavoriteIcon style={{ color: "red", fontSize: 30 }} />
              ) : (
                <FavoriteBorderIcon style={{ color: "black", fontSize: 30 }} />
              )}
            </IconButton>
            <Typography variant="body2">{likeCount} Likes</Typography>
            <IconButton onClick={() => navigate(`/community/posts/${post.id}`)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_4877_2084)">
                  <path
                    d="M24.0002 11.2476C23.8555 8.90758 23.0293 6.66108 21.6233 4.78499C20.2174 2.9089 18.2932 1.48526 16.0879 0.689559C13.8826 -0.106139 11.4927 -0.239103 9.21271 0.307059C6.93277 0.853221 4.86249 2.05463 3.2571 3.76318C1.65171 5.47173 0.581393 7.61273 0.178094 9.92222C-0.225205 12.2317 0.0561486 14.6088 0.987468 16.7603C1.91879 18.9118 3.45936 20.7438 5.41926 22.0303C7.37916 23.3169 9.67271 24.0018 12.0172 24.0006H19.0002C20.3258 23.9992 21.5968 23.472 22.5342 22.5346C23.4716 21.5972 23.9988 20.3262 24.0002 19.0006V11.2476ZM22.0002 19.0006C22.0002 19.7962 21.6841 20.5593 21.1215 21.1219C20.5589 21.6845 19.7958 22.0006 19.0002 22.0006H12.0172C10.6061 21.9999 9.211 21.7019 7.92285 21.1259C6.6347 20.5499 5.48243 19.7088 4.54115 18.6576C3.59533 17.6068 2.88508 16.366 2.45805 15.0184C2.03101 13.6707 1.89705 12.2473 2.06515 10.8436C2.33059 8.62951 3.32534 6.56675 4.89259 4.98049C6.45985 3.39422 8.51045 2.37467 10.7212 2.08256C11.1524 2.02851 11.5865 2.00113 12.0212 2.00056C14.3516 1.99421 16.61 2.80847 18.4002 4.30056C19.4457 5.16951 20.3039 6.24172 20.9227 7.45219C21.5415 8.66267 21.9081 9.9862 22.0002 11.3426V19.0006Z"
                    fill="#0B0A0A"
                  />
                  <path
                    d="M8 9H12C12.2652 9 12.5196 8.89464 12.7071 8.7071C12.8946 8.51957 13 8.26521 13 8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7H8C7.73478 7 7.48043 7.10536 7.29289 7.29289C7.10536 7.48043 7 7.73478 7 8C7 8.26521 7.10536 8.51957 7.29289 8.7071C7.48043 8.89464 7.73478 9 8 9Z"
                    fill="#0B0A0A"
                  />
                  <path
                    d="M16 11H8C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.29289 12.7071C7.48043 12.8947 7.73478 13 8 13H16C16.2652 13 16.5196 12.8947 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11Z"
                    fill="#0B0A0A"
                  />
                  <path
                    d="M16 15H8C7.73478 15 7.48043 15.1054 7.29289 15.2929C7.10536 15.4804 7 15.7348 7 16C7 16.2652 7.10536 16.5196 7.29289 16.7071C7.48043 16.8947 7.73478 17 8 17H16C16.2652 17 16.5196 16.8947 16.7071 16.7071C16.8946 16.5196 17 16.2652 17 16C17 15.7348 16.8946 15.4804 16.7071 15.2929C16.5196 15.1054 16.2652 15 16 15Z"
                    fill="#0B0A0A"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_4877_2084">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </IconButton>
            <Typography
              variant="body2"
              onClick={() => navigate(`/community/posts/${post.id}`)}
            >
              {post.Comments.length} replies
            </Typography>
            <IconButton>
              <VisibilityOutlinedIcon
                style={{ color: "#A5A1A1", fontSize: 30 }}
              />
            </IconButton>
            <Typography style={{ color: "#A5A1A1" }} variant="body2">
              {post.Comments.length + likeCount}{" "}
            </Typography>{" "}
          </Box>

          {/* Edit and Delete Icons on the Right */}
          {post.userId == currentUser?.user?.id && (
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton
                onClick={() => navigate(`/community/post/edit/${post.id}`)}
              >
                <EditIcon style={{ color: "black", fontSize: 30 }} />
              </IconButton>
              <IconButton onClick={() => openDeleteModal(post.id)}>
                <DeleteIcon style={{ color: "red", fontSize: 30 }} />
              </IconButton>
            </Box>
          )}
        </Box>
        <Divider
          sx={{
            borderRadius: 2,
            border: "1px solid",
            marginBottom: 3,
            borderColor: "divider",
            backgroundColor: "background.paper",
          }}
        />
      </Box>
      {renderImagePreview()}
      {showComments && (
        <Box>
          <Box
            mt={4}
            display="flex"
            gap={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <TextField
              fullWidth
              label="Comment"
              variant="outlined"
              multiline
              value={newComment}
              onChange={(e) => {
                if (e.target.value.length <= 500) {
                  setNewComment(e.target.value);
                }
              }}
              inputProps={{ maxLength: 500 }}
            />
            <Typography variant="body2" color="textSecondary" align="right">
              {newComment.length}/500
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePostComment}
                sx={{
                  padding: "15px 50px 15px 50px",
                  textTransform: "capitalize",
                }}
              >
                {t("community.Post")}
              </Button>
            </Box>
          </Box>

          <Box mt={2}>
            {post?.Comments.length > 0 ? (
              post?.Comments.slice()
                .reverse()
                .map((comment, index) => (
                  <Box key={index} mb={2}>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box display="flex" alignItems="center">
                        <Avatar>
                          {comment?.user && comment?.user.firstName
                            ? ` ${
                                comment?.user.firstName
                                  .charAt(0)
                                  .toUpperCase() ?? ""
                              }`
                            : comment?.user?.email.charAt(0).toUpperCase() ||
                              ""}
                        </Avatar>
                        <Box ml={2}>
                          <Typography variant="subtitle2">
                            {(comment?.user && comment?.user.firstName) ||
                            comment?.user.lastName
                              ? ` ${comment?.user.firstName ?? ""} ${
                                  comment?.user.lastName ?? ""
                                }`
                              : comment?.user?.email || ""}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="caption">
                        {dayjs(comment?.createdAt).format("MMMM DD, YYYY")}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      mt={1}
                      sx={{
                        overflowWrap: "break-word",
                      }}
                    >
                      {comment?.content}
                    </Typography>
                  </Box>
                ))
            ) : (
              <Typography>{t("community.no_comments_yet")}</Typography>
            )}
          </Box>
        </Box>
      )}

      <Modal open={isDeleteModalOpen} onClose={closeDeleteModal}>
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            margin: "auto",
            mt: "20vh",
          }}
        >
          <Typography variant="h6" mb={2}>
            {t("community.are_you_sure_you_want_to_delete_this_post")}
          </Typography>
          <Divider />
          <Box mt={3} display="flex" justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              onClick={closeDeleteModal}
            >
              {t("cancel_button")}
            </Button>
            <Button variant="contained" color="error" onClick={confirmDelete}>
              {t("applyButton")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default PostCard;
