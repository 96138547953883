import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

// get community groups and posts search
export const getCommunityGroupsAndPostsSearchRequest = (payload: dataTypes.getCommunityGroupsAndPostsSearchPayload) => ({
    type: actionTypes.GET_COMMUNITY_GROUPS_AND_POSTS_SEARCH_REQUEST,
    payload,
});
export const getCommunityGroupsAndPostsSearchSuccess = (data: any) => ({
    type: actionTypes.GET_COMMUNITY_GROUPS_AND_POSTS_SEARCH_SUCCESS,
    payload: data,
});


// get community groups and its posts for group posts page
export const getCommunityGroupAndItsPostsRequest = (payload: dataTypes.getCommunityGroupAndItsPostsPayload) => ({
    type: actionTypes.GET_COMMUNITY_GROUP_AND_ITS_POSTS_REQUEST,
    payload,
});
export const getCommunityGroupAndItsPostsSuccess = (data: any) => ({
    type: actionTypes.GET_COMMUNITY_GROUP_AND_ITS_POSTS_SUCCESS,
    payload: data,
});


// for Join community group
export const joinCommunityGroupRequest = (payload: dataTypes.JoinCommunityGroupPayload) => ({
    type: actionTypes.JOIN_COMMUNITY_GROUP_REQUEST,
    payload,
});
export const joinCommunityGroupSuccess = (groupId: number) => ({
    type: actionTypes.JOIN_COMMUNITY_GROUP_SUCCESS,
    payload: { groupId }
});


// for Leave community group
export const leaveCommunityGroupRequest = (payload: dataTypes.LeaveCommunityGroupPayload) => ({
    type: actionTypes.LEAVE_COMMUNITY_GROUP_REQUEST,
    payload,
});
export const leaveCommunityGroupSuccess = (groupId: number, userId?: any) => ({
    type: actionTypes.LEAVE_COMMUNITY_GROUP_SUCCESS,
    payload: { groupId, userId }
});


// get community group members for Group Posts page
export const getCommunityGroupMembersRequest = (payload: dataTypes.getCommunityGroupMembersPayload) => ({
    type: actionTypes.GET_COMMUNITY_GROUP_MEMBERS_REQUEST,
    payload,
});
export const getCommunityGroupMembersSuccess = (data: dataTypes.Members[]) => ({
    type: actionTypes.GET_COMMUNITY_GROUP_MEMBERS_SUCCESS,
    payload: data,
});