import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./wishlistActionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";

import * as dataTypes from "./wishlistDataTypes";
import * as actions from "./wishlistActions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";


function* getWishlists(action: dataTypes.GetWishlistsAction): Generator<any, void, any> {
  const { userId, token, setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/wishlist/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(actions.getWishlistsSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* deleteWishlist(action: dataTypes.DeleteWishlistAction): Generator<any, void, any> {
  const { userId, productId, token, navigate } = action.payload;
  try {
    const response = yield call(axios.delete, `${baseUrl}/api/shop/wishlist/${userId}/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const message = response.data.message || "wishlist deleted successfully.";
    yield put(actions.deleteWishlistSuccess(productId))
    toast.success(message);

  } catch (error: any) {

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* createWishlist(action: dataTypes.CreateWishlistAction): Generator<any, void, any> {
  const { userId, productId, token, navigate } = action.payload;
  try {
    const data = {
      userId,
      productId,
    };

    const response = yield call(axios.post, `${baseUrl}/api/shop/wishlist`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const newWishlist = response.data.wishlist;
    const message = response.data.message || "wishlist created successfully.";

    yield put(actions.createWishlistSuccess(newWishlist))
    toast.success(message);

  } catch (error: any) {

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {

        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchWishlistSagas() {
  yield takeLatest(types.GET_WISHLISTS_REQUEST, getWishlists);
  yield takeLatest(types.DELETE_WISHLIST_REQUEST, deleteWishlist);
  yield takeLatest(types.CREATE_WISHLIST_REQUEST, createWishlist);

}
