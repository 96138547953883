import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import * as dataTypes from "./dataTypes";
import * as actions from "./actions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";

function* getCommunityGroupsAndPostsSearch(action: dataTypes.getCommunityGroupsAndPostsSearchAction): Generator<any, void, any> {
    const { setLoading, navigate, keyword } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/posts/search/${keyword}`);
        yield put(actions.getCommunityGroupsAndPostsSearchSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* getCommunityGroupAndItsPosts(action: dataTypes.getCommunityGroupAndItsPostsAction): Generator<any, void, any> {
    const { setLoading, navigate, groupId, token } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/groups/${groupId}/posts`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        yield put(actions.getCommunityGroupAndItsPostsSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* joinCommunityGroup(action: dataTypes.JoinCommunityGroupAction): Generator<any, void, any> {
    const { userId, groupId, setIsMember, navigate, token } = action.payload;
    try {

        const response = yield call(axios.post, `${baseUrl}/api/community/groups/join`,
            {
                userId,
                groupId,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

        yield put(actions.joinCommunityGroupSuccess(groupId));
        setIsMember(true);
        const message = response.data.message || "successfully joined the group";
        toast.success(message);

    } catch (error: any) {

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* leaveCommunityGroup(action: dataTypes.LeaveCommunityGroupAction): Generator<any, void, any> {
    const { userId, groupId, setIsMember, navigate, token, reason } = action.payload;
    try {

        if (reason) {
            const response = yield call(axios.post, `${baseUrl}/api/community/groups/leave`,
                {
                    userId,
                    groupId,
                    reason,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            yield put(actions.leaveCommunityGroupSuccess(groupId, userId));
            const message = response.data.message || "successfully left the group";
            toast.success(message);

        } else {
            const response = yield call(axios.post, `${baseUrl}/api/community/groups/leave`,
                {
                    userId,
                    groupId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

            if (setIsMember) {
                setIsMember(false);
            }
            yield put(actions.leaveCommunityGroupSuccess(groupId));
            const message = response.data.message || "successfully left the group";
            toast.success(message);
        }

    } catch (error: any) {
        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* getCommunityGroupMembers(action: dataTypes.getCommunityGroupMembersAction): Generator<any, void, any> {
    const { setLoading, navigate, groupId, token, setOpenMembersModal } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/groups/group/${groupId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        yield put(actions.getCommunityGroupMembersSuccess(response.data))
        setOpenMembersModal(true);

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


export default function* watchCommunityGroupAndPostSaga() {
    yield takeLatest(actionTypes.GET_COMMUNITY_GROUPS_AND_POSTS_SEARCH_REQUEST, getCommunityGroupsAndPostsSearch);
    yield takeLatest(actionTypes.GET_COMMUNITY_GROUP_AND_ITS_POSTS_REQUEST, getCommunityGroupAndItsPosts);
    yield takeLatest(actionTypes.JOIN_COMMUNITY_GROUP_REQUEST, joinCommunityGroup);
    yield takeLatest(actionTypes.LEAVE_COMMUNITY_GROUP_REQUEST, leaveCommunityGroup);
    yield takeLatest(actionTypes.GET_COMMUNITY_GROUP_MEMBERS_REQUEST, getCommunityGroupMembers);
}


