import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

// for fetching community groups for admin page
export const fetchCommunityGroupsRequest = (payload: dataTypes.FetchCommunityGroupsPayload) => ({
    type: actionTypes.FETCH_COMMUNITY_GROUPS_REQUEST,
    payload,
});
export const fetchCommunityGroupsSuccess = (data: dataTypes.Group[]) => ({
    type: actionTypes.FETCH_COMMUNITY_GROUPS_SUCCESS,
    payload: data,
});


// for fetching approved community groups for group page
export const fetchApprovedCommunityGroupsRequest = (payload: dataTypes.FetchApprovedCommunityGroupsPayload) => ({
    type: actionTypes.FETCH_APPROVED_COMMUNITY_GROUPS_REQUEST,
    payload,
});
export const fetchApprovedCommunityGroupsSuccess = (data: dataTypes.Group[]) => ({
    type: actionTypes.FETCH_APPROVED_COMMUNITY_GROUPS_SUCCESS,
    payload: data,
});


// for deleting community group
export const deleteCommunityGroupRequest = (payload: dataTypes.DeleteCommunityGroupPayload) => ({
    type: actionTypes.DELETE_COMMUNITY_GROUP_REQUEST,
    payload,
});
export const deleteCommunityGroupSuccess = (groupId: number) => ({
    type: actionTypes.DELETE_COMMUNITY_GROUP_SUCCESS,
    payload: { groupId }
});


// for updating community group
export const updateCommunityGroupRequest = (payload: dataTypes.UpdateCommunityGroupPayload) => ({
    type: actionTypes.UPDATE_COMMUNITY_GROUP_REQUEST,
    payload,
});
export const updateCommunityGroupSuccess = (groupId: number, updatedCommunityGroup: dataTypes.Group) => ({
    type: actionTypes.UPDATE_COMMUNITY_GROUP_SUCCESS,
    payload: { groupId, updatedCommunityGroup }
});


// for creating community group
export const createCommunityGroupRequest = (payload: dataTypes.CreateCommunityGroupPayload) => ({
    type: actionTypes.CREATE_COMMUNITY_GROUP_REQUEST,
    payload,
});
export const createCommunityGroupSuccess = (newCommunityGroup: any) => ({
    type: actionTypes.CREATE_COMMUNITY_GROUP_SUCCESS,
    payload: { newCommunityGroup }
});


// for fetching groups that user join 
export const fetchUserJoinedGroupsRequest = (payload: dataTypes.FetchUserJoinedGroupsPayload) => ({
    type: actionTypes.FETCH_USER_JOINED_GROUPS_REQUEST,
    payload,
});
export const fetchUserJoinedGroupsSuccess = (data: dataTypes.UserJoinedGroups[]) => ({
    type: actionTypes.FETCH_USER_JOINED_GROUPS_SUCCESS,
    payload: data,
});


// for community group detail with user and check IsMember for group posts page
export const getCommunityGroupDetailRequest = (payload: dataTypes.GetCommunityGroupDetailPayload) => ({
    type: actionTypes.GET_COMMUNITY_GROUP_DETAIL_REQUEST,
    payload,
});