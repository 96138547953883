import React, { FC, useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import {
  Box,
  Button,
  Typography,
  Grid,
  Container,
  CircularProgress,
  CardContent,
  Card,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sky from "../../assets/images/sky.jpeg";
import love from "../../assets/images/Love1.webp";
import youthfull from "../../assets/images/Youthful.png";
import forgive from "../../assets/images/Forgiving.png";
import empower from "../../assets/images/Empower.webp";
import resillence from "../../assets/images/Resilence.png";
import strength from "../../assets/images/Strength.png";
import ProductComponent from "../Shop/ProductComponent";
import explore_products_section_image from "../../assets/images/explore_products_section.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getFeatureProductsRequest } from "../../store/products/productActions";
import { useTranslation } from "react-i18next";

interface Product {
  id: number;
  title: string;
  description: string;
  image: string;
  colors: { name: string; code: string }[];
  size: string[];
  variants: any;
  is_soldout: boolean;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
  price: number;
  subcategoryId: number;
}
const HomePage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { t } = useTranslation() as { t: (key: string) => string };
  const { products } = useSelector((state: any) => state?.productReducer);

  useEffect(() => {
    dispatch(getFeatureProductsRequest({ setLoading, navigate }));
  }, []);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: (
      <ArrowForwardIosIcon
        sx={{
          color: "black",
          fontSize: 40,
          "&:hover": {
            color: "black",
          },
        }}
      />
    ),
    prevArrow: (
      <ArrowBackIosIcon
        sx={{
          color: "black",
          fontSize: 40,
          "&:hover": {
            color: "black",
          },
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const cards = [
    {
      id: 1,
      title: t("home.sixPillersText.love.title"),
      description: t("home.sixPillersText.love.description"),
      img: love,
    },
    {
      id: 2,
      title: t("home.sixPillersText.yothfullness.title"),
      description: t("home.sixPillersText.yothfullness.description"),
      img: youthfull,
    },
    {
      id: 3,
      title: t("home.sixPillersText.forgiveness.title"),
      description: t("home.sixPillersText.forgiveness.description"),
      img: forgive,
    },
    {
      id: 4,
      title: t("home.sixPillersText.empowering.title"),
      description: t("home.sixPillersText.empowering.description"),
      img: empower,
    },
    {
      id: 5,
      title: t("home.sixPillersText.resilience.title"),
      description: t("home.sixPillersText.resilience.description"),
      img: resillence,
    },
    {
      id: 6,
      title: t("home.sixPillersText.strength.title"),
      description: t("home.sixPillersText.strength.description"),
      img: strength,
    },
  ];

  const CustomNextArrow = ({ onClick }: { onClick: () => void }) => (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        right: { xs: "-20px", sm: "-30px", md: "-40px" },
        transform: "translateY(-50%)",
        fontSize: { xs: "30px", sm: "40px", md: "50px" },
        color: "black",
        zIndex: 1,
        cursor: "pointer",
        "&:hover": {
          color: "#ff6347",
        },
      }}
    >
      &#8594;
    </Box>
  );

  const CustomPrevArrow = ({ onClick }: { onClick: () => void }) => (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "50%",
        left: { xs: "-20px", sm: "-30px", md: "-40px" },
        transform: "translateY(-50%)",
        fontSize: { xs: "30px", sm: "40px", md: "50px" },
        color: "black",
        zIndex: 1,
        cursor: "pointer",
        "&:hover": {
          color: "#ff6347",
        },
      }}
    >
      &#8592;
    </Box>
  );

  const settingsProductCarousel = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    nextArrow: <CustomNextArrow onClick={() => {}} />,
    prevArrow: <CustomPrevArrow onClick={() => {}} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexShrink={0}
        sx={{
          backgroundImage: `url(${sky})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: { xs: "200px", sm: "400px", md: "550px" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            opacity: 0.6,
            zIndex: 1,
          }}
        />

        <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Sans-serif",
                fontSize: { xs: "20px", sm: "28px", md: "39px" },
                fontWeight: 500,
                fontStyle: "normal",
                lineHeight: "120%",
                color: "#FAFAFA",
              }}
            >
              <Box sx={{ display: "flex", gap: 3 }}>
                <Box
                  sx={{
                    padding: { xs: "2px", md: "4px" },
                    backgroundColor: "#FF5A00",
                    height: { xs: "155px", md: "285px" },
                  }}
                />
                <Box
                  sx={{
                    paddingTop: "4px",
                    fontSize: { xs: "20px", md: "39px" },
                  }}
                >
                  {t("home.bannerText.line1")} <br></br>
                  {t("home.bannerText.line2")} <br></br>
                  {t("home.bannerText.line3")} <br></br>
                  {t("home.bannerText.line4")} <br></br>
                  {t("home.bannerText.line5")} <br></br>
                  {t("home.bannerText.line6")}
                </Box>
              </Box>
            </Typography>
          </Box>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sx={{ justifyContent: "center" }}></Grid>
          <Grid item xs={12}>
            <Box sx={{ margin: "40px 0px" }}>
              <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontFamily: "Syne",
                      fontSize: { xs: "20px", sm: "28px", md: "40px" },
                      fontStyle: "normal",
                      fontWeight: 700,
                      textAlign: "center",
                      marginBottom: "50px",
                    }}
                  >
                    {t("home.homePageHeading")}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Container maxWidth={"lg"}>
                    <Box sx={{ width: "100%", margin: "0 auto" }}>
                      <Slider {...settingsProductCarousel}>
                        {cards.map((card) => (
                          <Box
                            key={card.id}
                            sx={{ paddingX: 1, boxSizing: "border-box" }}
                          >
                            <Card>
                              <Box
                                borderRadius="50%"
                                overflow="hidden"
                                width={{ xs: "205px", md: "300px" }}
                                height={{ xs: "205px", md: "300px" }}
                                margin="0 auto"
                              >
                                <img
                                  src={card.img}
                                  alt={card.title}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    display: "block",
                                  }}
                                />
                              </Box>
                              <CardContent sx={{ color: "#FF5A00" }}>
                                <Typography
                                  sx={{
                                    fontSize: "22px",
                                    fontWeight: 700,
                                    textAlign: "center",
                                  }}
                                  variant="h5"
                                  component="div"
                                  gutterBottom
                                >
                                  {card.title}
                                </Typography>
                                <p
                                  style={{
                                    color: "#000",
                                    lineHeight: "26px",
                                    textAlign: "center",
                                  }}
                                >
                                  {card.description}
                                </p>
                              </CardContent>
                            </Card>
                          </Box>
                        ))}
                      </Slider>
                    </Box>
                  </Container>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} width={"100%"} sx={{ backgroundColor: "#f9fafa" }}>
            <Box
              sx={{
                backgroundImage: `url(${sky})`,
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: "relative",
                height: { xs: "100px", sm: "150px", md: "200px" },
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "30px",
                overflow: "hidden",
                transition:
                  "background-size 0.5s ease-in-out, opacity 0.3s ease-in-out",
                "&:hover": {
                  backgroundSize: "130%",
                },
                "&::after": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "black",
                  opacity: 0,
                  transition: "opacity 0.3s ease-in-out",
                },
                "&:hover::after": {
                  opacity: 0.4,
                },
              }}
            ></Box>
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Typography
                sx={{
                  display: "block",
                  fontFamily: "Outfit",
                  fontSize: { xs: "16px", sm: "18px", md: "18px" },
                  fontStyle: "normal",
                  fontWeight: 400,
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                {t("home.learnMoreSectionText")}
              </Typography>
              <Button
                size="large"
                sx={{
                  display: "flex",
                  mx: "auto",
                  backgroundColor: "#FF5A00",
                  textTransform: "capitalize",
                  color: "white",
                  padding: { xs: "8px", sm: "10px", md: "8px 28px" },
                  "&:hover": {
                    backgroundColor: "primary.dark",
                    transform: "scale(1.05)",
                  },
                  mb: 4,
                }}
                onClick={() => navigate("/become-a-lyfer")}
              >
                {t("home.learnMoreButton")}
              </Button>
            </Box>
          </Grid>
          <Container
            sx={{ width: "100%", backgroundColor: "#FAFAFA", mt: 8, mb: 5 }}
          >
            <Container maxWidth="lg">
              <Grid
                container
                alignItems="center"
                spacing={1}
                direction={{ xs: "column", md: "row" }}
              >
                <Grid item md={6}>
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: { xs: "314px", sm: "314px", md: "314px" },
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      component="img"
                      src={explore_products_section_image}
                      alt="Product Image"
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "20px",
                        objectFit: "cover",
                        transition: "transform 1s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.2)",
                        },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={10} md={6}>
                  <Box
                    sx={{
                      textAlign: { xs: "center", sm: "center" },
                      paddingX: { xs: 2, sm: 3, md: 4 },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "600",
                        fontSize: {
                          xs: "20px",
                          sm: "28px",
                          md: "29px",
                          lg: "32",
                        },
                        marginBottom: { xs: "16px", sm: "24px", md: "32px" },
                      }}
                    >
                      {t("home.productSectionText")}
                    </Typography>

                    <Box textAlign={{ xs: "center" }}>
                      <Button
                        variant="contained"
                        sx={{
                          color: "white",
                          textDecoration: "underline",
                          "&:hover": {
                            backgroundColor: "black",
                            textDecoration: "none",
                          },
                          padding: { xs: "10px 20px", sm: "12px 30px" },
                          minWidth: { xs: "120px", sm: "150px" },
                        }}
                        onClick={() => navigate("/products")}
                      >
                        {t("home.shopButton")}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Box sx={{ width: "100%", marginY: 3 }}>
                {products.length > 0 ? (
                  <Slider {...settingsProductCarousel}>
                    {products.map((product: Product) => (
                      <Box
                        key={product.id}
                        sx={{ paddingX: 1, boxSizing: "border-box" }}
                      >
                        <ProductComponent
                          product={product}
                          userId={currentUser?.user?.id ?? 0}
                        />
                      </Box>
                    ))}
                  </Slider>
                ) : (
                  <Typography
                    variant="h6"
                    sx={{ width: "100%", textAlign: "center" }}
                  >
                    No records found
                  </Typography>
                )}
              </Box>
            </Container>
          </Container>
        </Grid>
      </Container>
    </>
  );
};

export default HomePage;
