import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  SelectChangeEvent,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  TableHead,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import baseUrl from "../../../config/apiConfig";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { handleApiError } from "../../common/Api-error-handler";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/auth/actions";
import { fetchColorRequest } from "../../../store/colors/colorActions";
import { fetchSubCategoryRequest } from "../../../store/subCategories/subCategoryActions";
import {
  createProductRequest,
  getProductRequest,
  updateProductRequest,
} from "../../../store/products/productActions";

interface Category {
  id: string;
  name: string;
}

interface Color {
  id: string;
  code: string;
}

interface Product {
  id: string;
  title: string;
  description: string;
  price: number;
  quantity: number;
  subcategoryId: string;
  size: string;
  is_soldout: boolean;
  printful_prodcut_id: string;
  colors: Color[];
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
  existingImageIds: string[];
}

interface Option {
  id: number;
  name: string;
  title: string;
  printfull_cat_id: number;
}
interface ProductVariant {
  id: string;
  syncedVariantId: string;
  name: string;
  color: string;
  color_code: string;
  size: string;
  price: number;
  image: string;
}

export default function CreateProductPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productId } = useParams<{ productId?: string }>();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<number | null>(null);
  const [quantity, setQuantity] = useState<number | null>(null);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [backDesignImage, setBackDesignImage] = useState<File | null>(null);
  const [backDesignPreview, setBackDesignPreview] = useState<string | null>(
    null
  );
  const [backDesign, setBackDesign] = useState<boolean>(false);
  const [existingImages, setExistingImages] = useState<string[]>([]);
  const [existingImageIds, setExistingImageIds] = useState<number[]>([]);
  const [removedImageIds, setRemovedImageIds] = useState<number[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [mainCategories, setMainCategories] = useState<Option[]>([]);
  const [categories, setCategories] = useState<Option[]>([]);
  const [subcategories, setSubcategories] = useState<Option[]>([]);
  const [products, setProducts] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [variants, setVariants] = useState<any[]>([]); // List of variants for the selected product
  const [selectedVariants, setSelectedVariants] = useState<any[]>([]);
  const [productVariants, setProductVariants] = useState<ProductVariant[]>([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState<any | null>(
    null
  );
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
  const [selectedSubcategory, setSelectedSubCategory] = useState<any | null>(
    null
  );
  const [availableVariants, setAvailableVariants] = useState<ProductVariant[]>(
    []
  );
  const [change, setChange] = useState<boolean>(false);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [variantToDelete, setVariantToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { subCategories } = useSelector(
    (state: any) => state?.subCategoryReducer
  );
  const { product } = useSelector((state: any) => state?.productReducer);
  const pendingFetches = useRef(0);

  const deleteOpenModal = () => setDeleteModalOpen(true);
  const deleteCloseModal = () => setDeleteModalOpen(false);

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      if (selectedSubcategory !== null && subcategories.length > 0) {
        try {
          const response = await axios.get(
            `${baseUrl}/api/shop/products/printful/products/${selectedSubcategory.printfull_cat_id}`
          );
          setProducts(response.data.result);
        } catch (error) {
          console.error("Error fetching products from Printful:", error);
        }
      } else if (selectedCategory !== null && selectedSubcategory == null) {
        try {
          const response = await axios.get(
            `${baseUrl}/api/shop/products/printful/products/${selectedCategory.printfull_cat_id}`
          );
          setProducts(response.data.result);
        } catch (error) {
          console.error("Error fetching products from Printful:", error);
        }
      }
    };
    fetchProductsByCategory();
  }, [selectedSubcategory, selectedCategory]);

  useEffect(() => {
    const fetchProductsByWithVariants = async () => {
      if (selectedProduct) {
        try {
          const response = await axios.get(
            `${baseUrl}/api/shop/products/printful/product/${selectedProduct.id}`
          );

          const filteredOut = response.data.result.variants.filter(
            (varient: any) => {
              if (
                varient["availability_regions"] &&
                varient["availability_regions"]["US"]
              ) {
                return true;
              }
            }
          );

          setVariants(filteredOut.length ? filteredOut : []);
        } catch (error) {
          console.error("Error fetching products from Printful:", error);
        }
      }
    };
    fetchProductsByWithVariants();
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      const backDesignExist = selectedProduct.files.find(
        (file: any) => file.id === "back"
      );
      if (backDesignExist) {
        setBackDesign(true);
      } else {
        setBackDesign(false);
      }
    }
  }, [selectedProduct]);

  const handleProductChange = (event: any) => {
    setSelectedProduct(event.target.value);
    setSelectedVariants([]);
  };

  const handleVariantChange = (event: any) => {
    setChange(true);
    setSelectedVariants(event.target.value);
  };
  useEffect(() => {
    if (selectedVariants) {
      setSelectedVariants(selectedVariants);
      setChange(false);
    }
  }, [change]);

  useEffect(() => {
    const fetchMainCategories = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/shop/subcategories/mainCategories`
        );
        // Replace with your API endpoint
        setMainCategories(response.data);
      } catch (error) {
        console.error("Error fetching main categories:", error);
      }
    };
    fetchMainCategories();
  }, []);

  // Fetch categories when a main category is selected
  useEffect(() => {
    if (selectedMainCategory !== null) {
      const fetchCategories = async () => {
        try {
          const response = await axios.get<Option[]>(
            `${baseUrl}/api/shop/subcategories/categories/${selectedMainCategory}`
          );
          setCategories(response.data);
          setSubcategories([]); // Reset subcategories when main category changes
        } catch (error) {
          console.error("Error fetching categories:", error);
        }
      };
      fetchCategories();
    }
  }, [selectedMainCategory]);

  // Fetch subcategories when a category is selected
  useEffect(() => {
    if (selectedCategory !== null) {
      const fetchSubcategories = async () => {
        try {
          const response = await axios.get<Option[]>(
            `${baseUrl}/api/shop/subcategories/subCategories/${selectedCategory.id}`
          );
          setSubcategories(response.data);
          setSelectedSubCategory(null);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };
      fetchSubcategories();
    }
  }, [selectedCategory]);

  // const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // let pendingFetches = 0;
    const setLoading = (status: any) => {
      if (status) {
        pendingFetches.current += 1;
      } else {
        pendingFetches.current -= 1;
        if (pendingFetches.current === 0) setIsLoading(false);
      }
    };
    dispatch(fetchSubCategoryRequest({ setLoading, navigate }));
    dispatch(fetchColorRequest({ setLoading, navigate }));
    if (productId) {
      dispatch(
        getProductRequest({
          productId,
          token: currentUser?.token,
          setLoading,
          navigate,
        })
      );
    }

    return () => {
      pendingFetches.current = 0;
    };
  }, [dispatch]);

  const fetchProduct = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/api/shop/products/printful/product/${product.printful_prodcut_id}`
      );
        const backDesignExists = response.data.result.product.files.find(
          (file: any) => file.id === "back"
        );
        if (backDesignExists) {
          setBackDesign(true);
        } else {
          setBackDesign(false);
        }
    } catch (error) {
      console.error("Error fetching products from Printful:", error);
    }

};

  useEffect(() => {
    if (productId) {
      if (product) {
        setTitle(product.title);
        setDescription(product.description);
        setPrice(product.price);
        setIsSoldOut(product.is_soldout);
        setQuantity(product.quantity);
        setProductVariants(product.variants);
        setExistingImages(
          product.images.map((img: { fullPath: any }) => img.fullPath)
        );
        setImagePreview(product.image || null);
        setBackDesignPreview(product.back_design_image || null);

        setExistingImageIds(product.images.map((img: { id: any }) => img.id));
        fetchProduct();
      }
    }
  }, [product]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const imagesArray = Array.from(files);

      setSelectedImages((prevImages) => {
        // Filter out images that are already in the previous list
        const newImages = imagesArray.filter(
          (image) =>
            !prevImages.some((prevImage) => prevImage.name === image.name)
        );

        return [...prevImages, ...newImages];
      });
    }
  };

  const handleRemoveImage = (index: number) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveExistingImage = (index: number) => {
    setExistingImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setRemovedImageIds((prevIds) => [...prevIds, existingImageIds[index]]);
    setExistingImageIds((prevIds) => prevIds.filter((_, i) => i !== index));
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file)); // Preview the image
      setErrors((prevErrors) => ({ ...prevErrors, image: "" })); // Clear image error
    }
  };

  const handleBackDesignChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setBackDesignImage(file);
      setBackDesignPreview(URL.createObjectURL(file)); // Preview for back design image
      setErrors((prevErrors) => ({ ...prevErrors, back_design_image: "" })); // Clear back design image error
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    // uncomment it if you want to make the upload image ( first field ) mandatory
    if (!product) {
      if (!title) newErrors.title = "Title is required";
      if (!description) newErrors.description = "Description is required";
      if (price === null || price <= 0)
        newErrors.price = "Price must be greater than 0";
      if (quantity === null || quantity <= 0)
        newErrors.quantity = "Quantity must be greater than 0";
      // Validate image upload
      if (!imageFile) {
        newErrors.image = "Design Image is required.";
      }
      if (!selectedImages || selectedImages.length === 0) {
        newErrors.images = "At least one image is required.";
      }
      if (selectedMainCategory === null)
        newErrors.mainCategory = "Main Category is required.";
      if (selectedCategory === null)
        newErrors.category = "Category is required.";
      if (selectedVariants.length < 1)
        newErrors.selectedVariants = "Variants are required.";
      if (selectedProduct === null)
        newErrors.selectedProduct = "Product is required.";
    } else {
      if (!title) newErrors.title = "Title is required";
      if (!description) newErrors.description = "Description is required";
      if (price === null || price <= 0)
        newErrors.price = "Price must be greater than 0";
      if (quantity === null || quantity <= 0)
        newErrors.quantity = "Quantity must be greater than 0";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price!.toString());
    formData.append("quantity", quantity!.toString());
    formData.append("mianCategoryId", selectedMainCategory ?? null);
    formData.append("categoryId", selectedCategory?.id ?? null);
    formData.append("subcategoryId", selectedSubcategory?.id ?? null);
    formData.append("product", JSON.stringify(selectedProduct));
    formData.append("variants", JSON.stringify(selectedVariants));
    formData.append("is_soldout", isSoldOut ? "1" : "0");
    formData.append("removedImageIds", JSON.stringify(removedImageIds));
    if (imageFile) {
      formData.append("designImage", imageFile);
    }
    if (backDesignImage) {
      formData.append("backDesignImage", backDesignImage);
    }

    selectedImages.forEach((image) => {
      formData.append("images", image);
    });
    formData.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
    console.log(Object.fromEntries(formData.entries()));

    if (productId) {
      dispatch(
        updateProductRequest({
          productId,
          formData,
          token: currentUser?.token,
          navigate,
        })
      );
    } else {
      dispatch(
        createProductRequest({ formData, token: currentUser?.token, navigate })
      );
    }
  };

  const allImages = [
    ...existingImages,
    ...selectedImages.map((image) => URL.createObjectURL(image)),
  ];

  if (isLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  const confirmDeleteVariant = (id: any) => {
    setVariantToDelete(id);
    setDeleteModalOpen(true);
  };

  const confirmRemoveVariant = (id: any) => {
    setProductVariants((prevVariants) =>
      prevVariants.filter((variant) => variant.id !== id)
    );
    setSelectedVariants((selectedVariants) =>
      selectedVariants.filter((variant) => variant.id !== id)
    );
  };

  const handleDeleteVariant = () => {
    if (!variantToDelete) return;
    axios
      .delete(`${baseUrl}/api/shop/products/variant/${variantToDelete}`, {
        headers: {
          Authorization: `Bearer ${currentUser?.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setProductVariants((prevVariants) =>
          prevVariants.filter((variant) => variant.id !== variantToDelete)
        );
      })
      .catch((error) => {
        console.error(
          "Error deleting variant:",
          error.response ? error.response.data : error.message
        );
      })
      .finally(() => {
        deleteCloseModal();
        setVariantToDelete(null);
      });
  };

  const handleAddNewVariants = async () => {
    try {
      // API call to fetch available variants from printfull
      const response = await axios.get(
        `${baseUrl}/api/shop/products/printful/product/${product.printful_prodcut_id}`
      );

      const filteredOut = response.data.result.variants.filter(
        (varient: any) => {
          if (
            varient["availability_regions"] &&
            varient["availability_regions"]["US"]
          ) {
            return true;
          }
        }
      );

      setAvailableVariants(filteredOut.length ? filteredOut : []);
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching variants:", error);
    }
  };

  // Function to handle the variant selection
  const handleVariantSelection = (event: SelectChangeEvent<string[]>) => {
    const selectedValues = event.target.value as string[];
    setSelectedVariants(selectedValues);
  };

  // Function to handle adding selected variants to current variants
  const handleAddVariants = () => {
    setProductVariants([...productVariants, ...selectedVariants]);
    setOpenModal(false);
  };

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      <Grid item xs={12}>
        <Grid container direction="column" flexShrink={0}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            sx={{
              padding: { xs: "8px", sm: "12px", md: "16px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
                color: "#000000",
                marginLeft: { xs: 0, md: 25 },
              }}
            >
              {productId ? "Update Product" : "Add Product"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ borderRadius: 4, border: "1px solid", mt: { xs: 0, sm: 6 } }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          margin={2}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box
                sx={{
                  border: `2px dashed ${errors.images ? "red" : "#ccc"}`,
                  borderRadius: "8px",
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    multiple
                    onChange={(e) => {
                      if (e.target.files) {
                        const files = Array.from(e.target.files); // Ensure files are non-null
                        if (
                          existingImages.length +
                            allImages.length +
                            files.length >
                          10
                        ) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            images: "You cannot upload more than 10 images.",
                          }));
                        } else {
                          handleImageUpload(e); // Call your existing function to handle the upload
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            images: "", // Clear the error message if under the limit
                          }));
                        }
                      }
                    }}
                  />
                  <ImageIcon sx={{ fontSize: 50, color: "#ccc" }} />
                </IconButton>
                <Typography
                  sx={{
                    position: "absolute",
                    marginTop: "130px",
                    color: "#999",
                    textAlign: "center",
                  }}
                >
                  Upload Pictures{" "}
                  {errors.images && (
                    <Typography
                      sx={{
                        color: "red",
                        fontSize: "0.875rem",
                        marginTop: "20px",
                      }}
                    >
                      {errors.images}{" "}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Grid>
            {/* Preview All Images (New + Existing) */}
            {allImages.length > 0 && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 2,
                    justifyContent: "center",
                  }}
                >
                  {allImages.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        width: "150px",
                        height: "150px",
                      }}
                    >
                      <Box
                        component="img"
                        src={image}
                        alt={`Image ${index + 1}`}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                          boxShadow: 2,
                        }}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                        }}
                        onClick={() => {
                          if (index < existingImages.length) {
                            handleRemoveExistingImage(index);
                          } else {
                            handleRemoveImage(index - existingImages.length);
                          }
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={(e) => {
                  const newValue = e.target.value;
                  // Check if the length exceeds 50 characters
                  if (newValue.length <= 50) {
                    setTitle(newValue); // Update the title only if the length is <= 50
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      title: "", // Clear any existing error
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      title: "Title cannot exceed 50 characters", // Set error message
                    }));
                  }
                }}
                error={!!errors.title} // Show error if there's a title error
                helperText={errors.title} // Display the error message
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Profit price"
                type="number"
                value={price || ""}
                onChange={(e) => {
                  const newPrice = Number(e.target.value);

                  // Prevent negative values
                  if (newPrice < 0) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      price: "Price cannot be less than 0.",
                    }));
                    return;
                  }

                  setPrice(newPrice);

                  // Clear the error if valid
                  if (errors.price) {
                    setErrors((prevErrors) => ({ ...prevErrors, price: "" }));
                  }
                }}
                error={!!errors.price}
                helperText={errors.price}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Quantity"
                type="number"
                value={quantity || ""}
                onChange={(e) => {
                  setQuantity(Number(e.target.value));
                  // Clear the error when the user starts typing
                  if (errors.quantity) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      quantity: "",
                    }));
                  }
                }}
                error={!!errors.quantity}
                helperText={errors.quantity}
              />
            </Grid>
            {!productId ? (
              <>
                {/* Title */}
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    Select Categories
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="main-category-label">
                      Main Category
                    </InputLabel>
                    <Select
                      labelId="main-category-label"
                      label="Main Category"
                      value={selectedMainCategory || ""}
                      onChange={(e) => {
                        setSelectedMainCategory(Number(e.target.value));
                        // Clear the error when a valid option is selected
                        if (errors.mainCategory) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            mainCategory: "",
                          }));
                        }
                      }}
                      error={!!errors.mainCategory} // Set error state
                    >
                      <MenuItem value="">
                        <em>Select Main Category</em>
                      </MenuItem>
                      {mainCategories.map((mainCategory) => (
                        <MenuItem key={mainCategory.id} value={mainCategory.id}>
                          {mainCategory.title}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.mainCategory && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "12px",
                          marginTop: "3px",
                          marginLeft: "14px",
                          fontWeight: 400,
                        }}
                      >
                        {errors.mainCategory}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Categories Dropdown */}
                {selectedMainCategory && (
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="category-label">Category</InputLabel>
                      <Select
                        labelId="category-label"
                        id="category"
                        label="Category"
                        value={selectedCategory || ""}
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                          // Clear the error when a valid option is selected
                          if (errors.category) {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              category: "",
                            }));
                          }
                        }}
                        error={!!errors.category} // Set error state for category
                      >
                        <MenuItem value="">
                          <em>Select Category</em>
                        </MenuItem>
                        {categories.map((category: any) => (
                          <MenuItem key={category.id} value={category}>
                            {category.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.category && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontSize: "12px",
                            marginTop: "3px",
                            marginLeft: "14px",
                            fontWeight: 400,
                          }}
                        >
                          {errors.category}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                )}

                {/* Subcategories Dropdown */}
                {selectedCategory && subcategories.length > 0 && (
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="subcategory-label">
                        Subcategory
                      </InputLabel>
                      <Select
                        labelId="subcategory-label"
                        id="subcategory"
                        label="Subcategory"
                        value={selectedSubcategory || ""}
                        onChange={(e) => setSelectedSubCategory(e.target.value)}
                      >
                        <MenuItem value="">
                          <em>Select Subcategory</em>
                        </MenuItem>
                        {subcategories.map((subcategory: any) => (
                          <MenuItem key={subcategory.id} value={subcategory}>
                            {subcategory.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  Category: {product?.subcategory?.name || "None"}
                </Typography>
                <div>
                  <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddNewVariants}
                    >
                      Add New Variants
                    </Button>
                  </Box>

                  {/* Multi-select dropdown for variants */}
                  <Dialog
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    fullWidth
                  >
                    <DialogTitle>Select Variants</DialogTitle>
                    <DialogContent>
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="variant-select-label">
                            Select Variants
                          </InputLabel>
                          <Select
                            labelId="variant-select-label"
                            label="Variants"
                            multiple
                            value={selectedVariants}
                            onChange={handleVariantSelection}
                            renderValue={(selected) =>
                              selected
                                .map(
                                  (variant: any) =>
                                    variant.size + " " + variant.color
                                )
                                .join(", ")
                            } // Render the selected variant names
                          >
                            {availableVariants.map((variant: any) => (
                              <MenuItem key={variant.id} value={variant}>
                                <Checkbox
                                  checked={selectedVariants.some(
                                    (selectedVariant: any) =>
                                      selectedVariant.id === variant.id
                                  )}
                                />
                                <Grid container alignItems="center" spacing={2}>
                                  {/* Variant Image */}
                                  <Grid item>
                                    <img
                                      src={variant.image}
                                      alt={variant.name}
                                      style={{
                                        width: 60,
                                        height: 60,
                                        objectFit: "cover",
                                        borderRadius: "5px",
                                      }}
                                    />
                                  </Grid>
                                  {/* Variant Color and Size */}
                                  <Grid item xs>
                                    <Typography variant="body2">
                                      {variant.name}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Size: {variant.size}
                                    </Typography>
                                    {/* Color block */}
                                    <Box display={"flex"}>
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                      >
                                        Color:
                                      </Typography>
                                      <Box
                                        sx={{
                                          width: 20,
                                          height: 20,
                                          backgroundColor: variant.color_code,
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenModal(false)}
                        color="primary"
                      >
                        Cancel
                      </Button>
                      <Button onClick={handleAddVariants} color="primary">
                        Add
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>

                <TableContainer
                  component={Paper}
                  style={{ marginTop: "20px" }}
                  sx={{
                    maxHeight: 600,
                    overflowY: "auto",
                  }}
                >
                  <Table>
                    <TableBody>
                      {productVariants.map((variant) => (
                        <TableRow key={variant.id}>
                          {/* Variant Information */}
                          <TableCell>
                            <Typography
                              variant="subtitle1"
                              gutterBottom
                              sx={{
                                fontSize: { xs: "0.8rem", sm: "1rem" },
                                fontWeight: "bold",
                              }}
                            >
                              {variant.name}
                            </Typography>
                            <Typography variant="body2">
                              #{variant.syncedVariantId}
                            </Typography>
                            {variant.syncedVariantId ? (
                              <Button
                                color="error"
                                onClick={() => confirmDeleteVariant(variant.id)}
                                style={{ marginRight: "10px" }}
                              >
                                Remove
                              </Button>
                            ) : (
                              <Button
                                color="error"
                                onClick={() => confirmRemoveVariant(variant.id)}
                                style={{ marginRight: "10px" }}
                              >
                                Remove
                              </Button>
                            )}
                          </TableCell>

                          {/* Printful Item Section */}
                          <TableCell>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <img
                                  src={variant.image}
                                  alt={variant.name}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "8px",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={8}>
                                {/* <Typography variant="subtitle1">
                      <strong>{variant.name}</strong>
                    </Typography> */}
                                <Typography variant="body2">
                                  <strong>Color:</strong>{" "}
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "15px",
                                      height: "15px",
                                      backgroundColor: variant.color_code,
                                      borderRadius: "50%",
                                      marginRight: "5px",
                                      verticalAlign: "middle",
                                    }}
                                  ></span>{" "}
                                  {variant.color}
                                </Typography>
                                <Typography variant="body2">
                                  <strong>Size:</strong> {variant.size}
                                </Typography>
                                <Typography variant="body2">
                                  <strong> Price:</strong> ${variant.price}
                                </Typography>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                      <div>
                        {/* Button to open the delete modal */}

                        {/* Modal */}
                        <Dialog
                          open={isDeleteModalOpen}
                          onClose={deleteCloseModal}
                          fullWidth
                        >
                          <DialogTitle textAlign={"center"}>
                            Confirm Remove
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText>
                              Are you sure you want to remove this variant?
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleDeleteVariant}
                              color="error"
                              variant="contained"
                            >
                              Yes, Remove
                            </Button>
                            <Button
                              onClick={deleteCloseModal}
                              color="primary"
                              variant="outlined"
                            >
                              Cancel
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}

            {selectedCategory && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    Select Product
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="product-select-label">
                      Select Product
                    </InputLabel>
                    <Select
                      labelId="product-select-label"
                      label="Select Product"
                      value={selectedProduct}
                      onChange={handleProductChange}
                      error={!!errors.selectedProduct} // Show error if validation fails
                    >
                      {products.map((product: any) => (
                        <MenuItem key={product.id} value={product}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid item>
                              {/* Assuming product.image is the URL to the product image */}
                              <img
                                src={product.image}
                                alt={product.title}
                                width={90}
                                height={90}
                                style={{ borderRadius: "50%" }}
                              />
                            </Grid>
                            <Grid item>
                              <Typography variant="body1">
                                {product.title}
                              </Typography>
                            </Grid>
                          </Grid>
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.selectedProduct && (
                      <Typography
                        sx={{
                          color: "#d32f2f",
                          fontSize: "12px",
                          marginTop: "3px",
                          marginLeft: "14px",
                          fontWeight: 400,
                        }}
                      >
                        {errors.selectedProduct}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12} mt={2}>
              {selectedProduct && (
                <Grid item xs={12}>
                  {!variants.length ? (
                    <Typography variant="body2" color="error">
                      There are no variats in the USA for the slected product
                    </Typography>
                  ) : (
                    <>
                      <FormControl fullWidth>
                        <InputLabel id="variant-select-label">
                          Select Variants
                        </InputLabel>
                        <Select
                          labelId="variant-select-label"
                          label="Select Variants"
                          multiple
                          value={selectedVariants}
                          onChange={(e) => handleVariantChange(e)}
                          sx={{
                            "& .MuiSelect-select": {
                              whiteSpace: "wrap",
                            },
                          }}
                          renderValue={(selected) =>
                            selected
                              .map(
                                (variant: any) =>
                                  variant.size + " " + variant.color
                              )
                              .join(", ")
                          }
                          error={!!errors.selectedVariants}
                        >
                          {variants.map((variant: any) => (
                            <MenuItem key={variant.id} value={variant}>
                              <Checkbox
                                checked={selectedVariants.some(
                                  (selectedVariant: any) =>
                                    selectedVariant.id === variant.id
                                )}
                              />
                              <Grid
                                container
                                alignItems="center"
                                spacing={2}
                                sx={{
                                  maxHeight: 500,
                                  overflowY: "auto",
                                }}
                              >
                                {/* Variant Image */}
                                <Grid item>
                                  <img
                                    src={variant.image}
                                    alt={variant.name}
                                    style={{
                                      width: 60,
                                      height: 60,
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                  />
                                </Grid>
                                {/* Variant Color and Size */}
                                <Grid item xs>
                                  <Typography variant="body2">
                                    {variant.name}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    Size: {variant.size}
                                  </Typography>
                                  {/* Color block */}
                                  <Box display={"flex"}>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      Color:
                                    </Typography>
                                    <Box
                                      sx={{
                                        width: 20,
                                        height: 20,
                                        backgroundColor: variant.color_code,
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.selectedVariants && (
                          <Typography
                            sx={{
                              color: "#d32f2f",
                              fontSize: "12px",
                              marginTop: "3px",
                              marginLeft: "14px",
                              fontWeight: 400,
                            }}
                          >
                            {errors.selectedVariants}
                          </Typography>
                        )}
                      </FormControl>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="large"
                        checked={isSoldOut}
                        onChange={(e) => setIsSoldOut(e.target.checked)}
                      />
                    }
                    label="Sold Out"
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Grid container spacing={4}>
                    {/* Front Design Upload */}
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          border: `2px dashed ${errors.image ? "red" : "#ccc"}`,
                          borderRadius: "8px",
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "relative",
                        }}
                      >
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={handleImageChange}
                            required
                          />
                          <ImageIcon sx={{ fontSize: 50, color: "#ccc" }} />
                        </IconButton>
                        <Typography
                          sx={{
                            position: "absolute",
                            marginTop: "130px",
                            color: "#999",
                          }}
                        >
                          Upload Front Design
                        </Typography>
                      </Box>

                      {errors.image && (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "0.875rem",
                            marginTop: "20px",
                          }}
                        >
                          {errors.image}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {imagePreview && (
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          justifyContent="center"
                        >
                          <img
                            src={imagePreview}
                            alt="Selected"
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100px",
                              borderRadius: "8px",
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>

                    {/* Back Design Upload */}
                    {(backDesign == true || product?.back_design_image) && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <Box
                            sx={{
                              border: `2px dashed ${
                                errors.backDesignImage ? "red" : "#ccc"
                              }`,
                              borderRadius: "8px",
                              width: "100%",
                              height: "100px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                            }}
                          >
                            <IconButton
                              color="primary"
                              aria-label="upload back design"
                              component="label"
                            >
                              <input
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={handleBackDesignChange}
                                required
                              />
                              <ImageIcon sx={{ fontSize: 50, color: "#ccc" }} />
                            </IconButton>
                            <Typography
                              sx={{
                                position: "absolute",
                                marginTop: "130px",
                                color: "#999",
                              }}
                            >
                              Upload Back Design
                            </Typography>
                          </Box>

                          {errors.backDesignImage && (
                            <Typography
                              sx={{
                                color: "red",
                                fontSize: "0.875rem",
                                marginTop: "20px",
                              }}
                            >
                              {errors.backDesignImage}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          {backDesignPreview && (
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              justifyContent="center"
                            >
                              <img
                                src={backDesignPreview}
                                alt="Back Design Selected"
                                style={{
                                  maxWidth: "100%",
                                  maxHeight: "100px",
                                  borderRadius: "8px",
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {selectedVariants && selectedVariants.length > 0 && (
              <Grid item xs={12} mt={2}>
                <TableContainer
                  component={Paper}
                  sx={{
                    maxHeight: 500,
                    overflowY: "auto",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: "600",
                            textAlign: "center",
                            padding: { xs: "2px", sm: "16px" },
                          }}
                        >
                          Varient
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "600",
                            textAlign: "center",
                            padding: { xs: "2px", sm: "16px" },
                          }}
                        >
                          Image
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "600",
                            textAlign: "center",
                            padding: { xs: "2px", sm: "16px" },
                          }}
                        >
                          Profit Price
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "600",
                            textAlign: "center",
                            padding: { xs: "2px", sm: "16px" },
                          }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "600",
                            textAlign: "center",
                            padding: { xs: "2px", sm: "16px" },
                          }}
                        >
                          Total Price
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedVariants.map((variant, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{variant.size}</TableCell>
                          <TableCell align="center">
                            <img
                              src={variant.image}
                              alt={`Variant ${index}`}
                              style={{ width: "50px", height: "auto" }}
                            />
                          </TableCell>
                          <TableCell align="center">{price}</TableCell>
                          <TableCell align="center">{variant.price}</TableCell>
                          <TableCell align="center">
                            {Number(price) + Number(variant.price)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}

            <Grid item xs={12} mt={2}>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={4}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  if (errors.description) {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      description: "",
                    }));
                  }
                }}
                error={!!errors.description}
                helperText={errors.description}
              />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                gap={2}
                mt={2}
              >
                <Button
                  fullWidth
                  variant="contained"
                  color="warning"
                  size="large"
                  sx={{ minWidth: "120px" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fullWidth
                  variant="outlined"
                  color="warning"
                  size="large"
                  sx={{ minWidth: "120px" }}
                  onClick={() => navigate("/admin/products")}
                >
                  Discard
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
