import React, { useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import contactusCover from "../../assets/images/contactusover.png";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { toast } from "react-toastify";
import { handleApiError } from "../common/Api-error-handler";
import { logout } from "../../store/auth/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  // State variables to hold form data
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation() as { t: (key: string) => string };

  const validateForm = () => {
    const newErrors = { name: "", email: "", phone: "", message: "" };
    let isValid = true;

    if (!name.trim()) {
      newErrors.name = t("contact_us.validation_error.name");
      isValid = false;
    }
    if (
      !email.trim() ||
      !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)
    ) {
      newErrors.email = t("contact_us.validation_error.valid_email");
      isValid = false;
    }
    if (!phone.trim()) {
      newErrors.phone = t("contact_us.validation_error.phoneNumber");
      isValid = false;
    }
    if (!message.trim()) {
      newErrors.message = t("contact_us.validation_error.meassage");
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateForm()) {
      return; // If form is invalid, don't submit
    }
    const formData = { name, email, phone, message };

    try {
      // Send form data to backend API
      const response = await axios.post(
        `${baseUrl}/api/users/contact`,
        formData
      );
      toast.success(response.data.message);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setErrors({ name: "", email: "", phone: "", message: "" });
      setLoading(false);
    } catch (error) {
      const { message, navigateTo } = handleApiError(error);
      toast.error(message);
      if (navigateTo) {
        if (navigateTo == "login") {
          dispatch(logout());
        }
        navigate(`/${navigateTo}`);
      }
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Grid container sx={{ justifyContent: "center" }}>
        <Grid item xs={11} md={11} lg={11} xl={9}>
          <Grid
            container
            borderRadius={{ xs: "20px", sm: "30px", md: "40px" }}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexShrink={0}
            padding={{ xs: 2, sm: 4, md: 6 }}
            sx={{
              backgroundImage: `url(${contactusCover})`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: { xs: "200px", sm: "350px", md: "600px" },
              margin: "40px 0px",
            }}
          ></Grid>
        </Grid>
      </Grid>

      <Container maxWidth={"xl"}>
        <Grid
          container
          width="100%"
          direction="column"
          rowSpacing={2}
          flexShrink={0}
        >
          <Grid item xs={12} sx={{ margin: "20px 0px" }}>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} md={6}>
                <Grid container rowSpacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontFamily: "Syne",
                        fontSize: {
                          xs: "20px",
                          sm: "28px",
                          md: "28px",
                          lg: "32px",
                        },
                        fontWeight: "bold",
                      }}
                    >
                      {t("contact_us.get_in_touch")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        fontFamily: "Syne",
                        fontSize: {
                          xs: "26px",
                          sm: "34px",
                          md: "30px",
                          lg: "48px",
                        },
                        fontWeight: "bold",
                        width: {
                          xs: "100%",
                          sm: "80%",
                          md: "400px",
                          lg: "628px",
                        },
                      }}
                      variant="h3"
                    >
                      {t("contact_us.we_are_here_to_help_you_grow")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: {
                          xs: "16px",
                          sm: "20px",
                          md: "20px",
                          lg: "28px",
                        },
                      }}
                    >
                      {t("contact_us.contact_description")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      backgroundColor: "#FFE7DB",
                      border: "0.5px solid",
                      borderRadius: "20px",
                      marginTop: "25px",
                      padding: { xs: "20px", sm: "25px", md: "30px" },
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12} lg={6}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: { xs: "18px", sm: "20px", md: "24px" },
                            fontWeight: 400,
                          }}
                        >
                          Community Email
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: { xs: "24px", sm: "28px", md: "28px" },
                            fontWeight: 700,
                            color: "#FF5A00",
                          }}
                        >
                          community@lyfers.com
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={6}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: { xs: "18px", sm: "20px", md: "24px" },
                            fontWeight: 400,
                          }}
                        >
                          {t("contact_us.store_email")}
                        </Typography>
                        <Typography
                          variant="h4"
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: { xs: "24px", sm: "28px", md: "28px" },
                            fontWeight: 700,
                            color: "#FF5A00",
                          }}
                        >
                          store@lyfers.com
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper
                  sx={{
                    padding: { xs: 2, sm: 2, md: 2 },
                    backgroundColor: "#FFE7DB",
                    border: "1px solid",
                    borderRadius: "15px",
                  }}
                >
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("contact_us.input_labels.name")}
                          variant="outlined"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          error={!!errors.name}
                          helperText={errors.name}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("contact_us.input_labels.email")}
                          variant="outlined"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          error={!!errors.email}
                          helperText={errors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={t("contact_us.input_labels.phone_number")}
                          variant="outlined"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          error={!!errors.phone}
                          helperText={errors.phone}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          minRows={5}
                          label={t("contact_us.input_labels.message")}
                          variant="outlined"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          error={!!errors.message}
                          helperText={errors.message}
                        />
                      </Grid>
                      <Grid item xs={12} textAlign="center">
                        <Button
                          fullWidth
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                          sx={{
                            padding: { xs: "10px", sm: "15px" },
                          }}
                        >
                          {t("contact_us.get_in_touch")}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ContactUs;
