import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation() as { t: (key: string) => string };
  const privacyData = [
    {
      title: t("privacy_policy.policy_data.information_we_collect.title"),
      content: t("privacy_policy.policy_data.information_we_collect.content"),
    },
    {
      title: t("privacy_policy.policy_data.how_we_use_your_information.title"),
      content: t(
        "privacy_policy.policy_data.how_we_use_your_information.content"
      ),
    },
    {
      title: t(
        "privacy_policy.policy_data.cookies_tracking_technologies.title"
      ),
      content: t(
        "privacy_policy.policy_data.cookies_tracking_technologies.content"
      ),
    },
    {
      title: t("privacy_policy.policy_data.third_party_services.title"),
      content: t("privacy_policy.policy_data.third_party_services.content"),
    },
    {
      title: t("privacy_policy.policy_data.data_security.title"),
      content: t("privacy_policy.policy_data.data_security.content"),
    },
    {
      title: t("privacy_policy.policy_data.your_rights_choices.title"),
      content: t("privacy_policy.policy_data.your_rights_choices.content"),
    },
    {
      title: t("privacy_policy.policy_data.changes_to_this_policy.title"),
      content: t("privacy_policy.policy_data.changes_to_this_policy.content"),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h4" fontWeight="bold" fontFamily="Outfit">
          {t("privacy_policy.Privacy_Policy_LYFERS")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.8,
            fontFamily: "Outfit",
            fontWeight: 400,
            fontSize: "18px",
            mt: 2,
          }}
        >
          {t("privacy_policy.Your_privacy_is_important_to_us")}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {privacyData.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card sx={{ mb: 2, backgroundColor: "#f5f5f5" }}>
              <CardContent>
                <Typography
                  fontWeight="bold"
                  fontSize="18px"
                  fontFamily="Outfit"
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    lineHeight: 1.8,
                    fontFamily: "Outfit",
                    fontSize: "16px",
                  }}
                >
                  {item.content}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 5, textAlign: "center" }}>
        <Typography fontWeight="bold" fontSize="20px" fontFamily="Outfit">
        {t("privacy_policy.Contact_Us")}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.8,
            fontFamily: "Outfit",
            fontSize: "16px",
            mt: 2,
          }}
        >
          {t("privacy_policy.if_you_have_any_questions")} {" "}
          at <strong>support@lyfers.com</strong>.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
