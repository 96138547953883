import React from "react";
import {
  Box,
  Typography,
  keyframes,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { ReactComponent as LogoBlackSvg } from "../assets/logos/LogoDefault.svg";
import coverImage from "../assets/images/LYFERS-Collage.png";

// Define the gradient movement animation
const gradientAnimation = keyframes`
  0% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
`;

const ComingSoonPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "0 20px",
        textAlign: "center",
        backgroundImage: `linear-gradient(190deg, rgba(0, 0, 0, 0.91) 46%, rgba(255, 83, 0, 0.91) 100%), url(${coverImage})`,
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundColor: "transparent",
        animation: `${gradientAnimation} 5s infinite alternate`, // Add gradient animation
      }}
    >
      {/* Logo */}
      <Box
        sx={{
          height: { xs: 140, sm: 150, md: 210 },
          mb: 4,
          "& svg": {
            width: isMobile ? 300 : isTablet ? 500 : 700, // Larger sizes for all screens
          },
        }}
      >
        <Box component={LogoBlackSvg} />
      </Box>

      {/* Main Title with animation */}
      <Typography
        variant={isMobile ? "h4" : isTablet ? "h3" : "h2"}
        sx={{
          color: "rgb(255, 255, 255)",
          fontWeight: "bold",
          mb: 2, // Adds spacing below the title
          //   animation: `${pulseAnimation} 1.5s infinite`, // Add pulsing animation
        }}
      >
        Coming Soon
      </Typography>

      {/* Subtext */}
      <Typography
        variant="body1"
        sx={{
          color: "rgb(255, 255, 255)",
          fontSize: isMobile ? "1rem" : isTablet ? "1.2rem" : "1.5rem", // Responsive text size
          mb: 4, // Adds spacing below the subtext
        }}
      >
        We're working hard to bring you something amazing. Stay tuned!
      </Typography>
    </Box>
  );
};

export default ComingSoonPage;
