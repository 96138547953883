import { produce } from "immer";
import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

const initialState: dataTypes.InitialStateSubCategoryCategories = {
  subCategoryCategories: [],
};

const subCategoryCategoriesReducer = produce((state, action) => {
  switch (action.type) {
    
    case actionTypes.FETCH_SUBCATEGORY_CATEGORIES_SUCCESS:
      state.subCategoryCategories = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default subCategoryCategoriesReducer;
