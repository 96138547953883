import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import PostCard from "./PostCard";
import PopularGroups from "./PopularGroups";
import { Container } from "@mui/system";
import eventsCover from "../../assets/images/communityPage.png";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchCommunityPostsRequest } from "../../store/communityPost/actions";
import {
  fetchApprovedCommunityGroupsRequest,
  fetchUserJoinedGroupsRequest,
} from "../../store/communityGroup/actions";
import { useTranslation } from "react-i18next";

interface Group {
  id: number;
  name: string;
  description: string;
  lastActivity: string;
  postsCount: number;
}

interface Post {
  id: number;
  title: string;
  userId: string;
  description: string;
  createdAt: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  Group: {
    id: number;
    name: string;
  };
  PostImages: any[];
  Comments: any[];
}

const CommunityPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [groupsLoading, setGroupsLoading] = useState(true);
  const [postsLoading, setPostsLoading] = useState(true);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { t } = useTranslation() as { t: (key: string) => string };

  const { communityPosts: posts } = useSelector(
    (state: any) => state?.communityPostReducer
  );

  const { approvedCommunityGroups, userJoinedGroups } = useSelector(
    (state: any) => state?.communityGroupReducer
  );
  let sortedPosts = [];

  // Fetch posts and groups
  useEffect(() => {
    const fetchPosts = async () => {
      dispatch(
        fetchCommunityPostsRequest({
          setLoading: setPostsLoading,
          navigate,
          token: currentUser?.token,
        })
      );

      if (currentUser) {
        dispatch(
          fetchUserJoinedGroupsRequest({
            userId: currentUser?.user.id,
            setLoading: setGroupsLoading,
            navigate,
            token: currentUser?.token,
          })
        );
      } else {
        dispatch(
          fetchApprovedCommunityGroupsRequest({
            setLoading: setGroupsLoading,
            navigate,
          })
        );
      }
    };

    fetchPosts();
  }, []);

  // Sort posts by number of comments (descending)
  sortedPosts = [...posts].sort(
    (a, b) => b.Comments.length - a.Comments.length
  );

  const handleDeletePost = (deletedPostId: number) => {
    // Filter out the deleted post from the posts array
    sortedPosts = posts.filter((post: Post) => post.id !== deletedPostId);
    // Update the posts in the state
    dispatch(
      fetchCommunityPostsRequest({
        setLoading: setPostsLoading,
        navigate,
        token: currentUser?.token,
      })
    );
  };

  return (
    <>
      <Grid item xs={11} md={11} lg={11} xl={9.2}
        paddingX={1}
        paddingY={{xs:14, sm: 0}}
       >
        <Box
          justifyContent="center"
          alignItems="center" 
          display="flex"
          flexShrink={0}
          padding={{ xs: 2, sm: 4, md: 6 }}
          sx={{
            backgroundImage: `url(${eventsCover})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: { xs: "150px", sm: "200px", md: "350px" },
          }}
        ></Box>
      </Grid>
      <Container
        maxWidth={"lg"}
        sx={{
          marginBottom: "40px",
        }}
      >
        <Grid
          container
          width="100%"
          padding={{ xs: 2, sm: 3, md: 4 }}
          flexShrink={0}
        >
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "28px", md: "40px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
              }}
            >
              {t("community.community")}
            </Typography>
          </Grid>
        </Grid>
        {groupsLoading || postsLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                gap: "16px",
                flexDirection: "column",
                justifyContent: "center",
                display: {
                  sm: "flex",
                  xs: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "25px",
                  padding: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: {
                      xs: "16px",
                      sm: "20px",
                      md: "20px",
                      lg: "28px",
                    },
                  }}
                >
                  {t("community.greeting_new_LYFERS")}
                </Typography>
                {sortedPosts.length > 0 ? (
                  sortedPosts.map((post: Post) => (
                    <PostCard
                      key={post.id}
                      post={post}
                      showComments={false}
                      onDelete={handleDeletePost}
                    />
                  ))
                ) : (
                  <Typography>{t("community.no_posts_available")}</Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "24px",
                  padding: "1rem",
                }}
              >
                <PopularGroups
                  groups={
                    currentUser ? userJoinedGroups : approvedCommunityGroups
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default CommunityPage;
