import { produce } from "immer";
import * as types from "./productActionTypes";
import * as dataTypes from "./productTypes";

const initialState: dataTypes.InitialStateProduct = {
  products: [],
  product: null,
};

const productReducer = produce((state, action) => {
  switch (action.type) {

    case types.FETCH_PRODUCTS_SUCCESS:
      state.products = action.payload;
      break;

    case types.GET_FEATURE_PRODUCTS_SUCCESS:
      state.products = action.payload;
      break;

    case types.DELETE_PRODUCT_SUCCESS:
      state.products = state.products.filter(
        (item) => item.id !== action.payload.selectedProductId
      );
      break;

    case types.GET_PRODUCT_SUCCESS:
      state.product = action.payload;
      break;

    case types.GET_PRODUCTS_BY_CATEGORY_SUCCESS:
      state.products = action.payload;
      break;

    case types.GET_RANDOM_PRODUCTS_SUCCESS:
      state.products = action.payload;
      break;

    case types.CREATE_PRODUCT_SUCCESS:
      state.products = [...state.products, action.payload.newProduct];
      break;

    case types.UPDATE_PRODUCT_SUCCESS:
      state.products = state.products.map((item) =>
        item.id === action.payload.productId
          ? action.payload.updatedProduct
          : item
      );
      break;

    default:
      break;
  }
}, initialState);

export default productReducer;