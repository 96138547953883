import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import baseUrl from "../../../config/apiConfig";
import { toast } from "react-toastify";
import { handleApiError } from "../../common/Api-error-handler";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../store/auth/actions";
import {
  fetchColorRequest,
  deleteColorRequest,
  createColorRequest,
  updateColorRequest,
} from "../../../store/colors/colorActions";

interface Color {
  id: number;
  name: string;
  code: string;
}

export default function Colors() {
  //const [colors, setColors] = useState<Color[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newColorName, setNewColorName] = useState<string>("");
  const [newColorCode, setNewColorCode] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentColorId, setCurrentColorId] = useState<number | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    nameError?: string;
    codeError?: string;
  }>({});

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { colors } = useSelector((state: any) => state?.colorReducer);

  useEffect(() => {
    dispatch(fetchColorRequest({ setLoading, navigate }));
  }, []);

  const handleOpenEditModal = (color: Color) => {
    setCurrentColorId(color.id);
    setNewColorName(color.name);
    setNewColorCode(color.code);
    setEditMode(true);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditMode(false);
    setNewColorName("");
    setNewColorCode("");
    setCurrentColorId(null);
  };

  // const handleError = (message: string) => {
  //   toast.error(message);
  // };

  const validateForm = () => {
    const newErrors: { nameError?: string; codeError?: string } = {};

    if (!newColorName.trim()) {
      newErrors.nameError = " Name is required.";
    }
    if (!newColorCode.trim()) {
      newErrors.codeError = "Color code is requied.";
    }

    setErrors(newErrors);

    // Return true if no errors, false if errors exist
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    const colorData = { name: newColorName, code: newColorCode };
    if (editMode && currentColorId !== null) {
      // Update existing color
      dispatch(
        updateColorRequest({
          currentColorId,
          colorData,
          token: currentUser?.token,
          navigate,
        })
      );
    } else {
      // Create new color
      dispatch(
        createColorRequest({ colorData, token: currentUser?.token, navigate })
      );
    }
    handleCloseModal();
  };

  const handleDelete = (id: number) => {
    dispatch(deleteColorRequest({ id, token: currentUser?.token, navigate }));
    setDeleteConfirmOpen(false);
  };


  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  // if (error) {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       alignItems="center"
  //       height="100vh"
  //     >
  //       <Typography variant="h6" color="error">
  //         {error}
  //       </Typography>
  //     </Grid>
  //   );
  // }

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      <Grid item xs={12}>
        <Grid container direction="column" flexShrink={0} justifyContent={'center'} alignItems={'center'} >
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            sx={{ marginRight: { xs: 0, sm: 0, md: '34%' } }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontWeight: 700,
                lineHeight: 1,
                color: "#000000",
                display:'block',
                mt:2
              }}
            >
              All Colors
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} display="flex" alignItems="center">
        <Grid container justifyContent={"space-between"} >
          <Grid item alignItems={"center"} display={"flex"}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Outfit",
                fontSize: { xs: "20px" },
                fontWeight: "bold",
                display:{xs:'none', sm:'block'},
                marginLeft:'5px'
              }}
            >
              All colors
            </Typography>
          </Grid>
          <Grid item display={"flex"} justifyContent={"flex-end"} gap={2}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                padding: { xs: "10px", sm: "15px" },
                textTransform: "capitalize",
                
              }}
              onClick={() => {
                setModalOpen(true);
                setEditMode(false);
              }}
            >
              Add New Color
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                  Name
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                  Code
                </TableCell>
                <TableCell sx={{ textAlign: "center", fontWeight: "bold" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {colors
                .filter((color: Color) =>
                  color.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
                .map((color: Color, index: number) => (
                  <TableRow key={color.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {color.name}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      {color.code}
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      <Button onClick={() => handleOpenEditModal(color)}>
                        Edit
                      </Button>
                      <Button
                        onClick={() => {
                          setCurrentColorId(color.id);
                          setDeleteConfirmOpen(true);
                        }}
                        color="error"
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {colors.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Typography textAlign="center">No Colors Found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            width: "600px",
            maxWidth: "90%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle textAlign={"center"} fontWeight={"bold"}>
          {editMode ? "Edit Color" : "Add New Color"}
        </DialogTitle>
        <DialogContent>
  <TextField
    label="Color Name"
    type="text"
    margin="dense"
    fullWidth
    value={newColorName}
    onChange={(e) => {
      setNewColorName(e.target.value);
      // Clear the name error when the user starts typing in Color Name
      if (errors.nameError) {
        setErrors((prevErrors) => ({ ...prevErrors, nameError: '' }));
      }
    }}
    error={!!errors.nameError}
    helperText={errors.nameError}
  />
  
  <TextField
    label="Color Code"
    type="text"
    margin="dense"
    fullWidth
    value={newColorCode}
    onChange={(e) => {
      setNewColorCode(e.target.value);
      // Clear the name error when the user starts typing in Color Code
      if (errors.nameError) {
        setErrors((prevErrors) => ({ ...prevErrors, nameError: '' }));
      }
    }}
    error={!!errors.nameError}
    helperText={errors.nameError}
  />
</DialogContent>

        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          style: {
            width: "600px",
            maxWidth: "90%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle textAlign={"center"} fontWeight={"bold"}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography textAlign={"center"}>
            Are you sure you want to delete this color?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              currentColorId !== null && handleDelete(currentColorId)
            }
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
