// import { BrowserRouter, Route, Routes } from "react-router-dom";
// import routes from "./routes/allRoutes";
// import { PrivateRoute } from "./routes/PrivateRoute";
// import { PublicRoute } from "./routes/PublicRoute";
// import Layout from "./components/Layout";
// import FrontLayout from "./pages/common/Layout";
// import { useSelector } from "react-redux";
// import AdminLayout from "./components/admin/AdminLayout";
// import jwtDecode from 'jwt-decode';

// interface UserPayload {
//   id: string;
//   email: string;
//   role: string;
// }

// const App = () => {
//   const auth = useSelector((state: any) => state?.Auth);

//   const getUserDetailsFromToken = (token: string): UserPayload | null => {
//     try {
//       const decodedToken: UserPayload = jwtDecode(token);
//       return decodedToken;
//     } catch (error) {
//       console.error("Invalid token", error);
//       return null;
//     }
//   };

//   let userDetails: UserPayload | null = null;

//   const token = auth.currentUser?.token;
//   if (token) {
//     userDetails = getUserDetailsFromToken(token);
//   }

//   return (
//     <div>

//      <BrowserRouter>
//       <Routes>
//         {routes.map((route, i) => {
//           return (
//             <Route
//               key={i}
//               path={route.path}
//               element={
//                 !route.ispublic ? (
//                   !route.isAuth ? (
//                     <PrivateRoute>
//                       <FrontLayout>
//                         {userDetails?.role === "admin" ? (
//                           route.addlayout ? (
//                             <AdminLayout>{route.component}</AdminLayout>
//                           ) : (
//                             route.component
//                           )
//                         ) :

//                         route.addlayout ? (
//                           <Layout>{route.component}</Layout>
//                         ) : (
//                           route.component
//                         )
//                        }
//                       </FrontLayout>
//                     </PrivateRoute>
//                   ) : (
//                     <PrivateRoute>{route.component}</PrivateRoute>
//                   )
//                 ) : (
//                   <PublicRoute>
//                     <FrontLayout>{route.component}</FrontLayout>
//                   </PublicRoute>
//                 )
//               }
//             />
//           );
//         })}
//       </Routes>
//     </BrowserRouter>

//     </div>
//   );
// };

// export default App;

import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import routes from "./routes/allRoutes";
import { PrivateRoute } from "./routes/PrivateRoute";
import { PublicRoute } from "./routes/PublicRoute";
import Layout from "./components/Layout";
import FrontLayout from "./pages/common/Layout";
import { useSelector } from "react-redux";
import AdminLayout from "./components/admin/AdminLayout";
import jwtDecode from "jwt-decode";
import CommunityLayout from "./pages/common/CommunityLayout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ComingSoonPage from "./routes/ComingSoonPage";
import { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./i18n";

const stripePublicKey =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(stripePublicKey || "");

interface UserPayload {
  id: string;
  email: string;
  role: string;
}

const App = () => {
  const auth = useSelector((state: any) => state?.Auth);
  const [comingSoon, setComingSoon] = useState(false);

  // useEffect(() => {
  //   if (window.location.href.includes("http://lyfers.com")) {
  //     setComingSoon(true);
  //   } else {
  //     setComingSoon(false);
  //   }
  // }, []);

  const getUserDetailsFromToken = (token: string): UserPayload | null => {
    try {
      const decodedToken: UserPayload = jwtDecode(token);
      return decodedToken;
    } catch (error) {
      console.error("Invalid token", error);
      return null;
    }
  };

  let userDetails: UserPayload | null = null;

  const token = auth.currentUser?.token;
  if (token) {
    userDetails = getUserDetailsFromToken(token);
  }

  return (
    <div>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <Elements stripe={stripePromise}>
            {comingSoon ? (
              <ComingSoonPage />
            ) : (
              <Routes>
                {routes.map((route, i) => {
                  return (
                    <Route
                      key={i}
                      path={route.path}
                      element={
                        !route.ispublic ? (
                          !route.isAuth ? (
                            <PrivateRoute>
                              {route.isCommunity ? (
                                <CommunityLayout>
                                  {route.component}
                                </CommunityLayout>
                              ) : (
                                <FrontLayout>
                                  {route.adminOnly ? (
                                    userDetails?.role === "admin" ? (
                                      route.addlayout ? (
                                        <AdminLayout>
                                          {route.component}
                                        </AdminLayout>
                                      ) : (
                                        route.component
                                      )
                                    ) : (
                                      <Navigate to="/access-denied" />
                                    )
                                  ) : route.addlayout ? (
                                    <Layout>{route.component}</Layout>
                                  ) : (
                                    route.component
                                  )}
                                </FrontLayout>
                              )}
                            </PrivateRoute>
                          ) : (
                            <PrivateRoute>{route.component}</PrivateRoute>
                          )
                        ) : (
                          <PublicRoute>
                            {route.isCommunity ? (
                              <CommunityLayout>
                                {route.component}
                              </CommunityLayout>
                            ) : (
                              <FrontLayout>{route.component}</FrontLayout>
                            )}
                          </PublicRoute>
                        )
                      }
                    />
                  );
                })}
              </Routes>
            )}
          </Elements>
        </BrowserRouter>
      </I18nextProvider>
    </div>
  );
};

export default App;
