import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "@mui/system";
import PopularGroups from "./PopularGroups";
import PostCard from "./PostCard";
import {
  fetchApprovedCommunityGroupsRequest,
  fetchUserJoinedGroupsRequest,
} from "../../store/communityGroup/actions";
import { getCommunityPostDetailRequest } from "../../store/communityPost/actions";
import { useTranslation } from "react-i18next";

interface Post {
  id: number;
  title: string;
  userId: string;
  description: string;
  createdAt: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  Group: {
    id: number;
    name: string;
    createdAt: string;
  };
  PostImages: any[];
  Comments: any[];
}

interface Group {
  id: number;
  name: string;
  description: string;
  lastActivity: string;
  postsCount: number;
  createdAt: string;
}
interface showComments {
  showComments: boolean;
}

const PostDetail: React.FC = () => {
  const { postId } = useParams<{ postId: string }>(); // Get post ID from URL
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [groupsloading, setGroupsLoading] = useState(false);
  const [postLoading, setPostLoading] = useState<boolean>(true);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser); // Get current user from Redux store
  const { approvedCommunityGroups, userJoinedGroups } = useSelector(
    (state: any) => state?.communityGroupReducer
  );
  const { t } = useTranslation() as { t: (key: string) => string };
  const { communityPostDetail: post } = useSelector(
    (state: any) => state?.communityPostReducer
  );

  // Fetch post details on component mount or when postId changes
  useEffect(() => {
    const fetchPostDetailAndGroups = async () => {
      dispatch(
        getCommunityPostDetailRequest({
          postId,
          setLoading: setPostLoading,
          navigate,
          token: currentUser?.token,
        })
      );

      if (currentUser) {
        dispatch(
          fetchUserJoinedGroupsRequest({
            userId: currentUser?.user.id,
            setLoading: setGroupsLoading,
            navigate,
            token: currentUser?.token,
          })
        );
      } else {
        dispatch(
          fetchApprovedCommunityGroupsRequest({
            setLoading: setGroupsLoading,
            navigate,
          })
        );
      }
    };

    fetchPostDetailAndGroups();
  }, [postId]);

  return (
    <>
      <Container
        maxWidth={"xl"}
        sx={{
          marginBottom: "40px",
        }}
      >
        <Grid
          container
          width="100%"
          direction="column"
          padding={{ xs: 2, sm: 3, md: 4 }}
          rowSpacing={2}
          flexShrink={0}
        ></Grid>

        {postLoading || groupsloading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                gap: "16px",
                flexDirection: "column",
                justifyContent: "center",
                display: {
                  sm: "flex",
                  xs: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "12px",
                  padding: "1rem",
                }}
              >
                {/* <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: {
                      xs: "16px",
                      sm: "20px",
                      md: "20px",
                      lg: "28px",
                    },
                  }}
                >
                  Greeting New LYFERS 🥳
                </Typography> */}
                {post ? (
                  <PostCard key={post.id} post={post} showComments={true} />
                ) : (
                  <Typography>{t("community.no_posts_available")}</Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "12px",
                  padding: "1rem",
                }}
              >
                <PopularGroups
                  groups={
                    currentUser ? userJoinedGroups : approvedCommunityGroups
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default PostDetail;
