import { produce } from "immer";
import * as types from "./eventActionTypes";
import * as dataTypes from "./eventDataTypes";

const initialState: dataTypes.InitialStateEvent = {
  events: [],
  event: null,
};

const eventReducer = produce((state, action) => {
  switch (action.type) {
    
    case types.FETCH_EVENTS_SUCCESS:
      state.events = action.payload;
      break;

    case types.DELETE_EVENT_SUCCESS:
      state.events = state.events.filter(
        (item) => item.id !== action.payload.selectedEventId
      );
      break;

    case types.GET_EVENT_SUCCESS:
      state.event = action.payload;
      break;

    case types.CREATE_EVENT_SUCCESS:
      state.events = [...state.events, action.payload.newEvent];
      break;

    case types.UPDATE_EVENT_SUCCESS:
      state.events = state.events.map((item) =>
        item.id === action.payload.eventId ? action.payload.updatedEvent : item
      );
      break;

    case types.GET_UPCOMING_EVENTS_SUCCESS:
      state.events = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default eventReducer;
