import * as actionTypes from "./cartActionTypes";
import * as dataTypes from "./cartDataTypes";

// For fetching cart
export const fetchCartRequest = (payload: dataTypes.FetchCartPayload) => ({
    type: actionTypes.FETCH_CART_REQUEST,
    payload,
});

export const fetchCartSuccess = (data: dataTypes.CartItem[]) => ({
    type: actionTypes.FETCH_CART_SUCCESS,
    payload: data,
});


// For deleting cart item
export const deleteCartItemRequest = (payload: dataTypes.DeleteCartItemPayload) => ({
    type: actionTypes.DELETE_CART_ITEM_REQUEST,
    payload,
});

export const deleteCartItemSuccess = (productId: number) => ({
    type: actionTypes.DELETE_CART_ITEM_SUCCESS,
    payload: { productId }
});


// For creating cart item
export const createCartItemRequest = (payload: dataTypes.CreateCartItemPayload) => ({
    type: actionTypes.CREATE_CART_ITEM_REQUEST,
    payload,
});

export const createCartItemSuccess = (newCartItem: dataTypes.CartItem) => ({
    type: actionTypes.CREATE_CART_ITEM_SUCCESS,
    payload: { newCartItem }
});


// For getting cart item count
export const getCartItemsCountRequest = (payload: dataTypes.GetCartItemsCountPayload) => ({
    type: actionTypes.GET_CART_ITEMS_COUNT_REQUEST,
    payload,
});

export const getCartItemsCountSuccess = (cartItemsCount: number) => ({
    type: actionTypes.GET_CART_ITEMS_COUNT_SUCCESS,
    payload: cartItemsCount
});
