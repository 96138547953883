export const FETCH_CART_REQUEST = "fetch_cart_request";
export const FETCH_CART_SUCCESS = "fetch_cart_success";

export const DELETE_CART_ITEM_REQUEST = "delete_cart_item_request";
export const DELETE_CART_ITEM_SUCCESS = "delete_cart_item_success";

export const CREATE_CART_ITEM_REQUEST = "create_cart_item_request";
export const CREATE_CART_ITEM_SUCCESS = "create_cart_item_success";

export const GET_CART_ITEMS_COUNT_REQUEST = "get_cart_items_count_request";
export const GET_CART_ITEMS_COUNT_SUCCESS = "get_cart_items_count_success";