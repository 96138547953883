export const FETCH_COMMUNITY_GROUPS_REQUEST = "fetch_community_groups_request";
export const FETCH_COMMUNITY_GROUPS_SUCCESS = "fetch_community_groups_success";

export const FETCH_APPROVED_COMMUNITY_GROUPS_REQUEST = "fetch_approved_community_groups_request";
export const FETCH_APPROVED_COMMUNITY_GROUPS_SUCCESS = "fetch_approved_community_groups_success";

export const DELETE_COMMUNITY_GROUP_REQUEST = "delete_community_group_request";
export const DELETE_COMMUNITY_GROUP_SUCCESS = "delete_community_group_success";

export const UPDATE_COMMUNITY_GROUP_REQUEST = "update_community_group_request";
export const UPDATE_COMMUNITY_GROUP_SUCCESS = "update_community_group_success";

export const CREATE_COMMUNITY_GROUP_REQUEST = "create_community_group_request";
export const CREATE_COMMUNITY_GROUP_SUCCESS = "create_community_group_success";

export const FETCH_USER_JOINED_GROUPS_REQUEST = "fetch_user_joined_groups_request";
export const FETCH_USER_JOINED_GROUPS_SUCCESS = "fetch_user_joined_groups_success";

export const GET_COMMUNITY_GROUP_DETAIL_REQUEST = "get_community_group_detail_request";
