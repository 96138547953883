import React from "react";
import { Box, Typography } from "@mui/material";
import mobileCoverImage from "../../assets/images/becomeLYFERLogo.png";
import coverImage from "../../assets/images/LYFERS-Collage.png";
import { useTranslation } from "react-i18next";

const HeroBanner = () => {
  const { t } = useTranslation() as { t: (key: string) => string };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      sx={{
        backgroundImage: {
          xs: `linear-gradient(180deg, rgba(0, 0, 0, 0.91) 0%, rgba(255, 83, 0, 0.5) 100%), url(${mobileCoverImage})`,
          sm: `linear-gradient(180deg, rgba(0, 0, 0, 0.91) 0%, rgba(255, 83, 0, 0.91) 100%), url(${coverImage})`,
        },
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: { xs: "450px", sm: "500px" },
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          color: "white",
          maxWidth: "1100px",
          paddingX: "10px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Outlook",
            fontSize: { xs: "54px", sm: "84px" },
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: { xs: "70px", sm: "109px" },
            mb: "20px",
          }}
        >
          {t("becomeALyfer.bannerHeading")}
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "23px" },
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: { xs: "30px", sm: "38px" },
          }}
        >
          {t("becomeALyfer.bannerText")}
        </Typography>
      </Box>
    </Box>
  );
};

export default HeroBanner;
