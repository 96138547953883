import * as actionTypes from "./productActionTypes";
import * as dataTypes from "./productTypes";

// for fetching products
export const fetchProductsRequest = (payload: dataTypes.FetchProductsPayload) => ({
    type: actionTypes.FETCH_PRODUCTS_REQUEST,
    payload,
});

export const fetchProductsSuccess = (data: dataTypes.Product[]) => ({
    type: actionTypes.FETCH_PRODUCTS_SUCCESS,
    payload: data,
});

// feature products for home page
export const getFeatureProductsRequest = (payload: dataTypes.GetFeatureProductsPayload) => ({
    type: actionTypes.GET_FEATURE_PRODUCTS_REQUEST,
    payload,
});

export const getFeatureProductsSuccess = (data: dataTypes.Product[]) => ({
    type: actionTypes.GET_FEATURE_PRODUCTS_SUCCESS,
    payload: data,
});

// For deleting product
export const deleteProductRequest = (payload: dataTypes.DeleteProductPayload) => ({
    type: actionTypes.DELETE_PRODUCT_REQUEST,
    payload,
});

export const deleteProductSuccess = (selectedProductId: number) => ({
    type: actionTypes.DELETE_PRODUCT_SUCCESS,
    payload: { selectedProductId }
});


// for get product
export const getProductRequest = (payload: dataTypes.GetProductPayload) => ({
    type: actionTypes.GET_PRODUCT_REQUEST,
    payload,
});

export const getProductSuccess = (product: dataTypes.Product | null) => ({
    type: actionTypes.GET_PRODUCT_SUCCESS,
    payload: product,
});


// for get products by category
export const getProductsByCategoryRequest = (payload: dataTypes.GetProductsByCategoryPayload) => ({
    type: actionTypes.GET_PRODUCTS_BY_CATEGORY_REQUEST,
    payload,
});

export const getProductsByCategorySuccess = (products: dataTypes.Product[]) => ({
    type: actionTypes.GET_PRODUCTS_BY_CATEGORY_SUCCESS,
    payload: products,
});


//get random products
export const getRandomProductsRequest = (payload: dataTypes.GetRandomProductsPayload) => ({
    type: actionTypes.GET_RANDOM_PRODUCTS_REQUEST,
    payload,
});

export const getRandomProductsSuccess = (products: dataTypes.Product[]) => ({
    type: actionTypes.GET_RANDOM_PRODUCTS_SUCCESS,
    payload: products,
});


// for creating product
export const createProductRequest = (payload: dataTypes.CreateProductPayload) => ({
    type: actionTypes.CREATE_PRODUCT_REQUEST,
    payload,
});

export const createProductSuccess = (newProduct: dataTypes.NewProduct) => ({
    type: actionTypes.CREATE_PRODUCT_SUCCESS,
    payload: { newProduct }
});


// for updating product
export const updateProductRequest = (payload: dataTypes.UpdateProductPayload) => ({
    type: actionTypes.UPDATE_PRODUCT_REQUEST,
    payload,
});

export const updateProductSuccess = (productId: number | string, updatedProduct: dataTypes.UpdatedProduct) => ({
    type: actionTypes.UPDATE_PRODUCT_SUCCESS,
    payload: { productId, updatedProduct }
});