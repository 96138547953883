export const FETCH_PRODUCTS_REQUEST = "fetch_products_request";
export const FETCH_PRODUCTS_SUCCESS = "fetch_products_success";

export const GET_FEATURE_PRODUCTS_REQUEST = "get_feature_products_request";
export const GET_FEATURE_PRODUCTS_SUCCESS = "get_feature_products_success";

export const DELETE_PRODUCT_REQUEST = "delete_product_request";
export const DELETE_PRODUCT_SUCCESS = "delete_product_success";

export const GET_PRODUCT_REQUEST = "get_product_request";
export const GET_PRODUCT_SUCCESS = "get_product_success";

export const GET_PRODUCTS_BY_CATEGORY_REQUEST = "get_products_by_category_request";
export const GET_PRODUCTS_BY_CATEGORY_SUCCESS = "get_products_by_category_success";

export const GET_RANDOM_PRODUCTS_REQUEST = "get_random_products_request";
export const GET_RANDOM_PRODUCTS_SUCCESS = "get_random_products_success";

export const CREATE_PRODUCT_REQUEST = "create_product_request";
export const CREATE_PRODUCT_SUCCESS = "create_product_success";

export const UPDATE_PRODUCT_REQUEST = "update_product_request";
export const UPDATE_PRODUCT_SUCCESS = "update_product_success";