import { combineReducers } from "redux";
import Auth from "./auth/reducer";
import colorReducer from "./colors/colorReducer"
import subCategoryReducer from "./subCategories/subCategoryReducer"
import productReducer from "./products/productReducer";
import eventReducer from "./events/eventReducer";
import eventCategoryReducer from "./eventCategories/eventCategoryReducer";
import wishlistReducer from "./wishlist/wishlistReducer";
import cartReducer from "./cart/cartReducer";
import subCategoryCategoriesReducer from "./subCategoryCategories/reducer";
import securityQuestionsReducer from "./securityQuestions/reducer";
import notificationReducer from "./notification/reducer";
import communityGroupReducer from "./communityGroup/reducer";
import communityPostReducer from "./communityPost/reducer";
import communityGroupAndPostReducer from "./communityGroupAndPost/reducer";
import communityPostsAuthorReducer from "./communityPostsAuthor/reducer";

const rootReducer = combineReducers({
  Auth: Auth,
  colorReducer: colorReducer,
  subCategoryReducer: subCategoryReducer,
  productReducer: productReducer,
  eventReducer: eventReducer,
  eventCategoryReducer: eventCategoryReducer,
  wishlistReducer: wishlistReducer,
  cartReducer: cartReducer,
  subCategoryCategoriesReducer: subCategoryCategoriesReducer,
  securityQuestionsReducer: securityQuestionsReducer,
  Notification:notificationReducer,
  communityGroupReducer: communityGroupReducer,
  communityPostReducer: communityPostReducer,
  communityGroupAndPostReducer: communityGroupAndPostReducer,
  communityPostsAuthorReducer: communityPostsAuthorReducer,

});

export type AuthState = ReturnType<typeof rootReducer>;
export type NotificationState = ReturnType<typeof rootReducer>;


export default rootReducer;
