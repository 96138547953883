import { produce } from "immer";
import * as types from "./actionTypes";
import * as dataTypes from "./dataTypes";

const initialState: dataTypes.InitialStateCommunityPostsAuthor = {
  communityPostsAuthor: null,
};

const communityPostsAuthorReducer = produce((state, action) => {
  switch (action.type) {
    case types.GET_COMMUNITY_POSTS_AUTHOR_SUCCESS:
      state.communityPostsAuthor = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default communityPostsAuthorReducer;
