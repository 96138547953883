export const LOGIN = "login";
export const LOGIN_SUCCESS = "login_success";
export const CHANGE_PATH = "change_path";
export const UPDATE_PROFILE = "update_profile";
export const UPDATE_PROFILE_SUCCESS = "update_profile_success";
export const LOGOUT = "logout";
export const LOGOUT_SUCCESS = "logout_success";


export const SIGNUP = "register";
export const REGISTER_SUCCESS= "register_success";

export const FORGOTPASSWORD = "forgotPassword";
export const FORGOTPASSWORD_SUCCESS= "forgotPassword_success";

