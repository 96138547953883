import * as actionTypes from "./subCategoryActionTypes";
import * as dataTypes from "./subCategoryTypes";

// for fetching sub categories
export const fetchSubCategoryRequest = (payload: dataTypes.FetchSubCategoryPayload) => ({
    type: actionTypes.FETCH_SUB_CATEGORY_REQUEST,
    payload,
});

export const fetchSubCategorySuccess = (data: dataTypes.SubCategory[]) => ({
    type: actionTypes.FETCH_SUB_CATEGORY_SUCCESS,
    payload: data,
});


// for deleting sub category
export const deleteSubCategoryRequest = (payload: dataTypes.DeleteSubCategoryPayload) => ({
    type: actionTypes.DELETE_SUB_CATEGORY_REQUEST,
    payload,
});

export const deleteSubCategorySuccess = (id: number) => ({
    type: actionTypes.DELETE_SUB_CATEGORY_SUCCESS,
    payload: { id }
});


// for creating sub category
export const createSubCategoryRequest = (payload: dataTypes.CreateSubCategoryPayload) => ({
    type: actionTypes.CREATE_SUB_CATEGORY_REQUEST,
    payload,
});

export const createSubCategorySuccess = (newSubCategory: dataTypes.SubCategoryData) => ({
    type: actionTypes.CREATE_SUB_CATEGORY_SUCCESS,
    payload: { newSubCategory }
});


// for updating sub category
export const updateSubCategoryRequest = (payload: dataTypes.UpdateSubCategoryPayload) => ({
    type: actionTypes.UPDATE_SUB_CATEGORY_REQUEST,
    payload,
});

export const updateSubCategorySuccess = (currentSubCategoryId: number, UpdatedSubCategory: dataTypes.SubCategoryData) => ({
    type: actionTypes.UPDATE_SUB_CATEGORY_SUCCESS,
    payload: { currentSubCategoryId, UpdatedSubCategory }
});