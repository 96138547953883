export const GET_COMMUNITY_GROUPS_AND_POSTS_SEARCH_REQUEST = "get_community_groups_and_posts_search_request";
export const GET_COMMUNITY_GROUPS_AND_POSTS_SEARCH_SUCCESS = "get_community_groups_and_posts_search_success";

export const GET_COMMUNITY_GROUP_AND_ITS_POSTS_REQUEST = "get_community_group_and_its_posts_request";
export const GET_COMMUNITY_GROUP_AND_ITS_POSTS_SUCCESS = "get_community_group_and_its_posts_success";

export const JOIN_COMMUNITY_GROUP_REQUEST = "join_community_group_request";
export const JOIN_COMMUNITY_GROUP_SUCCESS = "join_community_group_success";

export const LEAVE_COMMUNITY_GROUP_REQUEST = "leave_community_group_request";
export const LEAVE_COMMUNITY_GROUP_SUCCESS = "leave_community_group_success";

export const GET_COMMUNITY_GROUP_MEMBERS_REQUEST = "get_community_group_members_request";
export const GET_COMMUNITY_GROUP_MEMBERS_SUCCESS = "get_community_group_members_success";