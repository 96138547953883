import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Avatar, CircularProgress } from "@mui/material";
import PostCard from "./PostCard";
import PopularGroups from "./PopularGroups";
import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchApprovedCommunityGroupsRequest,
  fetchUserJoinedGroupsRequest,
} from "../../store/communityGroup/actions";
import { getCommunityPostsByAuthorRequest } from "../../store/communityPost/actions";
import { getCommunityPostsAuthorRequest } from "../../store/communityPostsAuthor/actions";
import { useTranslation } from "react-i18next";

interface Group {
  id: number;
  name: string;
  description: string;
  lastActivity: string;
  postsCount: number;
}

interface Post {
  id: number;
  title: string;
  userId: string;
  description: string;
  createdAt: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  Group: {
    id: number;
    name: string;
  };
  PostImages: any[];
  Comments: any[];
}
interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  user_type: string;
}

const AuthorPosts: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [groupsLoading, setGroupsLoading] = useState(false);
  const [postsLoading, setPostsLoading] = useState(true);
  const [authorLoading, setAuthorLoading] = useState(true);
  const { t } = useTranslation() as { t: (key: string) => string };
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { approvedCommunityGroups, userJoinedGroups } = useSelector(
    (state: any) => state?.communityGroupReducer
  );
  const { communityPostsByAuthor: posts } = useSelector(
    (state: any) => state?.communityPostReducer
  );
  const { communityPostsAuthor: author } = useSelector(
    (state: any) => state?.communityPostsAuthorReducer
  );

  // Fetch posts, author and groups
  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        getCommunityPostsByAuthorRequest({
          userId,
          setLoading: setPostsLoading,
          navigate,
          token: currentUser?.token,
        })
      );

      dispatch(
        getCommunityPostsAuthorRequest({
          userId,
          setLoading: setAuthorLoading,
          navigate,
        })
      );

      if (currentUser) {
        dispatch(
          fetchUserJoinedGroupsRequest({
            userId: currentUser?.user.id,
            setLoading: setGroupsLoading,
            navigate,
            token: currentUser?.token,
          })
        );
      } else {
        dispatch(
          fetchApprovedCommunityGroupsRequest({
            setLoading: setGroupsLoading,
            navigate,
          })
        );
      }
    };

    fetchData();
  }, [userId]);

  const calculateAge = (dateOfBirth: string): number => {
    const dob = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age;
  };

  return (
    <>
      <Container
        maxWidth={"xl"}
        sx={{
          marginBottom: "40px",
        }}
      >
        <Grid
          container
          width="100%"
          direction="column"
          padding={{ xs: 2, sm: 3, md: 4 }}
          rowSpacing={2}
          flexShrink={0}
        >
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "28px", md: "40px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
              }}
            >
              {t("community.author_profile")}
            </Typography>
          </Grid>
        </Grid>

        {postsLoading || authorLoading || groupsLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={6} md={8}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Box
                    display={"flex"}
                    sx={{
                      border: "1px solid",
                      borderRadius: "25px",
                      padding: "1rem",
                    }}
                  >
                    <Avatar sx={{ width: 50, height: 50, borderRadius: "50%" }}>
                      {author
                        ? author.email.charAt(0).toUpperCase()
                        : "Loading..."}{" "}
                    </Avatar>
                    <Box ml={4}>
                      <Typography
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: {
                            xs: "16px",
                            sm: "20px",
                            md: "20px",
                            lg: "28px",
                          },
                        }}
                      >
                        {author && author.firstName && author.lastName
                          ? `${author.firstName} ${author.lastName}`
                          : author?.email || "Loading..."}
                      </Typography>

                      <Box display={"flex"}>
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: {
                              xs: "16px",
                              sm: "20px",
                              md: "20px",
                              lg: "20px",
                            },
                          }}
                        >
                          {author ? author.user_type : "Loading..."}{" "}
                        </Typography>
                        <Typography
                          ml={5}
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: {
                              xs: "16px",
                              sm: "20px",
                              md: "20px",
                              lg: "20px",
                            },
                            color: "#FF5A00",
                          }}
                        >
                          {author
                            ? calculateAge(author.dateOfBirth).toString()
                            : "Loading..."}{" "}
                          {t("community.years")}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    gap: "16px",
                    flexDirection: "column",
                    justifyContent: "center",
                    display: {
                      sm: "none",
                      xs: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid",
                      borderRadius: "25px",
                      padding: "1rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: {
                          xs: "16px",
                          sm: "20px",
                          md: "20px",
                          lg: "28px",
                        },
                      }}
                    >
                      {t("community.greeting_new_LYFERS")}
                      🥳
                    </Typography>
                    {posts.length > 0 ? (
                      posts.map((post: Post) => (
                        <PostCard
                          key={post.id}
                          post={post}
                          showComments={false}
                        />
                      ))
                    ) : (
                      <Typography>
                        {t("community.no_posts_available")}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "25px",
                  padding: "1rem",
                }}
              >
                <PopularGroups
                  groups={
                    currentUser ? userJoinedGroups : approvedCommunityGroups
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default AuthorPosts;
