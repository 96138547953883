import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ImageGallery from "react-image-gallery";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useParams } from "react-router-dom";
import baseUrl from "../../config/apiConfig";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ProductComponent from "./ProductComponent";
import {
  createCartItemRequest,
  deleteCartItemRequest,
  fetchCartRequest,
} from "../../store/cart/cartActions";
import {
  createWishlistRequest,
  deleteWishlistRequest,
  getWishlistsRequest,
} from "../../store/wishlist/wishlistActions";
import {
  getProductRequest,
  getProductsByCategoryRequest,
  getRandomProductsRequest,
} from "../../store/products/productActions";
import GenericSizeTable, { ProductData } from "./GenericSizeTable";
import { useTranslation } from "react-i18next";

interface ProductDetailItem {
  id: number;
  title: string;
  description: string;
  price: string;
  is_soldout: boolean;
  colors: { name: string; code: string }[];
  size: string[];
  image: string;
  subcategoryId: number;
}

interface Product {
  id: number;
  title: string;
  description: string;
  image: string;
  colors: { name: string; code: string }[];
  size: string[];
  variants: any;
  is_soldout: boolean;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
  price: number;
  subcategoryId: number;
}

interface Category {
  id: number;
  name: string;
}

interface CartItemProduct {
  id: number;
  title: string;
  description: string;
  image: string;
  is_soldout: boolean;
  color: string | null;
  colors: { name: string; code: string }[];
  size: any;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
  price: number;
  quantity: number;
  subcategoryId: number;
  subcategory: {
    id: number;
    name: string;
  };
}

interface CartItem {
  Product: CartItemProduct;
  cartId: number;
  color: string;
  id: number;
  productId: number;
  quantity: number;
  size: string;
  variant: any;
  designImageUrl: string | null;
}

interface WishlistItem {
  Product: {
    id: number;
    title: string;
    description: string;
    image: string;
    is_soldout: boolean;
    colors: { name: string; code: string }[];
    size: any;
    images: {
      fullPath: string;
      id: number;
      productId: number;
      image: string;
    }[];
    price: number;
    quantity: number;
    subcategoryId: number;
    printful_prodcut_id: number;
  };
  userId: number;
  id: number;
  productId: number;
  createdAt: string;
  updatedAt: string;
}
const clothingSizesOrder = [
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "2XL",
  "3XL",
  "4XL",
  "5XL",
];

const ProductDetail = () => {
  const { t } = useTranslation() as { t: (key: string) => string };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cartItems: reduxCartItems, cartItemsCount: cartItemsCount } =
    useSelector((state: any) => state?.cartReducer);

  const { id } = useParams<{ id: any }>();
  const [uniqueSizes, setUniqueSizes] = useState<any>([]);
  const [uniqueColors, setUniqueColors] = useState<any>([]);
  const [selectedVariant, setSelectedVariant] = useState<any>(null);
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [selectedSize, setSelectedSize] = useState<string>("");
  const [openSizeChart, setOpenSizeChart] = useState<boolean>(false);
  const [images, setImages] = useState<
    { original: string; thumbnail: string }[]
  >([]);
  const [inCart, setInCart] = useState<boolean>(false);
  const [inCartExist, setInCartExist] = useState<boolean>(false);
  const [isFavorited, setIsFavorited] = useState(false);
  const [change, setChange] = useState<boolean>(false);
  const [productLoading, productSetLoading] = useState(true);
  const [relatedProductsLoading, relatedProductsSetLoading] = useState(true);
  const [wishlistLoading, wishlistSetLoading] = useState(true);
  const [cartRequestLoading, cartRequestSetLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false); // for image full screen
  const [selectedColorName, setSelectedColorName] = useState<string>("");
  const [productsData, setProductsData] = useState<ProductData>({
    size_tables: [],
  });

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { wishlists } = useSelector((state: any) => state?.wishlistReducer);
  const { products, product } = useSelector(
    (state: any) => state?.productReducer
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  useEffect(() => {
    dispatch(
      getProductRequest({
        productId: id,
        token: currentUser?.token,
        setLoading: productSetLoading,
        navigate,
      })
    );
  }, [id]);

  useEffect(() => {}, [loading]);

  useEffect(() => {
    if (product) {
      const data = product.variants;

      // Extract unique colors
      const uniqueColors = Array.from(
        new Map(
          data.map((item: any) => [
            item.color,
            { color: item.color, color_code: item.color_code },
          ])
        ).values()
      );
      setUniqueColors(uniqueColors);

      // Extract unique sizes
      const uniqueSizes = Array.from(
        new Set(data.map((item: any) => item.size))
      );

      const _uniqueSizes = uniqueSizes.sort((a: any, b: any) => {
        const indexA = clothingSizesOrder.indexOf(a);
        const indexB = clothingSizesOrder.indexOf(b);

        // If one of the sizes is not in the predefined list, push it to the end
        return (indexA === -1 ? 999 : indexA) - (indexB === -1 ? 999 : indexB);
      });

      console.log("uniqueSizes after sorting:", _uniqueSizes);
      setUniqueSizes(_uniqueSizes);

      const imageGallery = product.images.map((image: any) => ({
        original: image.fullPath,
        thumbnail: image.fullPath,
      }));
      setImages(imageGallery);

      if (product?.subcategoryId) {
        dispatch(
          getProductsByCategoryRequest({
            subcategoryId: product?.subcategoryId,
            token: currentUser?.token,
            setLoading: relatedProductsSetLoading,
            navigate,
          })
        );
      } else {
        dispatch(
          getRandomProductsRequest({
            token: currentUser?.token,
            setLoading: relatedProductsSetLoading,
            navigate,
          })
        );
      }
    }
  }, [product, isFavorited]);

  useEffect(() => {
    if (product && uniqueColors.length > 0) {
      // Set the first color as the selected color
      const firstColor = uniqueColors[0].color_code;
      setSelectedColor(firstColor);

      // Find all sizes available for the first color
      const availableSizes = product.variants
        .filter((variant: any) => variant.color_code === firstColor)
        .map((variant: any) => variant.size);

      if (availableSizes.length > 0) {
        // Sort the sizes using the predefined clothingSizesOrder array
        const sortedSizes = availableSizes.sort((a: any, b: any) => {
          const indexA = clothingSizesOrder.indexOf(a);
          const indexB = clothingSizesOrder.indexOf(b);

          // If a size is not in the predefined list, push it to the end
          return (
            (indexA === -1 ? 999 : indexA) - (indexB === -1 ? 999 : indexB)
          );
        });

        // Set the smallest size as the selected size
        setSelectedSize(sortedSizes[0]);
      }
    }
  }, [product, uniqueColors]);

  useEffect(() => {
    if (selectedColor && selectedSize) {
      setChange(true);
      const variant = product.variants.find(
        (variant: any) =>
          variant.color_code === selectedColor && variant.size === selectedSize
      );
      setSelectedVariant(variant);
    }
  }, [selectedColor, selectedSize, change]);

  useEffect(() => {
    const fetchWishlist = () => {
      if (currentUser) {
        dispatch(
          getWishlistsRequest({
            userId: currentUser.user?.id,
            token: currentUser?.token,
            setLoading: wishlistSetLoading,
            navigate,
          })
        );
      }
    };
    fetchWishlist();
  }, [currentUser.user?.id]);

  useEffect(() => {
    if (wishlists) {
      const wishlistProductIds = wishlists?.map(
        (item: WishlistItem) => item.productId
      );
      if (wishlistProductIds.includes(product?.id)) {
        setIsFavorited(true);
      } else {
        setIsFavorited(false);
      }
    }
  }, [wishlists]);

  useEffect(() => {
    // Compute combined loading state
    console.log("productLoading", productLoading);
    console.log("relatedProductsLoading", relatedProductsLoading);
    console.log("wishlistLoading", wishlistLoading);
    setLoading(
      productLoading ||
        relatedProductsLoading ||
        wishlistLoading ||
        cartRequestLoading
    );
  }, [
    productLoading,
    relatedProductsLoading,
    wishlistLoading,
    cartRequestLoading,
  ]);

  useEffect(() => {
    const fetchProducts = async () => {
      if (product) {
        try {
          const response = await axios.get(
            `${baseUrl}/api/shop/products/printful/productSizeCart/${product?.printful_prodcut_id}`
          );
          console.log(
            "setProductsData",
            response.data,
            product?.printful_prodcut_id
          );
          setProductsData(
            response.data.result
              ? response.data.result
              : {
                  size_tables: [],
                }
          );
        } catch (err) {
          console.error("error while getting data from api");
        } finally {
        }
      }
    };

    fetchProducts();
  }, [product]);

  useEffect(() => {
    if (currentUser) {
      if (cartItemsCount > 0) {
        console.log("fetching cart, setting loading to true");
        cartRequestSetLoading(true);
        dispatch(
          fetchCartRequest({
            userId: currentUser?.user.id,
            token: currentUser?.token,
            navigate,
            setLoading: cartRequestSetLoading,
          })
        );
      }
    }
  }, [cartItemsCount]);

  useEffect(() => {
    if (product && reduxCartItems) {
      const isProductInCart = reduxCartItems.some(
        (cartItem: any) => cartItem.productId === product.id
      );

      setInCartExist(isProductInCart);
    }
  }, [product, reduxCartItems]);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (!product) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "center",
            color: "#000",
          }}
        >
          {t("shop.product_Not_Found")}
        </Typography>{" "}
      </Grid>
    );
  }

  const handleOpenSizeChart = () => {
    setOpenSizeChart(true);
  };

  const handleCloseSizeChart = () => {
    setOpenSizeChart(false);
  };

  const handleAddToCart = () => {
    if (!selectedColor || !selectedSize) {
      toast.error(t("shop.validation_color_size"));
      return;
    }
    const userId = currentUser.user?.id;
    const quantity = 1;

    const itemData = {
      userId,
      productId: product.id,
      quantity,
      color: selectedColorName,
      color_code: selectedColor,
      size: selectedSize,
      variant: selectedVariant,
    };

    dispatch(
      createCartItemRequest({ itemData, token: currentUser?.token, navigate })
    );

    setInCart(true);
  };

  const handleRemoveFromCart = () => {
    dispatch(
      deleteCartItemRequest({
        userId: currentUser.user?.id,
        productId: product.id,
        token: currentUser?.token,
        navigate,
      })
    );

    setInCart(false);
  };

  const handleCartToggle = () => {
    if (inCart || inCartExist) {
      handleRemoveFromCart();
    } else {
      handleAddToCart();
    }
  };

  const handleFavoriteClick = () => {
    const userId = currentUser.user?.id;
    if (isFavorited) {
      // Remove product from wishlist
      dispatch(
        deleteWishlistRequest({
          userId,
          productId: product.id,
          token: currentUser?.token,
          navigate,
        })
      );
    } else {
      // Add product to wishlist
      dispatch(
        createWishlistRequest({
          userId,
          productId: product.id,
          token: currentUser?.token,
          navigate,
        })
      );
    }
    setIsFavorited(!isFavorited);
  };

  const handleScreenChange = (fullscreen: any) => {
    setIsFullscreen(fullscreen); // Track fullscreen state for image
  };

  const addToCartForBuyItNow = () => {
    if (!selectedColor || !selectedSize) {
      toast.error(t("shop.validation_color_size"));
      return false;
    }
    const userId = currentUser.user?.id;
    const quantity = 1;

    const itemData = {
      userId,
      productId: product.id,
      quantity,
      color: selectedColorName,
      color_code: selectedColor,
      size: selectedSize,
      variant: selectedVariant,
    };

    dispatch(
      createCartItemRequest({ itemData, token: currentUser?.token, navigate })
    );

    return true;
  };

  const handleBuyItNow = () => {
    const success = addToCartForBuyItNow();
    if (success) {
      navigate("/cart", {
        state: {
          productId: product.id,
        },
      });
    }
  };

  return (
    <>
      <Box padding={4} sx={{ marginBottom: "40px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sx={{}}>
            <Paper
              elevation={3}
              sx={{
                ".image-gallery-content .image-gallery-slide .image-gallery-image":
                  {
                    maxHeight: isFullscreen ? "calc(100vh - 150px)" : "440px", // Adjust max-height for regular and full screen
                  },

                ".image-gallery-thumbnail img": {
                  maxHeight: "100px",
                },
              }}
            >
              <ImageGallery
                items={images}
                disableSwipe={true}
                showThumbnails={true}
                showPlayButton={false}
                autoPlay={true}
                showNav={false}
                showBullets={true}
                onScreenChange={handleScreenChange} // change fullscreen state
              />
            </Paper>

            <Grid item xs={12} marginTop={4}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "18px" },
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {t("shop.product_description")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "18px" },
                    }}
                  >
                    {product.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "18px" },
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {t("shop.mission_Behind_this_Product")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: { xs: "12px", sm: "18px" },
                    }}
                  >
                    {t("shop.mission_detail")}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontFamily: "Outfit",
                fontSize: "40px",
                fontWeight: 600,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "90%",
              }}
            >
              {product.title}
            </Typography>

            <Typography
              variant="h5"
              color="primary"
              sx={{
                fontFamily: "Syne",
                fontSize: "40px",
                fontWeight: 700,
              }}
            >
              ${" "}
              {(
                Number(product.price) +
                Number(
                  selectedVariant?.price ?? product.variants[0]?.price ?? 0
                )
              ).toFixed(2)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Outfit",
                fontSize: "18px",
              }}
            >
              {t("shop.shipping_calculated_at_checkout")}
            </Typography>
            <Box marginTop={2}>
              <Typography variant="h6"> {t("shop.color")}</Typography>
              <RadioGroup
                value={selectedColor}
                onChange={(event) => {
                  const selectedValue = event.target.value;
                  const color = uniqueColors.find(
                    (c: any) => c.color_code === selectedValue
                  );
                  setSelectedColor(selectedValue);
                  setSelectedColorName(color?.color || "");
                  setSelectedSize("");
                }}
                row
              >
                {uniqueColors.map((color: any) => {
                  return (
                    <FormControlLabel
                      key={color.color_code}
                      value={color.color_code}
                      control={
                        <Radio
                          sx={{
                            display: "none",
                          }}
                        />
                      }
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 40,
                              height: 40,
                              backgroundColor: color.color_code,
                              border: "1px solid",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                              "& svg": {
                                width: "50%",
                                height: "50%",
                              },
                            }}
                          >
                            {selectedColor === color.color_code && (
                              <Box
                                sx={{
                                  width: 8,
                                  height: 8,
                                  backgroundColor:
                                    color.color_code === "#ffffff"
                                      ? "#FF5A00"
                                      : "white",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </Box>

            <Box marginTop={2}>
              <Typography variant="h6" sx={{ marginBottom: 1 }}>
                {t("shop.size")}
              </Typography>
              <RadioGroup
                value={selectedSize}
                onChange={(event) => {
                  setSelectedSize(event.target.value);
                }}
                row
              >
                {uniqueSizes.map((size: any) => {
                  // Check if the size is available for the selected color
                  const isDisabled = !!(
                    selectedColor &&
                    !product.variants.some(
                      (item: any) =>
                        item.color_code === selectedColor && item.size === size
                    )
                  );

                  return (
                    <FormControlLabel
                      key={size}
                      value={size}
                      control={
                        <Radio
                          sx={{
                            display: "none",
                          }}
                          disabled={isDisabled} // Disable the radio button if not available
                        />
                      }
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            position: "relative",
                            border: `2px solid ${
                              selectedSize === size ? "transparent" : "gray"
                            }`,
                            backgroundColor: isDisabled
                              ? "lightgray"
                              : selectedSize === size
                              ? "primary.main"
                              : "transparent",
                            color: isDisabled
                              ? "gray"
                              : selectedSize === size
                              ? "white"
                              : "gray",
                            "&:hover": {
                              backgroundColor: isDisabled
                                ? "lightgray"
                                : selectedSize === size
                                ? "primary.main"
                                : "lightgray",
                            },
                          }}
                        >
                          {isDisabled && (
                            <Box
                              sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "50%",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "white",
                                  fontWeight: "bold",
                                  fontSize: "1rem",
                                }}
                              >
                                ✖
                              </Typography>
                            </Box>
                          )}
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            {size}
                          </Typography>
                        </Box>
                      }
                    />
                  );
                })}
              </RadioGroup>
            </Box>
            <Box marginTop={2}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: "18px",
                  fontWeight: 700,
                  cursor: "pointer",
                  color: "primary.main",
                }}
                onClick={handleOpenSizeChart}
              >
                {t("shop.sizeChart")}
              </Typography>
            </Box>
            <Box marginTop={2}>
              {product.is_soldout ? (
                // Display the 'Sold Out' box if the item is sold
                <Box
                  sx={{
                    height: "80px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "lightgray",
                    borderRadius: "20px",
                    fontFamily: "Outfit",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  {t("shop.soldOut")}
                </Box>
              ) : (
                // Display buttons if the item is not sold
                <>
                  <Box display="flex" justifyContent="space-between">
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid",
                        borderRadius: "20px",
                        fontFamily: "Outfit",
                        fontSize: "20px",
                      }}
                      onClick={handleCartToggle}
                    >
                      {inCart || inCartExist
                        ? t("shop.remove_from_Cart")
                        : t("shop.add_to_Cart")}
                    </Button>
                    <Box
                      sx={{
                        marginLeft: "20px",
                        border: "1px solid",
                        borderRadius: "20px",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={handleFavoriteClick}
                    >
                      {isFavorited ? (
                        <FavoriteBorderIcon
                          style={{ color: "red", fontSize: 34 }}
                        />
                      ) : (
                        <FavoriteBorderIcon
                          style={{ color: "black", fontSize: 34 }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box marginTop={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      size="large"
                      sx={{
                        borderRadius: "20px",
                        fontFamily: "Outfit",
                        fontSize: "20px",
                        fontWeight: 600,
                        padding: "15px",
                      }}
                      onClick={handleBuyItNow}
                    >
                      {t("shop.buyNow")}
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" flexShrink={0}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  marginBottom: { xs: "10px", sm: "15px", md: "20px" },
                  padding: { xs: "8px", sm: "12px", md: "16px" },
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Syne",
                    fontSize: { xs: "16px", sm: "24px", md: "32px" },
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {t("shop.related_products")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item width={"100%"}>
            <Grid container spacing={3}>
              {products.length > 0 ? (
                products
                  .filter(
                    (relatedProduct: any) => relatedProduct.id !== product.id
                  )
                  .map((product: Product) => (
                    <Grid item xs={12} sm={6} md={3} key={product.id}>
                      <ProductComponent
                        product={product}
                        userId={currentUser?.user?.id ?? 0}
                      />
                    </Grid>
                  ))
              ) : (
                <Typography
                  variant="h6"
                  sx={{ width: "100%", textAlign: "center" }}
                >
                  {t("no_records_found")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* Size Chart Popup */}
      <Modal
        open={openSizeChart}
        onClose={handleCloseSizeChart}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            width: 400,
            maxWidth: 600,
            margin: 4,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 3,
            position: "relative",
          }}
        >
          <IconButton
            onClick={handleCloseSizeChart}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <GenericSizeTable
            data={productsData}
            tableType="product_measure"
            tableType2="measure_yourself"
          />
        </Box>
      </Modal>
    </>
  );
};

export default ProductDetail;
