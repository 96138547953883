import { call, put, takeLatest, select } from "redux-saga/effects";
import axios from "axios";
import {fetchNotificationsSuccess, fetchNotificationsFailure } from "./actions";
import { FETCH_NOTIFICATIONS_REQUEST, MARK_NOTIFICATION_AS_READ } from "./actionTypes";
import { Notification } from "./types";
import baseUrl from "../../config/apiConfig";

// Fetch notifications for a specific user
const fetchNotifications = async (userId: number) => {
  const response = await axios.get<Notification[]>(`${baseUrl}/api/users/notification/userId/${userId}`);
  return response.data;
};

const markNotificationAsReadAPI = async (id: number) => {
  await axios.put(`${baseUrl}/api/users/notification/${id}/read`);
};

// Selector function to get the userId from the Redux store
const getUserId = (state: any): number | null => state?.Auth?.currentUser.user?.id ?? null;

function* handleFetchNotifications() {
  try {
    // Use select effect to get the userId from the Redux store
    const userId: number | null = yield select(getUserId);
    
    if (userId) {
      const notifications: Notification[] = yield call(fetchNotifications, userId);
      yield put(fetchNotificationsSuccess(notifications));
    } else {
      yield put(fetchNotificationsFailure('User ID not found.'));
    }
  } catch (error: any) {
    yield put(fetchNotificationsFailure(error.message));
  }
}

function* handleMarkNotificationAsRead(action: { type: string; payload: number }) {
  try {
    yield call(markNotificationAsReadAPI, action.payload);

    // Optionally refetch notifications to sync state
    const userId: number | null = yield select(getUserId);
    if (userId) {
      const notifications: Notification[] = yield call(fetchNotifications, userId);
      yield put(fetchNotificationsSuccess(notifications));
    } else {
      yield put(fetchNotificationsFailure('User ID not found.'));
    }
  } catch (error: any) {
    console.error("Failed to mark notification as read:", error.message);
  }
}

export function* notificationSaga() {
  yield takeLatest(FETCH_NOTIFICATIONS_REQUEST, handleFetchNotifications);
  yield takeLatest(MARK_NOTIFICATION_AS_READ, handleMarkNotificationAsRead);
}
