import { produce } from "immer";
import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

const initialState: dataTypes.InitialStateSecurityQuestions = {
  securityQuestions: [],
};

const securityQuestionsReducer = produce((state, action) => {
  switch (action.type) {
    
    case actionTypes.FETCH_SECURITY_QUESTIONS_SUCCESS:
      state.securityQuestions = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default securityQuestionsReducer;
