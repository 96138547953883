import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from "@mui/material";
import baseUrl from "../../../config/apiConfig";
import { useDispatch, useSelector } from "react-redux";
import { handleApiError } from "../../common/Api-error-handler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../store/auth/actions";

// Define Product, OrderItem, and Order interfaces
interface Product {
  id: number;
  title: string;
  image: string;
  price: number;
  size: string;
  color: string | null;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
}

interface OrderItem {
  id: number;
  orderId: number;
  productId: number;
  quantity: number;
  price: number;
  size: string;
  color: string;
  product: Product;
}

interface Order {
  id: number;
  status: string;
  shippingAddress: string;
  createdAt: string;
  orderItems: OrderItem[];
  totalAmount: number;
}

function OrdersPage() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch orders data from the API
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/orders/orders`, {
          headers: {
            Authorization: `Bearer ${currentUser?.token}`,
          },
        });
        setOrders(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const { message, navigateTo } = handleApiError(error);
        toast.error(message);
        if (navigateTo) {
          if (navigateTo == "login") {
            dispatch(logout());
          }
          navigate(`/${navigateTo}`);
        }
      }
    };

    fetchOrders();
  }, []);

  // Display loading and error states
  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Grid>
    );
  }

  // Check if there are no orders
  if (orders.length === 0) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography
          sx={{
            fontFamily: "Outfit",
            fontSize: "24px",
            fontWeight: 600,
            textAlign: "center",
            color: "#000",
          }}
        >
          You don't have any orders yet.
        </Typography>
      </Grid>
    );
  }

  return (
    <Box width="100%">
      <Grid
        container
        width="100%"
        direction="column"
        padding={{ xs: 2, sm: 3, md: 3 }}
        rowSpacing={2}
        flexShrink={0}
        sx={{ marginBottom: "40px" }}
      >
        <Grid item xs={12}>
          <Grid container direction="column" flexShrink={0}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                marginBottom: { xs: "10px", sm: "15px", md: "20px" },
                padding: { xs: "8px", sm: "12px", md: "16px" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Syne",
                  fontSize: { xs: "16px", sm: "24px", md: "32px" },
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                Orders
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction={"row"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "16px", sm: "24px", md: "32px" },
                  fontWeight: 700,
                  color: "#4E4949",
                }}
              >
                Order Details
              </Typography>
              <TableContainer>
                <Table>
                  <TableBody>
                    {orders.map((order) => {
                      const shippingInfo = JSON.parse(order.shippingAddress);
                      // Check if there are no items in the order
                      if (order.orderItems.length === 0) {
                        return (
                          <TableRow key={order.id}>
                            <TableCell colSpan={3} align="center">
                              <Typography
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: "20px",
                                  color: "#000",
                                }}
                              >
                                Items are deleted.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        );
                      }

                      return (
                        <TableRow key={order.id} >
                        {order.orderItems.filter((item) => item.product).length > 0 && (
                          <TableCell >
                          <Box sx={{display:{xs:"block", sm:"flex"}, gap:{xs:0, sm:12}}} >
                            <Box
                              display="flex"
                              flexDirection={{
                                xs: "row", 
                              }}
                              alignItems={{
                                xs: "center",
                                sm: "center",    
                              }}
                              width={{
                                xs:0,
                                sm:"40%"
                              }}
                              gap={2} 
                            >
                              {/* Image Section */}
                              <Box>
                                {(() => {
                                  const validItemWithImage = order.orderItems.find(
                                    (item) => item.product && item.product.images[0]?.fullPath
                                  );
                                  return validItemWithImage ? (
                                    <img
                                      src={validItemWithImage.product.images[0].fullPath}
                                      alt={validItemWithImage.product.title || "Product"}
                                      style={{
                                        width: "150px",
                                        height: "120px",
                                        borderRadius: "10px",
                                      }}
                                    />
                                  ) : (
                                    <Typography
                                      sx={{
                                        fontFamily: "Outfit",
                                        fontSize: {
                                          xs: "14px",
                                          sm: "20px",
                                        },
                                        fontWeight: 600,
                                      }}
                                    >
                                      {order.orderItems[0]?.product?.title || "No Product Available"}
                                    </Typography>
                                  );
                                })()}
                              </Box>
                      
                              {/* Order Details Section */}
                              <Box>
                                {order.orderItems
                                  .filter((item) => item.product)
                                  .map((item) => (
                                    <Box key={item.id} mb={2}>
                                      <Typography
                                        sx={{
                                          fontFamily: "Outfit",
                                          fontSize: {
                                            xs: "14px",
                                            sm: "18px",
                                          },
                                          fontWeight: 600,
                                        }}
                                      >
                                        {item.product.title.length > 20
                                          ? `${item.product.title.slice(0, 20)}...`
                                          : item.product.title}{" "}
                                        x {item.quantity}
                                      </Typography>
                      
                                      {/* Color and Size */}
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          padding: "4px 8px",
                                          backgroundColor: "#ffece2",
                                          borderRadius: 2,
                                        }}
                                      >
                                        <Typography variant="body1" sx={{ marginRight: 1 }}>
                                          {item.color}
                                        </Typography>
                                        <Box
                                          sx={{
                                            border: "1px solid",
                                            height: "15px",
                                            marginRight: 1,
                                          }}
                                        />
                                        <Typography variant="body1">{item.size}</Typography>
                                      </Box>
                                    </Box>
                                  ))}
                              </Box>
                            </Box>
                      
                            {/* Shipping and Order Information */}
                          
                            <Box mt={2} width={{xs:"100%", sm:"20%"}}>
                              <Typography
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                  },
                                }}
                              >
                                {shippingInfo.shippingFullName}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                  },
                                }}
                              >
                                {shippingInfo.shippingDeliveryAddress}, {shippingInfo.shippingCity},{" "}
                                {shippingInfo.shippingState}, {shippingInfo.shippingPostalCode}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                  },
                                }}
                              >
                                {new Date(order.createdAt).toLocaleDateString("en-US", {
                                  year: "numeric",
                                  month: "long",
                                  day: "numeric",
                                })}
                              </Typography>
                            </Box>
                      
                            {/* Status and Total */}
                            <Box
                              display="flex"
                              flexDirection="column"
                              mt={2}
                              gap={1}
                              alignItems="flex-start"
                            >
                              <Typography
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                  },
                                  fontWeight: 400,
                                  backgroundColor: "#FF5A00",
                                  textAlign: "center",
                                  borderRadius: "10px",
                                  color: "white",
                                  padding: "5px",
                                }}
                              >
                                {order.status}
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "Outfit",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "18px",
                                  },
                                  fontWeight: 700,
                                }}
                              >
                                $ {order.totalAmount}
                              </Typography>
                            </Box>
                          </Box>  
                          </TableCell>
                        )}
                      </TableRow>                      

                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default OrdersPage;
