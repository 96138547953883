import { Grid, Typography, Box } from "@mui/material";
import { Container } from "@mui/system";
import Resilience from "../../assets/images/6Pillers/Resilient.png";
import sixpillerBannerImage from "../../assets/images/6Pillers/sixpillerBanner.png";
import Love from "../../assets/images/6Pillers/Love-2.webp";
import LyfersCollage from "../../assets/images/6Pillers/LYFERS-Collage.png";
import orchidBg from "../../assets/images/6Pillers/orchid-bg-34.webp";
import orchidShade from "../../assets/images/6Pillers/dot-shape-2.webp";
import forgiveness from "../../assets/images/6Pillers/Forgiving.png";
import YouthFul from "../../assets/images/6Pillers/Youthful-1-1.webp";
import HealthDrink from "../../assets/images/6Pillers/health-drink-leaves-bgimage-1-1-1.webp";
import Empower from "../../assets/images/6Pillers/Empower-1.webp";
import Strength from "../../assets/images/6Pillers/Strength-1.webp";
import { useTranslation } from "react-i18next";

export default function SixPiller() {
  const { t } = useTranslation() as { t: (key: string) => string };

  return (
    <>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          sx={{
            backgroundImage: `url(${sixpillerBannerImage})`,
            backgroundPosition: "center",
            // backgroundRepeat: "no-repeat",
            backgroundSize: "cover", // Updated to 'cover'
            position: "relative",
            height: "595px",
            width: "100%",
            top: -90,
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              zIndex: 1,
            },
          }}
        >
          <Box zIndex={2}>
            <Typography
              variant="h1"
              sx={{
                fontSize: {
                  xs: "60px",
                  sm: "80px",
                  md: "117px",
                },
                textAlign: "center",
                fontWeight: 600,
                fontFamily: "outfit",
                color: "white",
              }}
            >
              {t("sixPiller.heading")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        width={"100%"}
        sx={{
          flexGrow: 1,
          backgroundImage: `url(${LyfersCollage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <Grid container width={"100%"} direction={"column"}>
          <Grid item xs={12} container direction={"column"}>
            {/* section 1*/}
            <Grid
              item
              container
              paddingTop={4}
              paddingBottom={10}
              sx={{
                backgroundImage: `url(${orchidBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  padding: { xs: 2, md: 4 },
                }}
              >
                {/* Text Section */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    textAlign: "left",
                    alignItems: "start",
                  }}
                >
                  <Box
                    maxWidth={550}
                    padding={3}
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: { xs: "24px", sm: "28px", md: "38px" },
                        marginBottom: { xs: 3, md: 4 },
                      }}
                    >
                      {t("sixPiller.sixPillersText.love.title")}
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #ddd",
                        padding: 2,
                        textAlign: "justify",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          lineHeight: "38px",
                          fontSize: "18px",
                        }}
                      >
                        <strong style={{ fontSize: "22px" }}>
                          {t("sixPiller.sixPillersText.love.title")}
                        </strong>
                        {t("sixPiller.sixPillersText.love.description")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Image Section */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                    paddingLeft: { xs: 0, md: 12 },
                    marginTop: { xs: 4, md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "500px",
                      padding: { xs: 2, md: 0 },
                    }}
                  >
                    {/* Dot Shape */}
                    <Box
                      sx={{
                        position: "absolute",
                        left: { xs: "5%", md: "-3%" },
                        bottom: { xs: "-5%", md: "-4%" },
                        width: { xs: "20%", sm: "30%" },
                        height: { xs: "20%", sm: "30%" },
                        background: `url(${orchidShade})`,
                        backgroundPosition: "left bottom",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 1,
                      }}
                    />

                    {/* Image Box */}
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: { xs: "300px", sm: "400px", md: "430px" },
                        maxWidth: "430px",
                        aspectRatio: "1",
                        borderTop: "20px solid #FF5A00",
                        borderRight: "20px solid #FF5A00",
                        borderRadius: "10px",
                        position: "relative",
                        zIndex: 2,
                        margin: "auto",
                      }}
                    >
                      <img
                        src={Love}
                        alt="Descriptive Alt Text"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </Grid>

            {/* section 2  */}
            <Grid
              container
              paddingTop={4}
              paddingBottom={10}
              sx={{
                backgroundColor: "#FAFAFA",
              }}
            >
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  padding: { xs: 2, md: 4 },
                }}
              >
                {/* Image Section */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  paddingRight={{ xs: 0, md: 12 }}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    marginBottom: { xs: 4, md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "500px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* Dot Shape */}
                    <Box
                      sx={{
                        position: "absolute",
                        right: { xs: "10%", md: "-3%" },
                        bottom: { xs: "10%", md: "-4%" },
                        width: { xs: "20%", md: "30%" },
                        height: { xs: "20%", md: "30%" },
                        background: `url(${orchidShade})`,
                        backgroundPosition: "right bottom",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 1,
                      }}
                    />

                    {/* Image Box */}
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: { xs: "300px", sm: "400px", md: "430px" },
                        maxWidth: "430px",
                        aspectRatio: "1",
                        borderTop: "20px solid #FF5A00",
                        borderLeft: "20px solid #FF5A00",
                        borderRadius: "10px",
                        position: "relative",
                        zIndex: 2,
                        margin: "auto", // Center the box
                      }}
                    >
                      <img
                        src={YouthFul}
                        alt="Descriptive Alt Text"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* Text Section */}
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                    textAlign: { xs: "center", md: "left" },
                    alignItems: "start",
                  }}
                >
                  <Box maxWidth={550} padding={3}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: { xs: "24px", sm: "28px", md: "38px" },
                        marginBottom: { xs: 2, md: 3 },
                        fontFamily: "Outfit",
                      }}
                    >
                      {t("sixPiller.sixPillersText.yothfullness.title")}
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #ddd",
                        padding: 2,
                        textAlign: "justify",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          lineHeight: "38px",
                          fontSize: "18px",
                        }}
                      >
                        <strong style={{ fontSize: "22px" }}>
                          {t("sixPiller.sixPillersText.yothfullness.title")}
                        </strong>
                        {t("sixPiller.sixPillersText.yothfullness.description")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </Grid>
            {/* section 3*/}
            <Grid
              item
              container
              paddingTop={4}
              paddingBottom={10}
              sx={{
                backgroundImage: `url(${orchidBg})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  padding: { xs: 2, md: 4 },
                }}
              >
                {/* Text Section */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    textAlign: "left",
                    alignItems: "start",
                  }}
                >
                  <Box
                    maxWidth={550}
                    padding={3}
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: { xs: "24px", sm: "28px", md: "38px" },
                        marginBottom: { xs: 3, md: 4 },
                      }}
                    >
                      {t("sixPiller.sixPillersText.forgiveness.title")}
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #ddd",
                        padding: 2,
                        textAlign: "justify",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          lineHeight: "38px",
                          fontSize: "18px",
                        }}
                      >
                        <strong style={{ fontSize: "22px" }}>
                          {t("sixPiller.sixPillersText.forgiveness.title")}
                        </strong>
                        {t("sixPiller.sixPillersText.forgiveness.description")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Image Section */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                    paddingLeft: { xs: 0, md: 12 },
                    marginTop: { xs: 4, md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "500px",
                      padding: { xs: 2, md: 0 },
                    }}
                  >
                    {/* Dot Shape */}
                    <Box
                      sx={{
                        position: "absolute",
                        left: { xs: "5%", md: "-3%" },
                        bottom: { xs: "-5%", md: "-4%" },
                        width: { xs: "20%", sm: "30%" },
                        height: { xs: "20%", sm: "30%" },
                        background: `url(${orchidShade})`,
                        backgroundPosition: "left bottom",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 1,
                      }}
                    />

                    {/* Image Box */}
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: { xs: "300px", sm: "400px", md: "430px" },
                        maxWidth: "430px",
                        aspectRatio: "1",
                        borderTop: "20px solid #FF5A00",
                        borderRight: "20px solid #FF5A00",
                        borderRadius: "10px",
                        position: "relative",
                        zIndex: 2,
                        margin: "auto",
                      }}
                    >
                      <img
                        src={forgiveness}
                        alt="Descriptive Alt Text"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </Grid>

            {/* background image shown*/}

            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                height={500}
                width={"100%"}
                sx={{
                  backgroundColor: "black",
                  opacity: "0.5",
                }}
              ></Box>
            </Grid>

            {/* section 4 */}

            <Grid
              item
              container
              paddingTop={6}
              paddingBottom={10}
              sx={{
                background: `url(${HealthDrink})`,
                position: "relative",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.94)",
                },
              }}
            >
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    textAlign: { xs: "center", md: "left" },
                    alignItems: "center",
                    zIndex: 1,
                    marginBottom: { xs: 4, md: 0 },
                  }}
                >
                  <Box maxWidth={550} padding={3}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: { xs: "28px", sm: "34px", md: "38px" },
                        marginBottom: 3,
                      }}
                    >
                      {t("sixPiller.sixPillersText.empowering.title")}
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #ddd",
                        padding: 2,
                        textAlign: "justify",
                        fontFamily: "Outfit",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          lineHeight: "38px",
                          fontSize: "18px",
                        }}
                      >
                        <strong style={{ fontSize: "22px" }}>
                          {t("sixPiller.sixPillersText.empowering.title")}
                        </strong>
                        {t("sixPiller.sixPillersText.empowering.description")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  paddingLeft={{ xs: 0, md: 12 }}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "494px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Dot Shape */}
                    <Box
                      sx={{
                        position: "absolute",
                        left: { xs: "5%", md: "-3%" },
                        bottom: { xs: "5%", md: "-4%" },
                        width: { xs: "20%", md: "30%" },
                        height: { xs: "20%", md: "30%" },
                        background: `url(${orchidShade})`,
                        backgroundPosition: "left bottom",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 1,
                      }}
                    />

                    {/* Image Box */}
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: { xs: "300px", sm: "400px", md: "430px" },
                        height: "auto",
                        borderTop: "20px solid #FF5A00",
                        borderRight: "20px solid #FF5A00",
                        borderRadius: "10px",
                        position: "relative",
                        zIndex: 2,
                        margin: "auto",
                      }}
                    >
                      <img
                        src={Empower}
                        alt="Descriptive Alt Text"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </Grid>

            {/* section 5 */}

            <Grid
              container
              paddingTop={4}
              paddingBottom={10}
              sx={{
                backgroundColor: "#FAFAFA",
              }}
            >
              <Container
                maxWidth="lg"
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  padding: { xs: 2, md: 4 },
                }}
              >
                {/* Image Section */}
                <Grid
                  item
                  xs={12}
                  md={6}
                  paddingRight={{ xs: 0, md: 12 }}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" },
                    marginBottom: { xs: 4, md: 0 },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: "500px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {/* Dot Shape */}
                    <Box
                      sx={{
                        position: "absolute",
                        right: { xs: "10%", md: "-3%" },
                        bottom: { xs: "10%", md: "-4%" },
                        width: { xs: "20%", md: "30%" },
                        height: { xs: "20%", md: "30%" },
                        background: `url(${orchidShade})`,
                        backgroundPosition: "right bottom",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 1,
                      }}
                    />

                    {/* Image Box */}
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: { xs: "300px", sm: "400px", md: "430px" },
                        maxWidth: "430px",
                        aspectRatio: "1",
                        borderTop: "20px solid #FF5A00",
                        borderLeft: "20px solid #FF5A00",
                        borderRadius: "10px",
                        position: "relative",
                        zIndex: 2,
                        margin: "auto", // Center the box
                      }}
                    >
                      <img
                        src={Resilience}
                        alt="Descriptive Alt Text"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* Text Section */}
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" },
                    textAlign: { xs: "center", md: "left" },
                    alignItems: "start",
                  }}
                >
                  <Box maxWidth={550} padding={3}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: { xs: "24px", sm: "28px", md: "38px" },
                        marginBottom: { xs: 2, md: 3 },
                        fontFamily: "Outfit",
                      }}
                    >
                      {t("sixPiller.sixPillersText.resilience.title")}
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #ddd",
                        padding: 2,
                        textAlign: "justify",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          lineHeight: "38px",
                          fontSize: "18px",
                        }}
                      >
                        <strong style={{ fontSize: "22px" }}>
                          {t("sixPiller.sixPillersText.resilience.title")}
                        </strong>
                        {t("sixPiller.sixPillersText.resilience.description")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </Grid>

            {/* section 6 */}

            <Grid
              item
              container
              paddingTop={4}
              paddingBottom={9}
              sx={{
                backgroundColor: "white",
              }}
            >
              <Container
                maxWidth={"lg"}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", lg: "flex-end" },
                    alignItems: "center",
                    textAlign: { xs: "center", md: "left" },
                    padding: { xs: 2, md: 3 },
                  }}
                >
                  <Box maxWidth={{ xs: "100%", md: 550 }}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: { xs: "28px", sm: "34px", md: "38px" },
                        marginBottom: 3,
                        fontFamily: "Outfit",
                      }}
                    >
                      {t("sixPiller.sixPillersText.strength.title")}
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #ddd",
                        padding: 2,
                        textAlign: "justify",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "Outfit, sans-serif",
                          lineHeight: "38px",
                          fontSize: "18px",
                        }}
                      >
                        <strong style={{ fontSize: "22px" }}>
                          {t("sixPiller.sixPillersText.strength.title")}
                        </strong>
                        {t("sixPiller.sixPillersText.strength.description")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "flex-end",
                    },
                    alignItems: "center",
                    padding: { xs: 2, md: 3 },
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      maxWidth: { xs: "300px", lg: "430px" },
                    }}
                  >
                    {/* Dot Shape Background */}
                    <Box
                      sx={{
                        position: "absolute",
                        left: { xs: "10%", md: "-3%" },
                        bottom: { xs: "10%", md: "-4%" },
                        width: { xs: "20%", md: "30%" },
                        height: { xs: "20%", md: "30%" },
                        background: `url(${orchidShade})`,
                        backgroundPosition: "left bottom",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 1,
                      }}
                    />
                    {/* Image Container */}
                    <Box
                      sx={{
                        overflow: "hidden",
                        width: { xs: "300px", sm: "400px", md: "430px" },
                        height: "auto",
                        aspectRatio: "1",
                        borderTop: "20px solid #FF5A00",
                        borderRight: "20px solid #FF5A00",
                        borderRadius: "10px",
                        position: "relative",
                        zIndex: 2,
                        margin: "0 auto",
                      }}
                    >
                      <img
                        src={Strength}
                        alt="Descriptive Alt Text"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
