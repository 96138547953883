import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

//for fetching SubCategory - Categories
export const fetchSubCategoryCategoriesRequest = (payload: dataTypes.FetchSubCategoryCategoriesPayload) => ({
    type: actionTypes.FETCH_SUBCATEGORY_CATEGORIES_REQUEST,
    payload: payload,
});

export const fetchSubCategoryCategoriesSuccess = (data: dataTypes.SubCategoryCategories[]) => ({
    type: actionTypes.FETCH_SUBCATEGORY_CATEGORIES_SUCCESS,
    payload: data,
});