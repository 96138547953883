import { produce } from "immer";
import * as types from "./cartActionTypes";
import * as dataTypes from "./cartDataTypes";

const initialState: dataTypes.InitialStateCart = {
  cartItems: [],
  cartItemsCount: 0,
};

const cartReducer = produce((state, action) => {
  switch (action.type) {
    
    case types.FETCH_CART_SUCCESS:
      state.cartItems = action.payload;
      break;

    case types.DELETE_CART_ITEM_SUCCESS:
      state.cartItems = state.cartItems.filter(
        (item: any) => item.productId !== action.payload.productId
      );
      break;

    case types.CREATE_CART_ITEM_SUCCESS:
      state.cartItems = [...state.cartItems, action.payload.newCartItem];
      break;

    case types.GET_CART_ITEMS_COUNT_SUCCESS:
      state.cartItemsCount = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default cartReducer;
