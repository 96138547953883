import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import PostCard from "./PostCard";
import PopularGroups from "./PopularGroups";
import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  fetchApprovedCommunityGroupsRequest,
  fetchUserJoinedGroupsRequest,
  getCommunityGroupDetailRequest,
} from "../../store/communityGroup/actions";
import {
  getCommunityGroupAndItsPostsRequest,
  getCommunityGroupMembersRequest,
  joinCommunityGroupRequest,
  leaveCommunityGroupRequest,
} from "../../store/communityGroupAndPost/actions";
import { checkAuth } from "../../utils/authCheck";
import { useTranslation } from "react-i18next";

interface Group {
  id: number;
  name: string;
  userId: number;
  description: string;
  lastActivity: string;
  postsCount: number;
}

interface Post {
  id: number;
  title: string;
  userId: string;
  description: string;
  createdAt: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  Group: {
    id: number;
    name: string;
    userId: number;
  };
  PostImages: any[];
  Comments: any[];
}
interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  user_type: string;
}
interface GroupDetail {
  id: number;
  name: string;
  createdAt: string;
  description: string;
  lastActivity: string;
  userId: number;
  postsCount: number;
  membersCount: number;
  membersDetails: any;
  image: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
}

interface Members {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userId: string;
  membershipId: string;
}

const GroupPosts: React.FC = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [posts, setPosts] = useState<Post[]>([]);
  const [group, setGroup] = useState<GroupDetail | null>(null);
  const [isMember, setIsMember] = useState(false);
  const { t } = useTranslation() as { t: (key: string) => string };
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [
    communityGroupAndItsPostsLoading,
    setCommunityGroupAndItsPostsLoading,
  ] = useState(true);
  const [communityGroupDetailLoading, setCommunityGroupDetailLoading] =
    useState(false);
  const [groupMembersLoading, setGroupMembersLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openMembersModal, setOpenMembersModal] = useState(false);
  const [openReasonDialog, setOpenReasonDialog] = useState(false);
  const [removeReason, setRemoveReason] = useState("");
  const [selectedMemberId, setSelectedMemberId] = useState("");

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);

  const { approvedCommunityGroups, userJoinedGroups } = useSelector(
    (state: any) => state?.communityGroupReducer
  );

  const { communityGroupAndItsPosts, communityGroupMembers: members } =
    useSelector((state: any) => state?.communityGroupAndPostReducer);

  // Fetch posts data from API
  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        getCommunityGroupAndItsPostsRequest({
          setLoading: setCommunityGroupAndItsPostsLoading,
          navigate,
          token: currentUser?.token,
          groupId,
        })
      );

      if (currentUser) {
        dispatch(
          getCommunityGroupDetailRequest({
            userId: currentUser?.user.id,
            groupId: groupId,
            setIsMember,
            token: currentUser?.token,
            navigate,
            setLoading: setCommunityGroupDetailLoading,
          })
        );

        dispatch(
          fetchUserJoinedGroupsRequest({
            userId: currentUser?.user.id,
            setLoading: setGroupsLoading,
            navigate,
            token: currentUser?.token,
          })
        );
      } else {
        dispatch(
          fetchApprovedCommunityGroupsRequest({
            setLoading: setGroupsLoading,
            navigate,
          })
        );
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (communityGroupAndItsPosts.posts && communityGroupAndItsPosts.group) {
      setPosts(communityGroupAndItsPosts.posts);
      setGroup(communityGroupAndItsPosts.group);
    }
  }, [communityGroupAndItsPosts]);

  const joinGroup = () => {
    checkAuth(currentUser, () => {
      dispatch(
        joinCommunityGroupRequest({
          userId: currentUser?.user.id,
          groupId: groupId,
          setIsMember,
          token: currentUser?.token,
          navigate,
        })
      );
    });
  };

  // Function to handle leaving the group
  const leaveGroup = async () => {
    checkAuth(currentUser, () => {
      if (currentUser?.user?.id === group?.userId) {
        toast.error(t("community.you_can_not_leave_the_group"));
      } else {
        dispatch(
          leaveCommunityGroupRequest({
            userId: currentUser?.user.id,
            groupId: groupId,
            setIsMember,
            token: currentUser?.token,
            navigate,
          })
        );
      }
    });
  };

  const calculateAge = (dateOfBirth: string): number => {
    const dob = new Date(dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();

    // If the current month is before the birth month or it's the birth month and the current date is before the birth date
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < dob.getDate())
    ) {
      age--;
    }
    return age;
  };

  const openRemoveReasonDialog = (memberId: string) => {
    setSelectedMemberId(memberId); // Store the selected member ID
    setOpenReasonDialog(true);
    setOpenMembersModal(false);
  };

  const handleOpenMembersModal = async () => {
    dispatch(
      getCommunityGroupMembersRequest({
        groupId: groupId,
        setOpenMembersModal,
        token: currentUser?.token,
        navigate,
        setLoading: setGroupMembersLoading,
      })
    );
  };

  const MembersModal = () => {
    return (
      <Dialog
        open={openMembersModal}
        onClose={() => setOpenMembersModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle textAlign={"center"}>
          {t("community.group_members")}
        </DialogTitle>
        <Box sx={{ padding: 2 }}>
          <Box
            sx={{
              maxHeight: "300px", // Adjust the max height as needed
              overflowY: "auto", // Enables vertical scrolling
              marginBottom: 2, // Optional spacing for the button section
            }}
          >
            {members.map((member: Members) => (
              <Box
                key={member.id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  borderBottom: "1px solid #e0e0e0",
                  padding: "8px 0",
                }}
              >
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: {
                      xs: "12px", // Adjusted for readability on small screens
                      sm: "14px",
                      md: "16px",
                    },
                    color: "#FBB03A",
                    wordBreak: "break-word", // Prevent text overflow
                  }}
                >
                  {member.firstName || member.lastName
                    ? `${member.firstName ?? ""} ${member.lastName ?? ""}`
                    : member?.email || ""}
                </Typography>

                {communityGroupAndItsPosts?.group.userId === member?.userId ? (
                  <Typography
                    color="text.secondary"
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: {
                        xs: "10px",
                        sm: "14px",
                        md: "16px",
                      },
                      color: "red",
                      fontWeight: "bold",
                      cursor: "default",
                    }}
                  >
                    {t("community.admin")}
                  </Typography>
                ) : (
                  currentUser?.user?.id ===
                    communityGroupAndItsPosts?.group.userId && (
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: {
                          xs: "10px",
                          sm: "14px",
                          md: "16px",
                        },
                        color: "red",
                        cursor: "pointer", // Pointer cursor for clickable text
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        currentUser?.user?.id &&
                        openRemoveReasonDialog(member?.userId)
                      } // Only allow removal if currentUser exists
                    >
                      {t("removeButton")}
                    </Typography>
                  )
                )}
              </Box>
            ))}
          </Box>
        </Box>

        <DialogActions>
          <Button onClick={() => setOpenMembersModal(false)} color="primary">
            {t("close_button")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const removeFromGroup = async () => {
    checkAuth(currentUser, () => {
      if (!removeReason.trim()) {
        toast.error("Please provide a reason to remove the member.");
        return;
      } else {
        dispatch(
          leaveCommunityGroupRequest({
            userId: selectedMemberId,
            groupId: groupId,
            reason: removeReason,
            token: currentUser?.token,
            navigate,
          })
        );
        setOpenReasonDialog(false);
      }
    });
  };

  console.log("group", group);

  return (
    <>
      <MembersModal />
      {/* <ReasonDialog /> */}

      <Container
        maxWidth={"xl"}
        sx={{
          marginBottom: "40px",
        }}
      >
        <Grid
          container
          width="100%"
          direction="column"
          padding={{ xs: 2, sm: 3, md: 4 }}
          rowSpacing={2}
          flexShrink={0}
        >
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></Grid>
        </Grid>

        {communityGroupAndItsPostsLoading ||
        communityGroupDetailLoading ||
        groupsLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={8} sm={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={12} sm={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    sx={{
                      border: "1px solid",
                      borderRadius: "25px",
                      padding: "1rem",
                    }}
                  >
                    {/* Group Image or Avatar */}
                    {group?.image ? (
                      <img
                        src={group?.image}
                        alt={group?.name}
                        style={{
                          width: "10vw",
                          height: "10vh",
                          borderRadius: "10%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      <Avatar sx={{ width: 50, height: 50 }}>
                        {group ? group.name.charAt(0).toUpperCase() : " "}
                      </Avatar>
                    )}

                    {/* Group Info */}
                    <Box flex={1} ml={4}>
                      {/* Group Name and Members Count */}
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: {
                              xs: "16px",
                              sm: "20px",
                              md: "20px",
                              lg: "28px",
                            },
                            fontWeight: 700,
                            // textOverflow: "ellipsis",
                          }}
                        >
                          {group ? group.name : " "}
                        </Typography>

                        <Button
                          size="small"
                          startIcon={
                            isMember ? (
                              <RemoveIcon
                                sx={{
                                  border: "1.5px solid",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <AddIcon
                                sx={{
                                  border: "1.5px solid",
                                  borderRadius: "50%",
                                }}
                              />
                            )
                          }
                          variant="contained"
                          sx={{
                            backgroundColor: "primary",
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            padding: 1.5,
                          }}
                          onClick={isMember ? leaveGroup : joinGroup}
                          // onClick={handleJoinAndLeaveGroup}
                        >
                          {isMember ? "Leave" : "Join"}
                        </Button>
                      </Box>

                      {/* Created By and Created Date */}
                      <Box
                        display="flex"
                        justifyContent={"space-between"}
                        alignContent={"center"}
                        alignItems={"center"}
                        mt={1}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                            color: "#000",
                            mr: 3,
                          }}
                        >
                          {t("community.created_by")}:{" "}
                          <span style={{ color: "orange" }}>
                            {(group?.user && group?.user.firstName) ||
                            group?.user.lastName
                              ? ` ${group?.user.firstName ?? ""} ${
                                  group?.user.lastName ?? ""
                                }`
                              : group?.user?.email || ""}
                          </span>
                        </Typography>

                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                          }}
                        >
                          {t("community.created")}:{" "}
                          <span style={{ color: "orange" }}>
                            {dayjs(group?.createdAt).fromNow() ||
                              "18 Months Ago"}
                          </span>
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "16px",
                              lg: "16px",
                            },
                            fontWeight: 600,
                            textAlign: "right",
                          }}
                          onClick={handleOpenMembersModal}
                        >
                          {t("community.members")}:{" "}
                          <span style={{ color: "orange" }}>
                            {group?.membersCount}
                          </span>
                        </Typography>
                      </Box>

                      {/* Description */}
                      <Typography
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: {
                            xs: "14px",
                            sm: "16px",
                            md: "16px",
                            lg: "16px",
                          },
                          color: "#000",
                          mt: 1,
                        }}
                      >
                        {group?.description ||
                          "No description added by the group founder"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  md={12}
                  xs={12}
                  sm={12}
                  item
                  sx={{
                    gap: "16px",
                    flexDirection: "column",
                    justifyContent: "center",
                    display: {
                      sm: "flex",
                      xs: "flex",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <Box
                    sx={{
                      border: "1px solid",
                      borderRadius: "25px",
                      padding: "1rem",
                    }}
                  >
                    {/* <Typography
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: {
                          xs: "16px",
                          sm: "20px",
                          md: "20px",
                          lg: "28px",
                        },
                      }}
                    >
                      Posts
                    </Typography> */}
                    {posts.length > 0 ? (
                      posts.map((post) => (
                        <PostCard
                          key={post.id}
                          post={post}
                          showComments={false}
                        />
                      ))
                    ) : (
                      <Typography>
                        {t("community.no_posts_available")}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "25px",
                  padding: "1rem",
                }}
              >
                <PopularGroups
                  groups={
                    currentUser ? userJoinedGroups : approvedCommunityGroups
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>

      <Dialog
        open={openReasonDialog}
        onClose={() => setOpenReasonDialog(false)}
        sx={{ zIndex: 1301 }}
      >
        <DialogTitle>{t("community.reason_for_removing_member")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Reason"
            type="text"
            fullWidth
            variant="outlined"
            value={removeReason}
            onChange={(e) => setRemoveReason(e.target.value)}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReasonDialog(false)} color="primary">
            {t("cancel_button")}
          </Button>
          <Button onClick={removeFromGroup} color="primary">
            {t("submit_button")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupPosts;
