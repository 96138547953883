import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./eventCategoryActionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";

import * as dataTypes from "./eventCategoryDataTypes";
import * as actions from "./eventCategoryActions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";

function* fetchEventCategories(
  action: dataTypes.FetchEventCategoryAction
): Generator<any, void, any> {
  const { navigate, setLoading } = action.payload;
  try {
    setLoading(true);

    const response = yield call(
      axios.get,
      `${baseUrl}/api/shop/eventCategories`
    );

    yield put(actions.fetchEventCategorySuccess(response.data));
    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error);
    toast.error(React.createElement("pre", null, message));

    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchEventCategorySagas() {
  yield takeLatest(types.FETCH_EVENT_CATEGORY_REQUEST, fetchEventCategories);
}