import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./eventActionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";

import * as dataTypes from "./eventDataTypes";
import * as actions from "./eventActions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";


function* fetchEvents(action: dataTypes.FetchEventsAction): Generator<any, void, any> {
  const { navigate, setLoading } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/events`);
    yield put(actions.fetchEventsSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

function* getUpcomingEvents(action: dataTypes.GetUpcomingEventsAction): Generator<any, void, any> {
  const { setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/events/upcoming`);
    yield put(actions.getUpcomingEventsSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

function* getEvent(action: dataTypes.GetEventAction): Generator<any, void, any> {
  const { eventId, token, setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/events/${eventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(actions.getEventSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* deleteEvent(action: dataTypes.DeleteEventAction): Generator<any, void, any> {
  const { selectedEventId, token, navigate } = action.payload;
  try {
    const response = yield call(axios.delete, `${baseUrl}/api/shop/events/${selectedEventId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const message = response.data.message || "event deleted successfully.";
    yield put(actions.deleteEventSuccess(selectedEventId))
    toast.success(message);
  } catch (error: any) {

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* createEvent(action: dataTypes.CreateEventAction): Generator<any, void, any> {
  const { formData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.post, `${baseUrl}/api/shop/events`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    const newEvent = response.data.event;
    const message = response.data.message || "event created successfully.";

    yield put(actions.createEventSuccess(newEvent))
    toast.success(message);
    navigate("/admin/events");
  } catch (error: any) {

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

function* updateEvent(action: dataTypes.UpdateEventAction): Generator<any, void, any> {
  const { eventId, formData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.put, `${baseUrl}/api/shop/events/${eventId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    const updatedEvent = response.data.event;
    const message = response.data.message || "event updated successfully.";

    yield put(actions.updateEventSuccess(eventId, updatedEvent))
    toast.success(message);
    navigate("/admin/events");

  } catch (error: any) {
    
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchEventSagas() {
  yield takeLatest(types.FETCH_EVENTS_REQUEST, fetchEvents);
  yield takeLatest(types.DELETE_EVENT_REQUEST, deleteEvent);
  yield takeLatest(types.CREATE_EVENT_REQUEST, createEvent);
  yield takeLatest(types.UPDATE_EVENT_REQUEST, updateEvent);
  yield takeLatest(types.GET_EVENT_REQUEST, getEvent);
  yield takeLatest(types.GET_UPCOMING_EVENTS_REQUEST, getUpcomingEvents);
}
