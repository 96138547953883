import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changePath } from "../store/auth/actions";


export function PrivateRoute({ children }: any) {
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state?.Auth);

  // console.log("auth",auth)
  // console.log("chekk")
  // console.log("show children",children)

  if (!auth.currentUser) {
    if (
      window.location.pathname === '/login' ||
      window.location.pathname === '/forgotPassword' ||
      /^\/register(\/\d+)?$/.test(window.location.pathname)
    ) {
      // console.log("check1")
      return children;
    }else{
      // console.log("check2")
      // toast.error("please login...")
      return <Navigate to={"/login"} replace />;
      // return null;  // Don't render anything else (stay on the page)
    }
  } else {
    if (
      window.location.pathname === '/login' ||
      window.location.pathname === '/forgotPassword' ||
      /^\/register(\/\d+)?$/.test(window.location.pathname)
    ) {
      return <Navigate to={"/"} replace />;
    }
    if ((auth.selectedPath !== window.location.pathname)) {
      dispatch(changePath(window.location.pathname))
    }
    return children;
  }
}
