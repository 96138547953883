export const FETCH_COLOR_REQUEST = "fetch_color_request";
export const FETCH_COLOR_SUCCESS = "fetch_color_success";

export const DELETE_COLOR_REQUEST = "delete_color_request";
export const DELETE_COLOR_SUCCESS = "delete_color_success";

export const CREATE_COLOR_REQUEST = "create_color_request";
export const CREATE_COLOR_SUCCESS = "create_color_success";

export const UPDATE_COLOR_REQUEST = "update_color_request";
export const UPDATE_COLOR_SUCCESS = "update_color_success";