// notification/reducer.ts

import { NotificationState, NotificationActions, FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILURE, ADD_NOTIFICATION,MARK_NOTIFICATION_AS_READ } from "./types";

const initialState: NotificationState = {
  notifications: [],
  unreadCount: 0,
  loading: false,
  error: null,
};

const notificationReducer = (
  state = initialState,
  action: NotificationActions
): NotificationState => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };

    case FETCH_NOTIFICATIONS_SUCCESS: {
      const unreadCount = action.payload.filter((n) => !n.readStatus).length;
      return {
        ...state,
        loading: false,
        notifications: action.payload,
        unreadCount:unreadCount
      };
    }

    case FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case ADD_NOTIFICATION: {
      const isUnread = !action.payload.readStatus;
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
        unreadCount: isUnread ? state.unreadCount + 1 : state.unreadCount, // Increment if unread
      };
    }
    case MARK_NOTIFICATION_AS_READ: {
      const updatedNotifications = state.notifications.map((notification) =>
        notification.id === action.payload // Comparison works as both are numbers
          ? { ...notification, readStatus: true }
          : notification
      );
    
      const unreadCount = updatedNotifications.filter((n) => !n.readStatus).length;
    
      return { ...state, notifications: updatedNotifications, unreadCount };
    }

    default:
      return state;
  }
};

export default notificationReducer;
