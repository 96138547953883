// notification/actions.ts

import { FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILURE, ADD_NOTIFICATION,MARK_NOTIFICATION_AS_READ } from "./actionTypes";
import { Notification } from "./types";

export const fetchNotificationsRequest = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
});

export const fetchNotificationsSuccess = (notifications: Notification[]) => ( {
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const fetchNotificationsFailure = (error: string) => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload: error,
});

export const addNotification = (notification: Notification) => ({
  type: ADD_NOTIFICATION,
  payload: notification,
});

export const markNotificationAsRead = (id: number) => ({
  type: MARK_NOTIFICATION_AS_READ,
  payload: id,
});