export const FETCH_SUB_CATEGORY_REQUEST = "fetch_sub_category_request";
export const FETCH_SUB_CATEGORY_SUCCESS = "fetch_sub_category_success";

export const DELETE_SUB_CATEGORY_REQUEST = "delete_sub_category_request";
export const DELETE_SUB_CATEGORY_SUCCESS = "delete_sub_category_success";

export const CREATE_SUB_CATEGORY_REQUEST = "create_sub_category_request";
export const CREATE_SUB_CATEGORY_SUCCESS = "create_sub_category_success";

export const UPDATE_SUB_CATEGORY_REQUEST = "update_sub_category_request";
export const UPDATE_SUB_CATEGORY_SUCCESS = "update_sub_category_success";