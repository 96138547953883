import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Grid,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import baseUrl from "../../../config/apiConfig";
import { Box } from "@mui/system";
import ImageIcon from "@mui/icons-material/Image";
import { toast } from "react-toastify";
import { handleApiError } from "../../common/Api-error-handler";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/auth/actions";
import {
  createEventRequest,
  getEventRequest,
  updateEventRequest,
} from "../../../store/events/eventActions";
import { fetchEventCategoryRequest } from "../../../store/eventCategories/eventCategoryActions";

interface Category {
  id: number;
  name: string;
}

interface Event {
  id?: number;
  title: string;
  date: string;
  description: string;
  location: string;
  ticketPrice: string;
  categoryId: number;
  image?: string; // Image URL if editing an existing event
}

const EventsForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { eventId } = useParams<{ eventId: string }>(); // Get eventId from route
  const [title, setTitle] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [ticketPrice, setTicketPrice] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [categoryId, setCategoryId] = useState<number>(0);
  //const [categories, setCategories] = useState<Category[]>([]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null); // Set image preview from existing event

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);

  const [titleError, setTitleError] = useState("");
  const [ticketPriceError, setTicketPriceError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const { event } = useSelector(
    (state: any) => state?.eventReducer
  );
  const { eventCategory } = useSelector(
    (state: any) => state?.eventCategoryReducer

  );

  const [eventCategoryLoading, eventCategorySetLoading] = useState<boolean>(true);
  const [eventLoading, eventSetLoading] = useState<boolean>(true);
  const today = new Date().toISOString().split('T')[0];
  
  useEffect(() => {
    dispatch(
      fetchEventCategoryRequest({
        setLoading: eventCategorySetLoading,
        navigate,
      })
    );
  }, []);


  useEffect(() => {
    if (eventId) {
      dispatch(
        getEventRequest({
          eventId,
          token: currentUser?.token,
          setLoading: eventSetLoading,
          navigate,
        })
      );
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      if (event) {
        setTitle(event.title);
        setDate(new Date(event.date).toISOString().split("T")[0]);
        setDescription(event.description);
        setTicketPrice(event.ticketPrice);
        setLocation(event.location);
        setCategoryId(event.categoryId);
        setImagePreview(event.image || null);
      }
    }
  }, [event]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);
      // Create a preview URL for the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    const formData = new FormData();
    formData.append("title", title);
    formData.append("location", location);
    formData.append("date", date);
    formData.append("description", description);
    formData.append("ticketPrice", ticketPrice);
    formData.append("categoryId", categoryId.toString());
    if (imageFile) {
      formData.append("image", imageFile);
    }

    if (eventId) {
      // Update existing event
      dispatch(
        updateEventRequest({
          eventId,
          formData,
          token: currentUser?.token,
          navigate,
        })
      );
      // navigate("/admin/events");
    } else {
      // Create new event
      dispatch(
        createEventRequest({ formData, token: currentUser?.token, navigate })
      );
      // navigate("/admin/events");
    }
    setLoading(false);
    // Clear form
    setTitle("");
    setDate("");
    setDescription("");
    setTicketPrice("");
    setCategoryId(0);
    setImageFile(null);
    setImagePreview(null);
  };

  if ((eventId && eventLoading) || eventCategoryLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      <Grid item xs={12}>
        <Grid container direction="column" flexShrink={0}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            sx={{
              padding: { xs: "8px", sm: "12px", md: "16px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
                color: "#000000",
                marginLeft: { xs: 0, md: 20 },
              }}
            >
              {eventId ? "Edit Event" : "Add Event"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ borderRadius: 4, border: "1px solid", padding: 2 }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: "8px",
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <ImageIcon sx={{ fontSize: 50, color: "#ccc" }} />
                </IconButton>
                <Typography
                  sx={{
                    position: "absolute",
                    marginTop: "130px",
                    color: "#999",
                  }}
                >
                  Upload Pictures
                </Typography>
              </Box>
            </Grid>

            {/* Image Preview */}
            {imagePreview && (
              <Grid item xs={12} display="flex" justifyContent="center">
                <img
                  src={imagePreview}
                  alt="Selected"
                  style={{
                    maxWidth: "100%",
                    maxHeight: "200px",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                />
              </Grid>
            )}
<Grid item xs={12}>
  <TextField
    label="Title"
    variant="outlined"
    fullWidth
    value={title}
    onFocus={() => setTitleError("")}
    onChange={(e) => {
      const newValue = e.target.value;

      // Restrict the length to a maximum of 50 characters
      if (newValue.length <= 50) {
        setTitle(newValue);
        setTitleError(""); // Clear any existing error
      } else {
        setTitleError("Title cannot exceed 50 characters");
      }
    }}
    onBlur={() => {
      if (title.trim() === "") {
        setTitleError("Title cannot be empty");
      }
    }}
    required
    error={!!titleError}
    helperText={titleError}
  />
</Grid>
            <Grid item xs={6}>
              <TextField
                label="Date"
                type="date"
                variant="outlined"
                fullWidth
                inputProps={{
                  min: today, 
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </Grid>
            {/* Category Dropdown */}
            <Grid item xs={6}>
              <FormControl fullWidth required>
                <InputLabel>Category</InputLabel>
                <Select
                  value={categoryId}
                  onChange={(e) => setCategoryId(Number(e.target.value))}
                  label="Category"
                >
                  <MenuItem value={0} disabled>
                    Select Category
                  </MenuItem>
                  {eventCategory.map((category: Category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
  <TextField
    label="Ticket Price"
    variant="outlined"
    type="number"
    fullWidth
    value={ticketPrice}
    onFocus={() => setTicketPriceError("")} // Clear error on focus
    onChange={(e) => {
      const newValue = e.target.value;
      setTicketPrice(newValue);
      if (newValue.trim() !== "") {
        setTicketPriceError(""); // Clear the error if input is not empty
      }
    }}
    onBlur={() => {
      if (ticketPrice.trim() === "") {
        setTicketPriceError("Ticket Price cannot be empty"); // Set error if empty
      }
    }}
    required
    error={!!ticketPriceError} // Show error if there's any
    helperText={ticketPriceError} // Display error message
  />
</Grid>
<Grid item xs={6}>
  <TextField
    label="Event Location"
    variant="outlined"
    type="text"
    fullWidth
    value={location}
    onFocus={() => setLocationError("")} // Clear error on focus
    onChange={(e) => {
      const newValue = e.target.value;
      setLocation(newValue);
      if (newValue.trim() !== "") {
        setLocationError(""); // Clear the error if input is not empty
      }
    }}
    onBlur={() => {
      if (location.trim() === "") {
        setLocationError("Event Location cannot be empty"); // Set error if empty
      }
    }}
    required
    error={!!locationError} // Show error if there's any
    helperText={locationError} // Display error message
  />
</Grid>


<Grid item xs={12}>
  <TextField
    label="Description"
    variant="outlined"
    multiline
    rows={4}
    fullWidth
    value={description}
    onFocus={() => setDescriptionError("")} // Clear error on focus
    onChange={(e) => {
      const newValue = e.target.value;
      setDescription(newValue);
      if (newValue.trim() !== "") {
        setDescriptionError(""); // Clear the error if input is not empty
      }
    }}
    onBlur={() => {
      if (description.trim() === "") {
        setDescriptionError("Description cannot be empty"); // Set error if empty
      }
    }}
    required
    error={!!descriptionError} // Show error if there's any
    helperText={descriptionError} // Display error message
  />
</Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? "Saving..." : eventId ? "Update Event" : "Add Event"}
              </Button>
            </Grid>

            {error && (
              <Grid item xs={12}>
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {successMessage && (
              <Grid item xs={12}>
                <Typography color="success.main">{successMessage}</Typography>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default EventsForm;
