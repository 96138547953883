import React, { FC, useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { useNavigate, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as CommunityLogo } from "../../assets/logos/CommunityLogo.svg";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddIcon from "@mui/icons-material/Add";
import styles from "./header.module.css";
import { Container, Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/actions";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/LanguageSwitcher";

import {
  fetchNotificationsRequest,
  markNotificationAsRead,
} from "../../store/notification/actions";
import { CombinedState } from "redux";
import MailIcon from "@mui/icons-material/Mail";
import { checkAuth } from "../../utils/authCheck";
import MobileLanguageSwitcher from "../../components/MobileLanguageSwitcher";

interface Props {
  window?: () => Window;
}
interface Notification {
  id: number;
  message: string;
  readStatus: boolean;
  createdAt: any;
  User: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
}

interface NotificationState {
  notifications: Notification[];
  unreadCount: number;
}

const CommunityHeader: FC<Props> = (props: Props) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const auth = useSelector((state: any) => state?.Auth);
  const { t } = useTranslation() as { t: (key: string) => string };
  const [searchKeyword, setSearchKeyword] = useState("");
  const { window } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const drawerWidth = 240;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [anchorElHome, setAnchorElHome] = useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] =
    useState<null | HTMLElement>(null);

  const [newAnchorEl, setNewAnchorEl] = useState<null | HTMLElement>(null);
  const [createAnchorEl, setCreateAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const pages = [
    { name: t("community.header.pages.news_feed"), path: "/community/posts" },
    { name: t("community.header.pages.groups"), path: "/community/groups" },
  ];

  const notifications = useSelector(
    (state: CombinedState<{ Auth: any; Notification: NotificationState }>) =>
      state.Notification.notifications
  );
  const unReadNotificationsCount = useSelector(
    (state: CombinedState<{ Auth: any; Notification: NotificationState }>) =>
      state.Notification.unreadCount
  );

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);

  useEffect(() => {
    dispatch(fetchNotificationsRequest());
  }, [dispatch]);

  const open = Boolean(anchorEl);
  // const openHome = Boolean(anchorElHome);

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const newOpen = Boolean(newAnchorEl);
  const isCreateMenuOpen = Boolean(createAnchorEl);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // const handleSearchClick = () => {
  //   setIsSearchOpen(true);
  // };

  // const handleSearchClose = () => {
  //   setIsSearchOpen(false);
  //   setSearchKeyword("");
  //   navigate(`/community/posts`);
  // };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchKeyword.trim()) {
      navigate(`/community/posts/search/${searchKeyword}`);
      // setIsSearchOpen(false);
    } else {
      navigate("/community/posts");
    }
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick1 = (event: React.MouseEvent<HTMLElement>) => {
    setNewAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setNewAnchorEl(null);
  };

  const handleDashboard = () => {
    if (auth.currentUser.user.user_type == "admin") {
      navigate("/admin/orders");
    } else {
      navigate("/dashboard");
    }
    handleClose();
  };
  const handleProfile = () => {
    if (auth.currentUser.user.user_type == "admin") {
      navigate("/admin/profile");
    } else {
      navigate("/profile");
    }

    handleClose();
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate("/");
  };

  const handleCreateClick = (event: React.MouseEvent<HTMLElement>) => {
    setCreateAnchorEl(event.currentTarget);
  };

  const handleCreateClose = () => {
    setCreateAnchorEl(null);
  };

  const handleCreatePost = () => {
    checkAuth(currentUser, () => {
      navigate("/community/post/create");
      handleCreateClose();
    });
  };

  const handleCreateGroup = () => {
    checkAuth(currentUser, () => {
      navigate("/community/group/create");
      handleCreateClose();
    });
  };

  const handleMarkAsRead = async (notificationId: number) => {
    dispatch(markNotificationAsRead(notificationId));
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      height={"100%"}
      paddingY={2}
      sx={{ textAlign: "center", backgroundColor: "white" }}
    >
      <Stack spacing={3}>
        {pages.map((page) => (
          <React.Fragment key={page.name}>
            <Button
              onClick={() => navigate(page.path)}
              id={styles.headerButtonText}
              sx={{
                backgroundColor:
                  location.pathname === page.path ? "lightgray" : "transparent",
              }}
            >
              {page.name}
            </Button>
            <Divider variant="middle" />
          </React.Fragment>
        ))}
      </Stack>
    </Box>
  );

  return (
    <Grid container paddingBottom={14}>
      <AppBar position="fixed">
        <Toolbar disableGutters>
          <Grid container>
            {/* Banner */}
            <Grid item xs={12}>
              <Box id={styles.headerBanner}>
                <Typography
                  id={styles.headerBannerTextNormal}
                  component="span"
                  sx={{
                    fontSize: { xs: "10px", sm: "12px", md: "16px" },
                  }}
                >
                  {t("header.topbarText")}{" "}
                  <Typography
                    id={styles.headerBannerTextClickable}
                    component="span"
                    sx={{
                      fontSize: { xs: "10px", sm: "12px", md: "16px" },
                    }}
                    onClick={() => navigate("/become-a-lyfer")}
                  >
                    {" "}
                    {t("header.clickHereButton")}{" "}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Box sx={{display:"flex", justifyContent:"flex-end", alignItems:"center" ,width:"100%"}} >
              <Grid item xs={10}>
                <Container
                  maxWidth="lg"
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    flexWrap: { xs: "wrap", sm: "nowrap" },
                  }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    width={{ xs: "100%", sm: "25%", md: "30%" }}
                  >
                    {/* Logo */}
                    <Grid
                      item
                      xs={6}
                      sm={3}
                      md={4}
                      lg={5}
                      marginY={1}
                      marginX={2.5}
                      display="flex"
                    >
                      <Box
                        display="flex"
                        sx={{
                          height: { xs: 60, sm: 60, md: 60 },
                        }}
                      >
                        <Box
                          onClick={() => navigate("/")}
                          height="inherit"
                          component={CommunityLogo}
                        />
                      </Box>
                      <Box>
                        <IconButton onClick={handleClick1} sx={{ ml: 0.5 }}>
                          <ArrowDropDownIcon />
                        </IconButton>
                        <Menu
                          anchorEl={newAnchorEl}
                          open={newOpen}
                          onClose={handleClose1}
                        >
                          <MenuItem onClick={() => navigate("/")}>
                            {t("community.header.go_to_home")}
                          </MenuItem>
                        </Menu>
                      </Box>
                      <IconButton
                        onClick={handleGoBack}
                        sx={{ marginRight: 0 }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Grid>

                    {/* Mobile Menu Icon */}
                    <Grid
                      item
                      xs={2}
                      sm={0}
                      sx={{
                        display: { xs: "flex", sm: "none" },
                        margin: 1,
                        marginLeft:7
                        // justifyContent:'space-between'
                      }}
                      alignSelf="center"
                    >
                      <Box display="flex" alignItems="center">
                        {/* First Drawer Icon */}
                        <IconButton
                          color="inherit"
                          aria-label="open drawer"
                          edge="start"
                          onClick={handleDrawerToggle}
                          sx={{ mr: 2 }}
                        >
                          <MenuIcon sx={{ color: "black" }} />
                        </IconButton>

                        {/* Mobile Language Switcher */}
                        <MobileLanguageSwitcher />
                      </Box>
                    </Grid>
                  </Box>
                  {/* Navigation Links */}
                  <Grid
                    item
                    xs={0}
                    // sm={3.5}
                    md={4}
                    alignItems="center"
                    display="flex"
                    sx={{ display: { xs: "none", sm: "flex" } }}
                  >
                    <Grid container>
                      {pages.map((page) => (
                        <Grid item md={6} key={page.name}>
                          <Button
                            onClick={() => navigate(page.path)}
                            sx={{
                              padding: 0,
                              fontFamily: "Outfit",
                              color:
                                location.pathname === page.path
                                  ? "#FF5A00"
                                  : " #000",
                              fontWeight:
                                location.pathname === page.path ? 700 : 500,
                            }}
                          >
                            {page.name}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {/* Right Icons Section */}
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={10}
                    display="flex"
                    alignItems="center"
                    sx={{
                      flexDirection: { xs: "column", sm: "row", md: "row" },
                      justifyContent: { xs: "space-between", sm: "flex-start" },
                      gap: { xs: 1, sm: 1 },
                      mt: { xs: 1, sm: 1, md: 0 },
                    }}
                  >
                    {/* Search Icon */}
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        width: "100%",
                        marginRight: { xs: 0, sm: 0, md: 4 },
                      }}
                    >
                      <TextField
                        value={searchKeyword}
                        onChange={handleSearchChange}
                        placeholder={t("community.header.search_on_lyfers")}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <IconButton onClick={handleSearchSubmit}>
                        <SearchIcon />
                      </IconButton>
                    </Box>

                    {/* Create Button */}
                    <Box
                      display={"flex"}
                      gap={{ xs:1,sm: 2, md: 2 }}
                      alignItems={"center"}
                      width={"60%"}
                      justifyContent={{ xs: "space-between", sm: "flex-end" }}
                      mb={{ xs: 2, sm: 0 }}
                      marginRight={0}
                    >
                      <Button
                        startIcon={
                          <AddIcon
                            sx={{
                              border: "1.5px solid",
                              borderRadius: "50%",
                            }}
                          />
                        }
                        variant="contained"
                        sx={{
                          backgroundColor: "primary",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          // width: { xs: "auto", sm: "auto", md: "auto" }
                          minWidth: "100px",
                        }}
                        onClick={handleCreateClick}
                      >
                        {t("community.header.pages.create")}
                      </Button>

                      {/* Dropdown Menu */}
                      <Menu
                        anchorEl={createAnchorEl}
                        open={isCreateMenuOpen}
                        onClose={handleCreateClose}
                      >
                        <MenuItem onClick={handleCreatePost}>
                          {t("community.header.pages.create_post")}
                        </MenuItem>
                        <MenuItem onClick={handleCreateGroup}>
                          {t("community.header.pages.create_group")}
                        </MenuItem>
                      </Menu>

                      {/* Notification Icon */}
                      <IconButton
                        onClick={handleNotificationClick}
                        sx={{
                          border: "2px solid #FF5A00",
                          borderRadius: "50%",
                          padding: "5px",
                        }}
                      >
                        <Badge
                          badgeContent={unReadNotificationsCount || 0}
                          color="error"
                          overlap="circular"
                        >
                          <NotificationsIcon sx={{ color: "#FF5A00" }} />
                        </Badge>
                      </IconButton>

                      {/* Notification Dropdown */}
                      <Menu
                        anchorEl={notificationAnchorEl}
                        open={Boolean(notificationAnchorEl)}
                        onClose={handleNotificationClose}
                        PaperProps={{
                          style: {
                            maxHeight: "400px",
                            width: "500px",
                            marginTop: "30px",
                            borderRadius: "10px",
                          },
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "10px",
                          }}
                        >
                          <Grid container alignItems="center">
                            <MailIcon
                              sx={{ marginRight: "10px", color: "#555" }}
                            />
                            <Typography variant="body2">
                              {unReadNotificationsCount}{" "}
                              {t("notifications.new_messages")}
                            </Typography>
                          </Grid>
                        </MenuItem>

                        {notifications?.length ? (
                          <>
                            <MenuItem
                              sx={{
                                justifyContent: "center",
                                fontWeight: "bold",
                                backgroundColor: "#f4f4f4",
                              }}
                            >
                              {notifications.length}{" "}
                              {t("notifications.notifications")}
                            </MenuItem>

                            <Divider />
                            {/* Display first 10 notifications */}
                            {notifications.slice(0, 10).map((notification) => (
                              <>
                                <MenuItem
                                  key={notification.id}
                                  onClick={() =>
                                    handleMarkAsRead(notification.id)
                                  }
                                  sx={{
                                    backgroundColor: notification.readStatus
                                      ? "#f4f4f4"
                                      : "#FFEBEB",
                                    "&:hover": {
                                      backgroundColor: notification.readStatus
                                        ? "#f4f4f4"
                                        : "#FFDDDD",
                                    },
                                    padding: { xs: "8px", sm: "15px" },
                                    borderRadius: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    display={"flex"}
                                    gap={{ xs: 1, md: 2 }}
                                    alignItems={"center"}
                                  >
                                    <Avatar>
                                      {notification.User?.firstName?.charAt(
                                        0
                                      ) ?? ""}
                                    </Avatar>
                                    <Box
                                      display={"flex"}
                                      flexDirection={{
                                        xs: "column",
                                        md: "row",
                                      }}
                                      alignItems={{ xs: "start", md: "center" }}
                                      gap={{ xs: 0, md: 2 }}
                                    >
                                      <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        sx={{
                                          fontSize: {
                                            xs: "12px",
                                            md: "14px",
                                          },
                                        }}
                                      >
                                        {notification.User?.firstName ?? ""}{" "}
                                        {notification.User?.lastName ?? ""}{" "}
                                        {notification.message}
                                      </Typography>
                                      <Box
                                        sx={{
                                          fontSize: { xs: "12px", md: "16px" },
                                          pb: {},
                                        }}
                                      >
                                        {(() => {
                                          const createdAt: any = new Date(
                                            notification.createdAt
                                          );
                                          const now: any = new Date();
                                          const diffMs = now - createdAt;
                                          const diffMinutes = Math.floor(
                                            diffMs / 60000
                                          );
                                          const diffHours = Math.floor(
                                            diffMinutes / 60
                                          );

                                          if (diffMinutes < 1)
                                            return "just now";
                                          if (diffMinutes < 60)
                                            return `${diffMinutes} minute${
                                              diffMinutes > 1 ? "s" : ""
                                            } ago`;
                                          if (diffHours < 24)
                                            return `${diffHours} hour${
                                              diffHours > 1 ? "s" : ""
                                            } ago`;

                                          const diffDays = Math.floor(
                                            diffHours / 24
                                          );
                                          return `${diffDays} day${
                                            diffDays > 1 ? "s" : ""
                                          } ago`;
                                        })()}
                                      </Box>
                                    </Box>
                                  </Box>

                                  <Box></Box>
                                </MenuItem>
                                <Divider />
                              </>
                            ))}

                            {notifications.length > 10 && (
                              <MenuItem
                                sx={{
                                  justifyContent: "center",
                                  fontWeight: "bold",
                                  color: "#FF5A00",
                                }}
                                onClick={() => navigate("/all-notifications")}
                              >
                                {t("notifications.see_all_notifications")}
                              </MenuItem>
                            )}
                          </>
                        ) : (
                          <MenuItem>
                            {" "}
                            {t("notifications.no_new_notifications")}
                          </MenuItem>
                        )}
                      </Menu>

                      <Box display="flex" gap={3}>
                        {auth.currentUser != null ? (
                          <>
                            <Avatar
                              onClick={handleClick}
                              style={{ cursor: "pointer" }}
                            >
                              {auth.currentUser?.user &&
                              auth.currentUser?.user.firstName &&
                              auth.currentUser?.user.lastName
                                ? `${auth.currentUser?.user.firstName
                                    .charAt(0)
                                    .toUpperCase()}`
                                : auth.currentUser?.user?.email
                                    .charAt(0)
                                    .toUpperCase() || ""}
                            </Avatar>
                            <Menu
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                            >
                              <MenuItem onClick={handleDashboard}>
                                {t("header.pages.dashboard")}{" "}
                              </MenuItem>
                              <MenuItem onClick={handleProfile}>
                                {t("header.pages.profile")}{" "}
                              </MenuItem>
                              <MenuItem onClick={handleLogout}>
                                {t("header.pages.logout")}{" "}
                              </MenuItem>
                            </Menu>
                          </>
                        ) : (
                          <Button
                            onClick={() => navigate("/login")}
                            id={styles.headerButtonText}
                            sx={{
                              fontFamily: "Outfit",
                              padding: 0,
                              minWidth: "110px",
                            }}
                          >
                            {t("header.pages.login")}{" "}
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Container>
              </Grid>
            <Grid
              item
              xs={1}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                ml:2
              }}
            >
              <Box
                sx={{
                  ml: "auto",
                  mr:1,
                  display: { xs: "none", sm: "none", md: "flex" },
                }}
              >
                <LanguageSwitcher />
              </Box>
            </Grid>
            </Box>

          </Grid>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

export default CommunityHeader;
