import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import eventsCover from "../../assets/images/speaker.jpeg";
import EventsComponent from "./EventsComponent";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchEventsRequest } from "../../store/events/eventActions";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

interface Category {
  id: number;
  name: string;
}

interface EventData {
  id: number;
  title: string;
  date: string;
  image: string;
  description: string;
  price: string;
  category: Category;
}

export default function EventsPage() {
  const [value, setValue] = useState<string>("all");
  const [filteredEvents, setFilteredEvents] = useState<EventData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchDate, setSearchDate] = useState<string>("");
  const [eventsLoading, eventsSetLoading] = useState<boolean>(true);
  const { t } = useTranslation() as { t: (key: string) => string };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { events } = useSelector((state: any) => state?.eventReducer);

  useEffect(() => {
    dispatch(fetchEventsRequest({ setLoading: eventsSetLoading, navigate }));
  }, []);

  useEffect(() => {
    if (events) {
      filterEvents(value);
    }
  }, [value, events, searchQuery, searchDate]);

  const filterEvents = (filter: string) => {
    const currentDate = new Date();
    let filtered = events;

    // Apply date filter first, if present
    if (searchDate) {
      filtered = events.filter((event: EventData) => {
        const formattedEventDate = format(new Date(event.date), "yyyy-MM-dd");
        return formattedEventDate === searchDate;
      });
      setValue("all"); // Clear the tab filter when the date filter is applied
    } else {
      // Apply tab filter when date filter is not applied
      if (filter === "upcoming") {
        filtered = events.filter(
          (event: EventData) => new Date(event.date) > currentDate
        );
      } else if (filter === "past") {
        filtered = events.filter(
          (event: EventData) => new Date(event.date) < currentDate
        );
      }

      // Apply the search query filter
      if (searchQuery) {
        filtered = filtered.filter((event: EventData) =>
          event.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    }

    setFilteredEvents(filtered);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setSearchQuery(""); // Clear search query when tab filter is changed
    setSearchDate(""); // Clear date filter when tab filter is changed
  };

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setSearchDate(""); // Clear date filter when search query is changed
    filterEvents(value); // Apply the filter with the current tab value when search query changes
  };

  const handleSearchDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchDate(e.target.value);
    setSearchQuery(""); // Clear search query when date filter is changed
    filterEvents("all"); // Apply the filter without considering tab filter, since date filter is applied
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexShrink={0}
          padding={{ xs: 2, sm: 4, md: 6 }}
          sx={{
            backgroundImage: `url(${eventsCover})`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: { xs: "150px", sm: "200px", md: "350px" },
            position: "relative",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              color: "#FFFFFF",
              fontWeight: 500,
              fontSize: { xs: "16px", sm: "24px", md: "32px" },
              fontFamily: '"Readex Pro", Sans-serif',
              textAlign: { xs: "start", md: "center" },
              position: "absolute",
              top: "50%",
              left: { xs: "40%", sm: "50%" },
              transform: "translate(-50%, -50%)",
            }}
          >
            <h2> {t("events.heading")}</h2>
          </Typography>
        </Box>
      </Grid>

      <Container maxWidth="lg">
        <Grid
          container
          width="100%"
          direction="column"
          padding={{ xs: 2, sm: 3, md: 4 }}
          rowSpacing={2}
          flexShrink={0}
        >
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              mb: 3,
              mt: 4,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "28px", md: "40px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
              }}
            >
              {t("events.join_our_events")}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={3}
          padding={{ xs: 0, sm: 0, md: 0 }}
          marginBottom={6}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                borderBottom: 1,
                borderColor: "divider",
                mb: 2,
              }}
            >
              {/* Tabs aligned left */}
              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  aria-label="scrollable auto tabs"
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                >
                  <Tab
                    sx={{
                      typography: "body1",
                      fontFamily: "Outfit",
                      fontSize: { xs: "14px", sm: "16px", md: "18px" },
                      fontWeight: 600,
                    }}
                    label={t("events.filters.all")}
                    value="all"
                  />
                  <Tab
                    sx={{
                      typography: "body1",
                      fontFamily: "Outfit",
                      fontSize: { xs: "14px", sm: "16px", md: "18px" },
                      fontWeight: 600,
                    }}
                    label={t("events.filters.upcoming")}
                    value="upcoming"
                  />
                  <Tab
                    sx={{
                      typography: "body1",
                      fontFamily: "Outfit",
                      fontSize: { xs: "14px", sm: "16px", md: "18px" },
                      fontWeight: 600,
                    }}
                    label={t("events.filters.past")}
                    value="past"
                  />
                </TabList>
              </TabContext>

              {/* Search fields aligned right */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                    label={t("events.filters.searchByName")}
                    variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={handleSearchQueryChange}
                />
                <TextField
                    label={t("events.filters.searchByDate")}
                    type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  size="small"
                  value={searchDate}
                  onChange={handleSearchDateChange}
                />
              </Box>
            </Box>
          </Grid>

          {eventsLoading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="30vh"
            >
              <CircularProgress />
            </Grid>
          ) : filteredEvents.length === 0 ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              height="30vh"
            >
              <Typography variant="h6">{t("events.filters.noRecord")}</Typography>
            </Grid>
          ) : (
            filteredEvents.map((event) => (
              <EventsComponent
                key={event.id}
                id={event.id}
                title={event.title}
                date={event.date}
                image={event.image}
                description={event.description}
                price={event.price}
                category={event.category}
              />
            ))
          )}
        </Grid>
      </Container>
    </>
  );
}
