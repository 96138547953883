import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import * as dataTypes from "./dataTypes";
import * as actions from "./actions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";


function* getCommunityPostsAuthor(action: dataTypes.getCommunityPostsAuthorAction): Generator<any, void, any> {
    const { userId, setLoading, navigate } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/users/auth/userById/${userId}`);
        yield put(actions.getCommunityPostsAuthorSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


export default function* watchGetCommunityPostsAuthorSaga() {
    yield takeLatest(actionTypes.GET_COMMUNITY_POSTS_AUTHOR_REQUEST, getCommunityPostsAuthor);
}


