import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  FormControl,
  IconButton,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchUserJoinedGroupsRequest } from "../../store/communityGroup/actions";
import { createCommunityPostRequest } from "../../store/communityPost/actions";
import { useTranslation } from "react-i18next";

interface Group {
  id: string;
  name: string;
}

export default function CreatePostPage() {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [groupId, setGroupId] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [existingImageIds, setExistingImageIds] = useState<number[]>([]);
  const [groupsLoading, setGroupsLoading] = useState(true);
  const { t } = useTranslation() as { t: (key: string) => string };
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userJoinedGroups: groups } = useSelector(
    (state: any) => state?.communityGroupReducer
  );
  const isSubmitDisabled = !title || !description || !groupId;

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const imagesArray = Array.from(files);
      setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);
    }
  };

  const handleRemoveImage = (index: number) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (!title) newErrors.title = t("community.posts.validation_error.title");
    if (!description)
      newErrors.description = t("community.posts.validation_error.description");
    if (!groupId) newErrors.groupId = "Category is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      // Prepare form data
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("groupId", groupId);
      if (currentUser?.user?.id) {
        formData.append("userId", currentUser.user.id);
      }
      selectedImages.forEach((image) => {
        formData.append("images", image);
      });
      // formData.forEach((value, key) => {
      //   console.log(key, value);
      // });

      dispatch(
        createCommunityPostRequest({
          createCommunityPostData: formData,
          token: currentUser?.token,
          navigate,
        })
      );

      // Reset form fields
      setTitle("");
      setDescription("");
      setGroupId("");
      setSelectedImages([]);
    }
  };

  useEffect(() => {
    const fetchGroups = async () => {
      dispatch(
        fetchUserJoinedGroupsRequest({
          userId: currentUser?.user.id,
          setLoading: setGroupsLoading,
          navigate,
          token: currentUser?.token,
        })
      );
    };

    fetchGroups();
  }, []);

  if (groupsLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: "0 0", sm: "0 0", md: "0 15%" }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginTop: "40px" }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: "Syne",
            fontSize: { xs: "20px", sm: "24px", md: "32px" },
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
            color: "#000000",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          {t("community.posts.create_new_post")}{" "}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          borderRadius: 4,
          border: "1px solid",
          marginBottom: "60px",
          margin: 2,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          padding={3}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <FormControl fullWidth error={!!errors.groupId}>
                <Autocomplete
                  options={groups}
                  getOptionLabel={(option: any) => option.name}
                  onChange={(event, value) =>
                    setGroupId(value ? value?.id : "")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("community.posts.input_labels.group")}
                      variant="outlined"
                      onFocus={() => setErrors({ ...errors, groupId: "" })}
                      error={!!errors.groupId}
                      helperText={errors.groupId}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("community.posts.input_labels.title")}
                value={title}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 40) {
                    setTitle(inputValue);
                  }
                }}
                error={!!errors.title}
                helperText={errors.title || `${title.length}/40`}
                onFocus={() => setErrors({ ...errors, title: "" })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("community.posts.input_labels.description")}
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={!!errors.description}
                helperText={errors.description}
                onFocus={() => setErrors({ ...errors, description: "" })}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: "8px",
                  width: "100%",
                  height: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    multiple
                    onChange={handleImageUpload}
                  />
                  <ImageIcon sx={{ fontSize: 50, color: "#ccc" }} />
                </IconButton>
                <Typography
                  sx={{
                    position: "absolute",
                    marginTop: "130px",
                    color: "#999",
                  }}
                >
                  {t("community.posts.input_labels.title")}
                </Typography>
              </Box>
            </Grid>

            {selectedImages.length > 0 && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 2,
                    justifyContent: "center",
                  }}
                >
                  {selectedImages.map((image, index) => (
                    <Box
                      key={index}
                      sx={{
                        position: "relative",
                        display: "inline-block",
                        width: "150px",
                        height: "150px",
                      }}
                    >
                      <Box
                        component="img"
                        src={URL.createObjectURL(image)}
                        alt={`Image ${index + 1}`}
                        sx={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "8px",
                          boxShadow: 2,
                        }}
                      />
                      <IconButton
                        size="small"
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          backgroundColor: "rgba(255, 255, 255, 0.7)",
                        }}
                        onClick={() => handleRemoveImage(index)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                size="large"
                sx={{ minWidth: "120px" }}
                type="submit"
                disabled={isSubmitDisabled}
              >
                {t("community.posts.Post_button")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
