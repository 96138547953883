import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en.json";
import es from "./locales/es.json";
import zh from "./locales/zh.json";
import vi from "./locales/vi.json";
import ko from "./locales/ko.json";
import ja from "./locales/ja.json";
import ru from "./locales/ru.json";
import fr from "./locales/fr.json";
import pt from "./locales/pt.json";
const savedLanguage = localStorage.getItem("i18nextLng") || "en";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            es: { translation: es },
            zh: { translation: zh },
            vi: { translation: vi },
            ko: { translation: ko },
            ja: { translation: ja },
            ru: { translation: ru },
            fr: { translation: fr },
            pt: { translation: pt },
        },
        lng: savedLanguage, // Set language from localStorage
        fallbackLng: "en",
        interpolation: { escapeValue: false },
        detection: {
            order: ["localStorage", "navigator"],
            caches: ["localStorage"],
        },
    });

export default i18n;
