
import * as React from "react";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import CommunityHeader from "./CommunityHeader";

interface Props {
  window?: () => Window;
  children: any;
}

export default function CommunityLayout(props: Props) {
  const { window, children } = props;
 
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
    <Box>
        <CommunityHeader></CommunityHeader>
    </Box>
    
    <Box sx={{minHeight:"75vh"}} >
        {children}
    </Box>

    <Box>
        <Footer ></Footer>
    </Box>
    
    </>
  );
}
