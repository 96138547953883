import React from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("i18nextLng", event.target.value);
  };

  return (
    <Select
      value={i18n.language}
      onChange={changeLanguage}
      variant="outlined"
      size="small"
      sx={{backgroundColor: "white" }}
    >
      <MenuItem value="en">🇺🇸 English</MenuItem>
      <MenuItem value="ru">🇷🇺 Русский</MenuItem>
      <MenuItem value="fr">🇫🇷 Français</MenuItem>
      <MenuItem value="pt">🇵🇹 Português</MenuItem>
      <MenuItem value="zh">🇨🇳 中文</MenuItem>
      <MenuItem value="vi">🇻🇳 Tiếng Việt</MenuItem>
      <MenuItem value="es">🇪🇸 Español</MenuItem>
      <MenuItem value="ja">🇯🇵 日本語</MenuItem>
      <MenuItem value="ko">🇰🇷 한국어</MenuItem>
    </Select>
  );
};

export default LanguageSwitcher;
