import { FC, useState, useEffect } from "react";
import {
  Avatar,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { toast } from "react-toastify";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { handleApiError } from "../common/Api-error-handler";
import { logout } from "../../store/auth/actions";
import { useNavigate } from "react-router-dom";

const Profile: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [uploadedImage, setUploadedImage] = useState<string>("");

  // Track whether form is editable or not
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    securityQuestion: "",
    address_email:"",
    answer: "",
    fullname: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    securityQuestion: "",
    address_email:"",
    answer: "",
    fullname: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
  });

  // UseEffect to set initial form values from currentUser
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/users/auth/user/${currentUser.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser?.token}`,
            },
          }
        );
        const user = response.data;

        setFormValues({
          firstName: user.firstName || "",
          lastName: user.lastName || "",
          email: user.email || "",
          dateOfBirth:
            new Date(user.dateOfBirth).toISOString().slice(0, 10) || "",
          securityQuestion: user.security_question_id || "",
          answer: user.security_answer || "",
          address_email:user.address_email || "",
          fullname: user.fullname || "",
          phone: user.phone || "",
          address: user.address || "",
          city: user.city || "",
          state: user.state || "",
          postalCode: user.postalCode || "",
        });
      } catch (error) {
        const { message, navigateTo } = handleApiError(error);
        toast.error(message);
        if (navigateTo) {
          if (navigateTo == "login") {
            dispatch(logout());
          }
          navigate(`/${navigateTo}`);
        }
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors: any = {};

    // Validation
    if (!formValues.firstName) errors.firstName = "First Name is required";
    if (!formValues.lastName) errors.lastName = "Last Name is required";
    if (!formValues.address_email) errors.address_email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formValues.address_email))
      errors.address_email = "Email is invalid";
    if (!formValues.dateOfBirth)
      errors.dateOfBirth = "Date of Birth is required";
    if (!formValues.fullname) errors.fullname = "Full Name is required";
    if (!formValues.phone) errors.phone = "Phone number is required";
    if (!formValues.address) errors.address = "Address is required";
    if (!formValues.city) errors.city = "City is required";
    if (!formValues.state) errors.state = "State is required";
    if (!formValues.postalCode) {
      errors.postalCode = "Postal Code is required";
    } else if (!/^\d{5}$/.test(formValues.postalCode)) {
      errors.postalCode = "Postal Code must be exactly 5 digits";
    }
    
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const errors: any = {};

      setFormErrors(errors);

      if (Object.keys(errors).length === 0) {
        setBtnLoading(true);

        try {
          const response = await axios.put(
            `${baseUrl}/api/users/auth/update-profile/${currentUser.user.id}`,
            formValues
          );

          toast.success(response.data.message);
        } catch (error) {
          const { message, navigateTo } = handleApiError(error);
          toast.error(message);
          if (navigateTo) {
            if (navigateTo == "login") {
              dispatch(logout());
            }
            navigate(`/${navigateTo}`);
          }
        } finally {
          setBtnLoading(false);
        }
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      {/* Account Details */}
      <Grid item xs={12}>
        <Grid
          container
          direction="column"
          flexShrink={0}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              width: { xs: "auto", sm: "auto", md: "60%" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: 1,
                display: "block",
                color: "#000000",
                width: "100%",
                mt: 2,
                mb: { sx: 0, sm: 0, md: 2 },
                marginRight: { xs: 0, sm: 0, md: "10%" },
              }}
            >
              Account Details
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Form */}
      <Grid item xs={12}>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{
            padding: { xs: 1, sm: 2, md: 3 },
            borderRadius: 4,
            border: "1px solid",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"} mb={2}>
            <Typography variant="h6" gutterBottom>
              Personal Details
            </Typography>
            <IconButton
              onClick={() => setIsEditable((prev) => !prev)}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>
          </Box>

          <Grid container spacing={2} alignItems="center">
            <Grid item sm={3}>
              <Avatar
                sx={{ width: 150, height: 150 }}
                variant="square"
                src="/avatar-url.png"
              />
            </Grid>
            <Grid item sm={9}>
              <Grid container spacing={2}>
              <Grid item xs={6}>
  <TextField
    label="First Name"
    name="firstName"
    value={formValues.firstName}
    onChange={(e) => {
      const newValue = e.target.value;

      if (newValue.length <= 30) {
        setFormValues((prevValues) => ({
          ...prevValues,
          firstName: newValue,
        }));

        // Clear the error if the user resolves it
        if (formErrors.firstName) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            firstName: "",
          }));
        }
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          firstName: "First Name must be 30 characters or less",
        }));
      }
    }}
    fullWidth
    error={!!formErrors.firstName}
    helperText={formErrors.firstName}
    disabled={!isEditable}
  />
              </Grid>
              <Grid item xs={6}>
  <TextField
    label="Last Name"
    name="lastName"
    value={formValues.lastName}
    onChange={(e) => {
      const newValue = e.target.value;

      if (newValue.length <= 30) {
        setFormValues((prevValues) => ({
          ...prevValues,
          lastName: newValue,
        }));

        // Clear the error if the user resolves it
        if (formErrors.lastName) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            lastName: "",
          }));
        }
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          lastName: "Last Name must be 30 characters or less",
        }));
      }
    }}
    fullWidth
    error={!!formErrors.lastName}
    helperText={formErrors.lastName}
    disabled={!isEditable}
  />
              </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Email"
                    name="email"
                    value={formValues.email}
                    onChange={(e) => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        email: e.target.value,
                      }));

                      // Clear the error when the user starts typing
                      if (formErrors.email) {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          email: "", // Clear the error message for email
                        }));
                      }
                    }}
                    fullWidth
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    disabled
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="dateOfBirth"
                    type="date"
                    value={formValues.dateOfBirth}
                    onChange={(e) => {
                      setFormValues((prevValues) => ({
                        ...prevValues,
                        dateOfBirth: e.target.value,
                      }));

                      // Clear the error when the user starts typing
                      if (formErrors.dateOfBirth) {
                        setFormErrors((prevErrors) => ({
                          ...prevErrors,
                          dateOfBirth: "", // Clear the error message for dateOfBirth
                        }));
                      }
                    }}
                    fullWidth
                    error={!!formErrors.dateOfBirth}
                    helperText={formErrors.dateOfBirth}
                    disabled={!isEditable}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Divider sx={{ marginY: 2 }} />

          {/* Shipping Details */}
          <Typography variant="h6" gutterBottom>
            Shipping Details
          </Typography>
          <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
  <TextField
    label="Full Name"
    name="fullname"
    value={formValues.fullname}
    onChange={(e) => {
      const newValue = e.target.value;

      if (newValue.length <= 30) {
        setFormValues((prevValues) => ({
          ...prevValues,
          fullname: newValue,
        }));

        // Clear the error if the user resolves it
        if (formErrors.fullname) {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            fullname: "",
          }));
        }
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          fullname: "Full Name must be 30 characters or less",
        }));
      }
    }}
    fullWidth
    error={!!formErrors.fullname}
    helperText={formErrors.fullname}
    disabled={!isEditable}
  />
          </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email"
                name="email"
                value={formValues.address_email}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    address_email: e.target.value,
                  }));

                  // Clear the error when the user starts typing
                  if (formErrors.address_email) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      address_email: "", // Clear the error message for email
                    }));
                  }
                }}
                fullWidth
                error={!!formErrors.address_email}
                helperText={formErrors.address_email}
                disabled={!isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
  <TextField
    label="Phone"
    name="phone"
    type="number"
    value={formValues.phone}
    onChange={(e) => {
      const inputValue = e.target.value;

      // Validate US phone number format
      const usPhoneRegex = /^(?:\+1\s?)?(\d{10}|\(\d{3}\)\s?\d{3}-?\d{4})$/;
      const isValidUSPhone = usPhoneRegex.test(inputValue);

      // Update form values
      setFormValues((prevValues) => ({
        ...prevValues,
        phone: inputValue,
      }));

      // Set or clear the error message
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phone: !isValidUSPhone && inputValue !== "" 
          ? "Enter a valid US phone number (e.g., 1234567890 or (123) 456-7890)." 
          : "", // Clear the error if valid
      }));
    }}
    fullWidth
    error={!!formErrors.phone}
    helperText={formErrors.phone}
    disabled={!isEditable}
  />
</Grid>


            <Grid item xs={12} md={12}>
              <TextField
                label="Address"
                name="address"
                value={formValues.address}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    address: e.target.value,
                  }));

                  // Clear the error when the user starts typing
                  if (formErrors.address) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      address: "", // Clear the error message for address
                    }));
                  }
                }}
                fullWidth
                error={!!formErrors.address}
                helperText={formErrors.address}
                disabled={!isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="City"
                name="city"
                value={formValues.city}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    city: e.target.value,
                  }));

                  // Clear the error when the user starts typing
                  if (formErrors.city) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      city: "", // Clear the error message for city
                    }));
                  }
                }}
                fullWidth
                error={!!formErrors.city}
                helperText={formErrors.city}
                disabled={!isEditable}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="State"
                name="state"
                value={formValues.state}
                onChange={(e) => {
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    state: e.target.value,
                  }));

                  // Clear the error when the user starts typing
                  if (formErrors.state) {
                    setFormErrors((prevErrors) => ({
                      ...prevErrors,
                      state: "", // Clear the error message for state
                    }));
                  }
                }}
                fullWidth
                error={!!formErrors.state}
                helperText={formErrors.state}
                disabled={!isEditable}
              />
            </Grid>
            <Grid item xs={12} md={12}>
  <TextField
    label="Postal Code"
    name="postalCode"
    type="number"
    value={formValues.postalCode}
    onChange={(e) => {
      const value = e.target.value;

      // Allow only up to 5 digits
      if (value.length <= 5) {
        // Update the form values
        setFormValues((prevValues) => ({
          ...prevValues,
          postalCode: value,
        }));

        // Regular expression for US postal code validation
        const zipCodeRegex = /^\d{5}$/;

        // Validate postal code and set error if invalid
        if (!zipCodeRegex.test(value) && value !== "") {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            postalCode: "Invalid postal code.",
          }));
        } else {
          // Clear the error if the postal code is valid
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            postalCode: "",
          }));
        }
      }
    }}
    fullWidth
    error={!!formErrors.postalCode}
    helperText={formErrors.postalCode}
    disabled={!isEditable}
    inputProps={{
      maxLength: 5, // Restrict input to 5 characters
    }}
  />
</Grid>
          </Grid>

          {/* Submit Button */}
          <Box mt={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={!isEditable}
            >
              {btnLoading ? "Updating..." : "save"}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Profile;
