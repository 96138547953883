export const FETCH_EVENTS_REQUEST = "fetch_events_request";
export const FETCH_EVENTS_SUCCESS = "fetch_events_success";

export const DELETE_EVENT_REQUEST = "delete_event_request";
export const DELETE_EVENT_SUCCESS = "delete_event_success";

export const CREATE_EVENT_REQUEST = "create_event_request";
export const CREATE_EVENT_SUCCESS = "create_event_success";

export const UPDATE_EVENT_REQUEST = "update_event_request";
export const UPDATE_EVENT_SUCCESS = "update_event_success";

export const GET_EVENT_REQUEST = "get_event_request";
export const GET_EVENT_SUCCESS = "get_event_success";

export const GET_UPCOMING_EVENTS_REQUEST = "get_upcoming_events_request";
export const GET_UPCOMING_EVENTS_SUCCESS = "get_upcoming_events_success";
