import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Box, Container, useMediaQuery } from "@mui/system";
import SectionOfSixPiller from "./SectionOfSixPiller";
import { throttle } from "lodash";
import HeroBanner from "./BecomeALyferHeroBanner";
import videoUrl from "../../assets/videos/BecomeALyfer.mp4";
import { Link } from "react-router-dom";
import MobileViewOfSixPiller from "./The6PillarsMobileView";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const BecomeALyfer = () => {
  const [scrollY, setScrollY] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation() as { t: (key: string) => string };

  const handleScroll = useCallback(
    throttle(() => {
      setScrollY(window.scrollY);
    }, 150),
    []
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const scaleFactor = React.useMemo(() => {
    const scale = 1 + scrollY / 1000;
    return Math.min(1.42, Math.max(1, scale));
  }, [scrollY]);

  const ResponsiveIframe = styled.iframe`
    width: 100%;
    height: 800px; /* Default height */

    @media (max-width: 576px) {
      /* XS breakpoint */
      height: 373px;
    }

    /* iPhone SE (1st generation) in portrait orientation */
    @media only screen and (min-device-width: 375px) and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      /* Your styles here */
      height: 338px;
    }

    @media only screen and (min-device-width: 390px) and (max-device-width: 390px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      /* Your styles here */
      height: 351px;
    }

    @media only screen and (min-device-width: 412px) and (max-device-width: 412px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      /* Your styles here */
      height: 371px;
    }

    @media only screen and (min-device-width: 430px) and (max-device-width: 430px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
      /* Your styles here */
      height: 387px;
    }

    @media (min-width: 577px) and (max-width: 768px) {
      /* SM breakpoint */
      height: 692px;
    }
  `;

  const videoIframe = useMemo(
    () => (
      <ResponsiveIframe
        src={videoUrl}
        title="video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        style={{
          width: "100%",
        }}
      />
    ),
    [videoUrl]
  );

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  // Toggle play/pause
  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false); // Show play icon when video ends
  };

  const handleVideoLoad = () => {
    setIsPlaying(false); // Show play icon initially
  };

  return (
    <>
      <HeroBanner />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            width: "90%",
            margin: "auto",
            position: "relative",
            "&:hover .playPauseButton": {
              opacity: 1,
            },
          }}
        >
          {/* Video element */}
          <video
            ref={videoRef}
            src={videoUrl}
            style={{ width: "100%" }}
            controls={false} // Disable native controls
            onLoadedMetadata={handleVideoLoad}
            onEnded={handleVideoEnd}
          />
          {/* Overlay Play/Pause Controls */}
          <Box
            className="playPauseButton"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1,
              cursor: "pointer",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
              width: "60px",
              height: "60px",
              opacity: isPlaying ? 0 : 1,
              transition: "opacity 0.3s ease",
            }}
            onClick={handlePlayPause}
          >
            {/* Icon based on play/pause state */}
            {isPlaying ? (
              <span style={{ color: "white", fontSize: "24px" }}>❚❚</span>
            ) : (
              <span style={{ color: "white", fontSize: "24px" }}>▶</span>
            )}
          </Box>
        </Box>
      </Box>

      {/* Section 4: Illuminating The World */}

      <Container sx={{ maxWidth: "1150px !important" }}>
        <Grid container direction="column">
          <Box
            sx={{
              display: {
                md: "none",
                sm: "none",
                xs: "block",
                width: "100%",
                margin: "40px 0px",
              },
            }}
          ></Box>
          <Grid
            item
            xs={12}
            sx={{
              margin: "40px 0px",
              display: {
                sm: "block",
                xs: "none",
              },
            }}
          >
            <Grid container direction="column" flexShrink={0}>
              <Grid
                item
                xs={12}
                display="flex"
                sx={{
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Syne",
                    fontSize: { xs: "24px", sm: "32px", md: "50px" },
                    fontStyle: "normal",
                    fontWeight: 700,
                    lineHeight: "120%",
                    marginBottom: "10px",
                  }}
                >
                  {t("becomeALyfer.sixPillerHeading")}
                </Typography>
              </Grid>

              <Grid
                container
                spacing={{ xs: 2, sm: 3, md: 4 }}
                justifyContent="center"
              ></Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          {/* Show based on screen size */}
          <div
            style={{
              display: isMobile ? "none" : "block",
            }}
          >
            <SectionOfSixPiller />
          </div>
          <div
            style={{
              display: isMobile ? "block" : "none",
            }}
          >
            <MobileViewOfSixPiller />
          </div>
        </div>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
            minWidth: "100%",
            bgcolor: "#e0e0e0",
            marginBottom: 8,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            alignItems="center"
            sx={{
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "36px", sm: "40px", md: "44px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "100%",
                marginY: "20px",
                cursor: "pointer",
              }}
            >
              {t("becomeALyfer.become_a_lyfer")}
            </Typography>
            <Link to={"/register"}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "10px 35px",
                  fontSize: { xs: "14px", sm: "16px" },
                  borderRadius: 0,
                }}
              >
                {t("clickHereButton")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="lg"></Container>
    </>
  );
};

export default BecomeALyfer;
