import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import baseUrl from "../../../config/apiConfig";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";
import { handleApiError } from "../../common/Api-error-handler";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../store/auth/actions";
import {
  fetchSubCategoryRequest,
  deleteSubCategoryRequest,
  createSubCategoryRequest,
  updateSubCategoryRequest,
} from "../../../store/subCategories/subCategoryActions";
import { fetchSubCategoryCategoriesRequest } from "../../../store/subCategoryCategories/actions";

interface Category {
  id: number;
  name: string;
}

interface SubCategory {
  id: number;
  name: string;
  category: Category;
}

export default function SubCategories() {
  // const [subcategories, setSubCategories] = useState<SubCategory[]>([]);
  //const [categories, setCategories] = useState<Category[]>([]);
  // const [loading, setLoading] = useState<boolean>(true);
  // const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newSubCategoryName, setNewSubCategoryName] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | "">("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [currentSubCategoryId, setCurrentSubCategoryId] = useState<
    number | null
  >(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    nameError?: string;
    categoryError?: string;
  }>({});

  const [subCategoryLoading, subCategorySetLoading] = useState<boolean>(true);
  const [subCategoryCategoriesLoading, subCategoryCategoriesSetLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { subCategories } = useSelector(
    (state: any) => state?.subCategoryReducer
  );
  const { subCategoryCategories } = useSelector(
    (state: any) => state?.subCategoryCategoriesReducer
  );

  console.log("check sub categories",subCategories)
  console.log("check sub categories Categories",subCategoryCategories)

  useEffect(() => {
    dispatch(fetchSubCategoryRequest({ setLoading: subCategorySetLoading, navigate }));
    dispatch(fetchSubCategoryCategoriesRequest({ setLoading: subCategoryCategoriesSetLoading, navigate }));
  }, []);

  const handleOpenEditModal = (subcategory: SubCategory) => {
    setCurrentSubCategoryId(subcategory.id);
    setNewSubCategoryName(subcategory.name);
    setSelectedCategoryId(subcategory.category.id);
    setEditMode(true);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditMode(false);
    setNewSubCategoryName("");
    setSelectedCategoryId("");
    setCurrentSubCategoryId(null);
  };

  // const handleError = (message: string) => {
  //   toast.error(message);
  // };

  const validateForm = () => {
    const newErrors: { nameError?: string; categoryError?: string } = {};

    if (!newSubCategoryName.trim()) {
      newErrors.nameError = "Subcategory name is required.";
    }
    if (!selectedCategoryId) {
      newErrors.categoryError = "Category must be selected.";
    }

    setErrors(newErrors);

    // Return true if no errors, false if errors exist
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }
    const subCategoryData = {
      name: newSubCategoryName,
      categoryId: selectedCategoryId,
    };

    if (editMode && currentSubCategoryId !== null) {
      // Update existing subcategory
      dispatch(
        updateSubCategoryRequest({
          currentSubCategoryId,
          subCategoryData,
          token: currentUser?.token,
          navigate,
        })
      );
    } else {
      //Create new subcategory
      dispatch(
        createSubCategoryRequest({
          subCategoryData,
          token: currentUser?.token,
          navigate,
        })
      );
    }
    handleCloseModal();
  };

  const filteredSubcategories = subCategories.filter(
    (subcategory: SubCategory) =>
      subcategory.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDelete = async (id: number) => {
    dispatch(
      deleteSubCategoryRequest({ id, token: currentUser?.token, navigate })
    );
    setDeleteConfirmOpen(false);
  };

  const openDeleteConfirm = (id: number) => {
    setCurrentSubCategoryId(id);
    setDeleteConfirmOpen(true);
  };

  if (subCategoryLoading || subCategoryCategoriesLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }
  
  // if (error) {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       alignItems="center"
  //       height="100vh"
  //     >
  //       <Typography variant="h6" color="error">
  //         {error}
  //       </Typography>
  //     </Grid>
  //   );
  // }

  return (
    <Grid
  container
  width="100%"
  direction="column"
  padding={{ xs: 0, sm: 0, md: 4 }}
  rowSpacing={3}
  flexShrink={0}
  sx={{ marginBottom: "40px" }}
>
  <Grid item xs={12}>
    <Grid container direction="column" flexShrink={0} justifyContent={'center'} alignItems={'center'} >
      <Grid
        item
        xs={12}
        display="flex"
        alignItems="center"
        sx={{
          // padding: { xs: "8px", sm: "12px", md: "16px" },
          marginRight:{ sx:0 , sm:0 , md:'33%'}
        }}
      >
        <Typography
          sx={{
            fontFamily: "Syne",
            fontSize: { xs: "20px", sm: "24px", md: "32px" },
            fontWeight: 700,
            lineHeight: 1,
            color: "#000000",
            display:'block',
            mt:2,
          }}
        >
          All Categories
        </Typography>
      </Grid>
    </Grid>
  </Grid>

  <Grid item xs={12}>
    <Grid
      container
      direction={{ xs: "row", md:'row'  }}
      alignItems={{ xs: "flex-start", md: "center" }}
      justifyContent="space-between"
      marginBottom={3}
    >
      <Grid item display="flex" alignItems="center" sx={{ marginBottom: { xs: 2, md: 0 } }}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Outfit",
            fontSize: '18px',
            fontWeight: "bold",
            display:{xs:'none', sm:'block'},
            marginLeft:'5px'
          }}
        >
          All Categories
        </Typography>
      </Grid>

      <Grid item display="flex" gap={2} flexDirection={{ xs: "row", sm: "row" }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ width: { xs: "72%", sm: "auto" } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          color="primary"
          sx={{
            padding: { xs: "10px", sm: "15px" },
            textTransform: "capitalize",
          }}
          onClick={() => {
            setModalOpen(true);
            setEditMode(false);
          }}
        >
          Add New Subcategory
        </Button>
      </Grid>
    </Grid>

    <Grid item xs={12}>
  <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
    <Table sx={{ minWidth: { xs: "100%", sm: 650 } }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell sx={{ padding: { xs: "6px", sm: "8px" }, fontWeight: "bold" }}>ID</TableCell>
          <TableCell sx={{ textAlign: "center", fontWeight: "bold", padding: { xs: "6px", sm: "8px" } }}>
            Category Name
          </TableCell>
          <TableCell sx={{ textAlign: "center", fontWeight: "bold", padding: { xs: "6px", sm: "8px" } }}>
            Sub Category Name
          </TableCell>
          <TableCell sx={{ textAlign: "center", fontWeight: "bold", padding: { xs: "6px", sm: "8px" } }}>
            Actions
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredSubcategories.length > 0 ? (
          filteredSubcategories.map(                   
             (subcategory: SubCategory, index: number) => (
            
            <TableRow key={subcategory.id}>
              <TableCell sx={{ padding: { xs: "6px", sm: "8px" } }}>{index+1}</TableCell>
              <TableCell sx={{ textAlign: "center", padding: { xs: "6px", sm: "8px" } }}>
                {subcategory.category.name}
              </TableCell>
              <TableCell sx={{ textAlign: "center", padding: { xs: "6px", sm: "8px" } }}>
                {subcategory.name}
              </TableCell>
              <TableCell sx={{ textAlign: "center", padding: { xs: "6px", sm: "8px" } }}>
                <Button
                  onClick={() => handleOpenEditModal(subcategory)}
                  sx={{ fontSize: { xs: "12px", sm: "14px" }, padding: { xs: "4px", sm: "6px" } }}
                >
                  Edit
                </Button>
                <Button
                  onClick={() => openDeleteConfirm(subcategory.id)}
                  color="error"
                  sx={{ fontSize: { xs: "12px", sm: "14px" }, padding: { xs: "4px", sm: "6px" } }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4} sx={{ padding: { xs: "6px", sm: "8px" } }}>
              <Typography variant="h6" textAlign="center">
                No Products Found
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
</Grid>

  </Grid>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          style: {
            width: "600px",
            maxWidth: "90%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle textAlign={"center"} fontWeight={"bold"}>
          {editMode ? "Edit Subcategory" : "Add New Subcategory"}
        </DialogTitle>
        <DialogContent>
<Box component="form" onSubmit={handleSubmit} noValidate>
  <TextField
    margin="dense"
    label="Category Name"
    type="text"
    fullWidth
    value={newSubCategoryName}
    onChange={(e) => {
      setNewSubCategoryName(e.target.value);
      if (errors.nameError) {
        setErrors((prevErrors) => ({ ...prevErrors, nameError: '' }));
      }
    }}
    error={!!errors.nameError}
    helperText={errors.nameError}
  />
  
  <FormControl fullWidth margin="dense">
    <Select
      value={selectedCategoryId}
      onChange={(e) => {
        setSelectedCategoryId(e.target.value as number);
        // Clear the category error when a new category is selected
        if (errors.categoryError) {
          setErrors((prevErrors) => ({ ...prevErrors, categoryError: '' }));
        }
      }}
      displayEmpty
    >
      <MenuItem value="">
        <InputLabel>Select Category</InputLabel>
      </MenuItem>
      {subCategoryCategories.map((category:Category ) => (
        <MenuItem key={category.id} value={category.id}>
          {category.name}
        </MenuItem>
      ))}
    </Select>
    {errors.categoryError && (
      <Typography variant="caption" color="error">
        {errors.categoryError}
      </Typography>
    )}
  </FormControl>
</Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          style: {
            width: "600px",
            maxWidth: "90%",
            margin: "auto",
          },
        }}
      >
        <DialogTitle textAlign={"center"} fontWeight={"bold"}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography textAlign={"center"}>
            Are you sure you want to delete this subcategory?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              currentSubCategoryId !== null &&
              handleDelete(currentSubCategoryId)
            }
            color="primary"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}