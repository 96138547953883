import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface Group {
  id: number;
  name: string;
  description: string;
  lastActivity: string;
  postsCount: number;
}

interface PopularGroupsProps {
  groups: Group[];
}

const PopularGroups: React.FC<PopularGroupsProps> = ({ groups }) => {
  const navigate = useNavigate();
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { t } = useTranslation() as { t: (key: string) => string };
  return (
    <Box
      sx={{
        maxHeight: "500px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none", // Hide the scrollbar
        },
        msOverflowStyle: "none", // For Internet Explorer and Edge
        scrollbarWidth: "none", // For Firefox
      }}
    >
      <Typography variant="h6" gutterBottom>
        {currentUser
          ? t("community.Joined_Group")
          : t("community.populor_groups")}
      </Typography>

      {currentUser && groups.length === 0 && (
        <Typography variant="body1" color="textSecondary">
          {t("community.you_have_not_joined_any_groups_yet")}
        </Typography>
      )}

      <List>
        {groups.map((group) => (
          <ListItem key={group.id} disableGutters>
            <Box sx={{ width: "100%" }}>
              {/* Name and Last Activity */}
              <ListItemText
                onClick={() => navigate(`/community/posts/group/${group.id}`)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                primary={
                  <Typography sx={{ fontWeight: "bold" }}>
                    {group.name}
                  </Typography>
                }
                secondary={
                  group.lastActivity ? dayjs(group.lastActivity).fromNow() : ""
                }
              />

              {/* Description */}
              <ListItemText
                sx={{
                  color: "text.secondary",
                  fontSize: "0.875rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "70%",
                }}
              >
                {group.description}
              </ListItemText>
            </Box>
          </ListItem>
        ))}
      </List>
      <Box justifyContent={"center"} display={"flex"} mb={2}>
        <Button
          variant="contained"
          sx={{ marginTop: "1rem", textTransform: "capitalize" }}
          onClick={() => navigate(`/community/groups`)}
        >
          {t("community.view_all")}
        </Button>
      </Box>
    </Box>
  );
};

export default PopularGroups;
