import React, { useEffect, useState } from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";
import ProductComponent from "../Shop/ProductComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getWishlistsRequest } from "../../store/wishlist/wishlistActions";
import { useTranslation } from "react-i18next";

interface WishlistItem {
  Product: {
    id: number;
    title: string;
    description: string;
    image: string;
    is_soldout: boolean;
    colors: { name: string; code: string }[];
    size: any;
    variants: any;
    images: {
      fullPath: string;
      id: number;
      productId: number;
      image: string;
    }[];
    price: number;
    quantity: number;
    subcategoryId: number;
  };
  userId: number;
  id: number;
  productId: number;
  createdAt: string;
  updatedAt: string;
}

export default function Wishlist() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { wishlists } = useSelector((state: any) => state?.wishlistReducer);
  const { t } = useTranslation() as { t: (key: string) => string };

  useEffect(() => {
    const userId = currentUser?.user?.id ?? 0;
    dispatch(
      getWishlistsRequest({
        userId,
        token: currentUser?.token,
        setLoading,
        navigate,
      })
    );
  }, []);

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      <Grid item xs={12}>
        <Grid container direction="column" flexShrink={0}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            sx={{ padding: { xs: "8px", sm: "12px", md: "16px" } }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontWeight: 700,
                lineHeight: "120%",
                color: "#000000",
                marginLeft: { xs: 0, md: 20 },
              }}
            >
              {t("wishlist.wishlist")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Display wishlist items */}
      <Grid item xs={12}>
        {wishlists.length > 0 ? (
          <Grid container spacing={3}>
            {wishlists.map((wishlistItem: WishlistItem) => (
              <Grid item xs={12} sm={6} md={4} key={wishlistItem.Product.id}>
                <ProductComponent
                  product={wishlistItem.Product}
                  userId={currentUser?.user?.id ?? 0}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1">
            {" "}
            {t("wishlist.no_items_in_your_wishlist")}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
