// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   Typography,
//   TextField,
//   FormControlLabel,
//   Checkbox,
//   Button,
//   Box,
// } from "@mui/material";
// import axios from "axios";
// import baseUrl from "../../../config/apiConfig";
// import { useSelector } from "react-redux";
// import { toast } from "react-toastify";

// const PointsPage = () => {
//   const [isDisabledSignUp, setIsDisabledSignUp] = useState(true);
//   const [signUpPoints, setSignUpPoints] = useState("");
//   const [isDisabledPurchase, setIsDisabledPurchase] = useState(true);
//   const [purchasePoints, setPurchasePoints] = useState("");
//   const [isDisabledMonetary, setIsDisabledMonetary] = useState(true);
//   const [monetaryValue, setMonetaryValue] = useState("");
//   const [isDisabledQRcode, setIsDisabledQRcode] = useState(true);
//   const [QRValue, setQRValue] = useState("");
//   const [id, setId] = useState("")
//   const [settings, setSettings] = useState<any[]>([]);
//   const currentUser = useSelector((state: any) => state?.Auth?.currentUser);

//   useEffect(() => {
//     // Fetch settings on component load
//     const fetchSettings = async () => {
//       try {
//         const response = await axios.get(`${baseUrl}/api/users/settings`);
//         const settings = response.data;
//         console.log('setting', settings);
  
//         // Map API settings to state variables
//         settings.forEach((setting:any) => {
//           switch (setting.settingType) {
//             case "signUpPoints":
//               setSignUpPoints(setting.value.toString());
//               setId(setting.id);              
//               break;
//             case "purchasingPointsApplicable":
//               setPurchasePoints(setting.value.toString());
//               setId(setting.id);
//               break;
//             case "manertyPoints":
//               setMonetaryValue(setting.value.toString());
//               setId(setting.id);
//               break;
//             case "signUpPointsByQrCode":
//               setQRValue(setting.value.toString());
//               setId(setting.id);
//               break;
//             default:
//               console.warn(`Unknown setting type: ${setting.settingType}`);
//           }
//           setSettings(settings);
//         });
//       } catch (error) {
//         console.error("Failed to fetch settings:", error);
//       }
//     };
  
//     fetchSettings();
//   }, []);
  

//   const handleSave = async (fieldName: any) => {
//     // Find the corresponding setting object from the state
//     const settingToSave = settings.find((setting: any) => {
//       if (fieldName === "signUp") return setting.settingType === "signUpPoints";
//       if (fieldName === "purchase") return setting.settingType === "purchasingPointsApplicable";
//       if (fieldName === "monetary") return setting.settingType === "manertyPoints";
//       return setting.settingType === "signUpPointsByQrCode";
//     });
  
//     if (settingToSave) {
//       const value =
//         fieldName === "signUp"
//           ? signUpPoints
//           : fieldName === "purchase"
//           ? purchasePoints
//           : fieldName === "monetary"
//           ? monetaryValue
//           : QRValue;
  
//       const settingType =
//         fieldName === "signUp"
//           ? "signUpPoints"
//           : fieldName === "purchase"
//           ? "purchasingPointsApplicable"
//           : fieldName === "monetary"
//           ? "manertyPoints"
//           : "signUpPointsByQrCode";
  
//       try {
//         await axios.post(
//           `${baseUrl}/api/users/settings/${settingToSave.id}`,
//           {
//             settingType,
//             value: parseInt(value, 10),
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${currentUser?.token}`,
//             },
//           }
//         );
//         toast.success(`Saved value for ${fieldName}: ${value}`);
//       } catch (error) {
//         console.error(`Failed to save ${fieldName}:`, error);
//         toast.error(`Failed to save ${fieldName}. Please try again.`);
//       }
//     }
//   };
//   return (
//     <Grid
//       container
//       width="100%"
//       direction="column"
//       padding={{ xs: 2, sm: 3, md: 3 }}
//       rowSpacing={2}
//       flexShrink={0}
//       sx={{ marginBottom: "40px" }}
//     >
//       <Grid item xs={12}>
//         <Grid container direction="column" flexShrink={0}>
//           <Grid
//             item
//             xs={12}
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             sx={{
//               marginBottom: { xs: "10px", sm: "15px", md: "20px" },
//               padding: { xs: "8px", sm: "12px", md: "16px" },
//             }}
//           >
//             <Typography
//               sx={{
//                 fontFamily: "Syne",
//                 fontSize: { xs: "16px", sm: "24px", md: "32px" },
//                 fontStyle: "normal",
//                 fontWeight: 700,
//                 lineHeight: "120%",
//                 textAlign: "center",
//                 color: "#000000",
//               }}
//             >
//               Points Setting
//             </Typography>
//           </Grid>
//         </Grid>
//       </Grid>
//       <Box display={"flex"} justifyContent={"center"}>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//             alignItems: "center",
//             border: "1px solid black",
//             borderRadius: 5,
//             width: { xs: "100%", sm: "60%" },
//             p: 2,
//             mt: 4,
//           }}
//         >
//           {/* Dynamic Field Rendering */}
//           {[
//             {
//               label: "Points added on Sign Up",
//               value: signUpPoints,
//               setValue: setSignUpPoints,
//               isDisabled: isDisabledSignUp,
//               setDisabled: setIsDisabledSignUp,
//               fieldName: "signUp",
//             },
//             {
//               label: "Points received from purchase",
//               value: purchasePoints,
//               setValue: setPurchasePoints,
//               isDisabled: isDisabledPurchase,
//               setDisabled: setIsDisabledPurchase,
//               fieldName: "purchase",
//             },
//             {
//               label: "Monetary value of points",
//               value: monetaryValue,
//               setValue: setMonetaryValue,
//               isDisabled: isDisabledMonetary,
//               setDisabled: setIsDisabledMonetary,
//               fieldName: "monetary",
//             },
//             {
//               label: "QR code value of points",
//               value: QRValue,
//               setValue: setQRValue,
//               isDisabled: isDisabledQRcode,
//               setDisabled: setIsDisabledQRcode,
//               fieldName: "qrCode",
//             },
//           ].map(({ label, value, setValue, isDisabled, setDisabled, fieldName }) => (
//             <Box key={fieldName} sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
//               <Typography variant="body1" textAlign={"center"}>
//                 <h3>{label}</h3>
//               </Typography>
//               <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
//   <TextField
//     label="Enter value"
//     type="number"
//     value={value}
//     onChange={(e) => {
//       const newValue = e.target.value;
//       // Only set the value if it's a positive number or empty string (to allow clearing the input)
//       if (newValue === "" || Number(newValue) > 0) {
//         setValue(newValue);
//       }
//     }}
//     disabled={isDisabled}
//     size="small"
//   />
//   <FormControlLabel
//     control={
//       <Checkbox
//         checked={isDisabled}
//         onChange={(e) => setDisabled(e.target.checked)}
//       />
//     }
//     label="Disable"
//   />
//   <Button
//     variant="contained"
//     onClick={() => handleSave(fieldName)}
//     disabled={isDisabled || !value.trim()}
//     size="small"
//   >
//     Save
//   </Button>
// </Box>
//               <Typography sx={{ fontSize: "12px", color: "gray", marginTop: "4px" }}>
//                 Set the {label.toLowerCase()}.
//               </Typography>
//             </Box>
//           ))}
//         </Box>
//       </Box>
//     </Grid>
//   );
// };

// export default PointsPage;



import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
} from "@mui/material";
import axios from "axios";
import baseUrl from "../../../config/apiConfig";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const PointsPage = () => {
  const [settings, setSettings] = useState<any[]>([]);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/users/settings`);
        setSettings(response.data);
      } catch (error) {
        console.error("Failed to fetch settings:", error);
      }
    };

    fetchSettings();
  }, []);

  const handleSave = async (setting: any) => {
    if (setting.value === "" || isNaN(setting.value)) {
      toast.error("Value cannot be empty.");
      return;
    }
    try {
      await axios.post(
        `${baseUrl}/api/users/settings/${setting.id}`,
        {
          value: parseInt(setting.value, 10),
          activeState: setting.activeState,
        },
        {
          headers: {
            Authorization: `Bearer ${currentUser?.token}`,
          },
        }
      );
      toast.success(`Saved ${setting.settingType} settings.`);
    } catch (error) {
      console.error(`Failed to save ${setting.settingType}:`, error);
      toast.error(`Failed to save ${setting.settingType}. Please try again.`);
    }
  };

  const handleToggleActive = (id: string) => {
    setSettings((prevSettings) =>
      prevSettings.map((setting) =>
        setting.id === id
          ? { ...setting, activeState: !setting.activeState }
          : setting
      )
    );
  };

  const handleValueChange = (id: string, value: string) => {
    const numericValue = parseInt(value, 10);
    if (numericValue >= 0 || value === "") {
      setSettings((prevSettings) =>
        prevSettings.map((setting) =>
          setting.id === id ? { ...setting, value } : setting
        )
      );
    } else {
      toast.error("Value cannot be negative.");
    }
  };

  const settingTypeLabels: Record<string, string> = {
    signUpPoints: "Sign Up Points",
    purchasingPointsApplicable: "Purchasing Points Applicable",
    manertyPoints: "Monetary Points",
    signUpPointsByQrCode: "Sign Up Points by QR Code",
  };

  const settingHelperText: Record<string, string> = {
    signUpPoints:
      "This is the amount points a new user recives when they sign up to lyfers",
    purchasingPointsApplicable: "what monetary value does a 1 point hold",
    manertyPoints: "Amount of points recived per 1$ purchase",
    signUpPointsByQrCode:
      "This is the amount of points the owner of the QR code recives when a what monetary value does a 1 point hold",
  };

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 2, sm: 3, md: 3 }}
      rowSpacing={2}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      <Grid item xs={12}>
        <Grid container direction="column" flexShrink={0}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              marginBottom: { xs: "10px", sm: "15px", md: "20px" },
              padding: { xs: "8px", sm: "12px", md: "16px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "16px", sm: "24px", md: "32px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
                textAlign: "center",
                color: "#000000",
              }}
            >
              Points Setting
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid black",
            borderRadius: 5,
            width: { xs: "100%", xl: "60%" },
            p: 2,
            mt: 4,
          }}
        >
          {settings.map((setting) => {
            const value = parseFloat(setting.value);
            let exampleText = "";
            if (
              setting.settingType === "purchasingPointsApplicable" &&
              !isNaN(value)
            ) {
              exampleText = `Amount of points received per 1$ purchase, for example, a 100$ purchase will give: ${
                100 * value
              } points.`;
            } else if (
              setting.settingType === "manertyPoints" &&
              !isNaN(value)
            ) {
              exampleText = `${value} purchase will give ${
                100 * value
              } points .`;
            }

            return (
              <Box
                key={setting.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  width: { xs: "100%", sm: "60%" },
                }}
              >
                <Typography variant="h6" textAlign="center">
                  {settingTypeLabels[setting.settingType] ||
                    setting.settingType}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <TextField
                    label="Enter value"
                    type="number"
                    value={setting.value}
                    onChange={(e) =>
                      handleValueChange(setting.id, e.target.value)
                    }
                    size="small"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={setting.activeState}
                        onChange={() => handleToggleActive(setting.id)}
                      />
                    }
                    label={setting.activeState ? "Active" : "Inactive"}
                  />
                  <Button
                    variant="contained"
                    onClick={() => handleSave(setting)}
                    size="small"
                  >
                    Save
                  </Button>
                </Box>
                <Typography sx={{ fontSize: "12px", color: "gray", mb: 3 }}>
                  {settingHelperText[setting.settingType] ||
                    setting.settingType}
                  {exampleText && (
                    <Box sx={{ fontSize: "14px", marginTop: 1 }}>
                      <strong>Example: </strong>
                      {exampleText}
                    </Box>
                  )}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Grid>
  );  
};

export default PointsPage;