import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

type MeasurementValue = {
  size: string;
  min_value?: string;
  max_value?: string;
  value?: string;
};

type Measurement = {
  type_label: string;
  values: MeasurementValue[];
};

type SizeTable = {
  type: string;
  unit: string;
  image_url:string
  description?: string;
  measurements: Measurement[];
};

export type ProductData = {
  size_tables: SizeTable[];
};

interface Props {
  data: ProductData;
  tableType: string; // Specify which "type" to use for the table, e.g., "product_measure".
  tableType2: string; // Specify which "type" to use for the table, e.g., "product_measure".
}

const GenericSizeTable: React.FC<Props> = ({ data, tableType,tableType2 }) => {
  let sizeTable = data.size_tables.find((table) => table.type === tableType) ;

  if (!sizeTable) {

    sizeTable = data.size_tables.find((table) => table.type === tableType2);
    if (!sizeTable) {
        return (
          <Typography color="error">
            No size table found for the specified type: {tableType}.
          </Typography>
        );
    }
  }
  const { measurements } = sizeTable;

  // Collect unique type_labels as columns and align size data
  const columnHeaders = measurements.map((m) => m.type_label);
  const rows: Record<string, Record<string, string>> = {};

  measurements.forEach((measurement) => {
    measurement.values.forEach((value) => {
      if (!rows[value.size]) {
        rows[value.size] = { Size: value.size }; // Initialize with Size column
      }
      rows[value.size][measurement.type_label] = value.value
        ? value.value
        : `${value.min_value || ""} - ${value.max_value || ""}`;
    });
  });

  // Transform rows into an array for rendering
  const rowArray = Object.values(rows);
  
  
  return (
    <TableContainer component={Paper} style={{ marginTop: "20px", display: "flex", flexDirection: "column", width: "400px", maxWidth: "600px" }}>
    {sizeTable?.image_url && (
      <img id="drawing-overlay" src={sizeTable.image_url} alt="Drawing" style={{ maxWidth: '100%', marginBottom: '10px' }} />
    )}
    <div style={{ maxHeight: '300px', overflowY: 'auto' }}> {/* Adjust maxHeight as needed */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: "16px", whiteSpace: "nowrap", fontWeight: "600" }}>Size</TableCell>
            {columnHeaders.map((header, index) => (
              <TableCell sx={{ fontSize: "16px", whiteSpace: "nowrap", fontWeight: "600" }} key={index}>
                {`${header} (${sizeTable?.unit || "N/A"})`}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowArray.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              <TableCell sx={{ textAlign: 'center', fontWeight: "400", fontSize: "16px" }}>{row.Size}</TableCell>
              {columnHeaders.map((header, colIndex) => (
                <TableCell sx={{ textAlign: 'center', fontWeight: "400", fontSize: "16px" }} key={colIndex}>
                  {row[header] || "-"}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  </TableContainer>
  );
};

export default GenericSizeTable;