import { produce } from "immer";
import * as types from "./actionTypes";
import * as dataTypes from "./dataTypes";

const initialState: dataTypes.InitialStateCommunityGroup = {
  communityGroup: [],
  approvedCommunityGroups: [],
  userJoinedGroups: [],
};

const communityGroupReducer = produce((state, action) => {
  switch (action.type) {
    case types.FETCH_COMMUNITY_GROUPS_SUCCESS:
      state.communityGroup = action.payload;
      break;

    case types.FETCH_APPROVED_COMMUNITY_GROUPS_SUCCESS:
      state.approvedCommunityGroups = action.payload;
      break;

    case types.DELETE_COMMUNITY_GROUP_SUCCESS:
      state.communityGroup = state.communityGroup.filter(
        (item) => item.id !== action.payload.groupId
      );
      break;

    case types.CREATE_COMMUNITY_GROUP_SUCCESS:
      state.communityGroup = [
        ...state.communityGroup,
        action.payload.newCommunityGroup,
      ];
      break;

    case types.UPDATE_COMMUNITY_GROUP_SUCCESS:
      state.communityGroup = state.communityGroup.map((item) =>
        item.id === action.payload.groupId
          ? action.payload.updatedCommunityGroup
          : item
      );
      break;

    case types.FETCH_USER_JOINED_GROUPS_SUCCESS:
      state.userJoinedGroups = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default communityGroupReducer;
