import { Typography, Box, Grid, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Common.css";
import styles from "./footer.module.css";
import { ReactComponent as LogoBlackSvg } from "../../assets/logos/LogoBlack.svg";
import { useTranslation } from "react-i18next";

function Footer() {
  const navigate = useNavigate();
    const { t } = useTranslation() as { t: (key: string) => string };
  

  return (
    <Grid container id={styles.mainContainer}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        sx={{
          paddingTop: {
            xs: 4,
            sm: 4,
            md: 8,
            lg: 8,
            xl: 8,
          },
          paddingBottom: 4,
          paddingLeft: {
            xs: 3,
            sm: 3,
            md: 4,
            lg: 4,
            xl: 4,
          },
        }}
      >
        <Box
          display={"flex"}
          justifyContent={{ xs: "center", md: "center", lg: "start" }}
          sx={{
            height: {
              xs: 50,
              sm: 50,
              md: 80,
              lg: 80,
              xl: 80,
            },
          }}
        >
          <Box
            onClick={() => navigate("/")}
            height={"inherit"}
            component={LogoBlackSvg}
            sx={{ cursor: "pointer" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12}>
        <Divider variant="middle" />
        <Grid
          container
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
          }}
          gap={{ xs: 1, md: 2 }} // Reduced gap size even more
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            sm={1.5} // Reduced size to make items smaller
            md={1.5} // Reduced size for smaller screen sizes
            lg={1} // Reduced size for large screens
            xl={1} // Reduced size for extra large screens
            justifyContent="center"
            alignItems="center"
            display="flex"
            whiteSpace={"nowrap"}
          >
            <Typography
              onClick={() => navigate("/six-piller")}
              component="span"
              id={styles.clickAbleText}
              sx={{ cursor: "pointer" }}
            >
              {t("header.pages.theSixpiller")}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={1.5} // Reduced size
            md={1.5} // Reduced size
            lg={1} // Reduced size
            xl={1} // Reduced size
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Typography
              onClick={() => navigate("/events")}
              component="span"
              id={styles.clickAbleText}
              sx={{ cursor: "pointer" }}
            >
              {t("header.pages.events")}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={1.5} // Reduced size
            md={1.5} // Reduced size
            lg={1} // Reduced size
            xl={1} // Reduced size
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Typography
              onClick={() => navigate("/community")}
              component="span"
              id={styles.clickAbleText}
              sx={{ cursor: "pointer" }}
            >
              {t("header.pages.community")}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={1.5} // Reduced size
            md={1.5} // Reduced size
            lg={1} // Reduced size
            xl={1} // Reduced size
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Typography
              onClick={() => navigate("/products")}
              component="span"
              id={styles.clickAbleText}
              sx={{ cursor: "pointer" }}
            >
              {t("header.pages.shop")}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={1.5} // Reduced size
            md={1.5} // Reduced size
            lg={1} // Reduced size
            xl={1} // Reduced size
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Typography
              onClick={() => navigate("/contact-us")}
              component="span"
              id={styles.clickAbleText}
              sx={{ cursor: "pointer" }}
            >
              {t("header.pages.contact")}
              </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={1.5}
            md={1.5}
            lg={1}
            xl={1}
            justifyContent="center"
            alignItems="center"
            display="flex"
            whiteSpace={"nowrap"}
          >
            <Typography
              onClick={() => navigate("/become-a-lyfer")}
              component="span"
              id={styles.clickAbleText}
              sx={{ cursor: "pointer" }}
            >
              {t("header.pages.becomeALyfer")}
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="middle" flexItem />
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Grid item paddingY={1}>
            <Typography component="span" id={styles.secondaryContainer}>
              <p style={{ fontSize: 14 }}> © 2025 LYFERS </p>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="span"
              id={styles.secondaryContainer}
              onClick={() => navigate("/term-and-conditions")}
              sx={{ cursor: "pointer" }}
            >
              <p style={{ fontSize: 14 }}> {t("header.pages.termConditions")}</p>
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="span"
              id={styles.secondaryContainer}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/privacy-policy")}
            >
              <p style={{ fontSize: 14 }}> {t("header.pages.privacyPolicy")}</p>
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Footer;
