import React, { useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "../common/header.module.css";
import { ExpandCircleDown } from "@mui/icons-material";
import SidebarFilter from "./SidebarFilter";

interface Category {
  id: number;
  name: string;
}

interface Subcategory {
  id: number;
  name: string;
  categoryId: number;
}

const ProductNavbar: React.FC<any> = ({
  handleSubcategorySelect,
  handleCategorySelection,
  handleMainCategorySelect,
  subcategories,
  categories,
  mainCategories,
}) => {
  const [currentSubcategories, setCurrentSubcategories] = useState<any | null>(
    null
  );
  const [currentCategories, setCurrentCategories] = useState<any | null>(null);
  const [showEmptyMessage, setShowEmptyMessage] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuCloseTimeout, setMenuCloseTimeout] =
    useState<NodeJS.Timeout | null>(null);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [mainDrawerOpen, setMainDrawerOpen] = useState(false);
  const [mobileSubcategoryDrawerOpen, setMobileSubcategoryDrawerOpen] =
    useState(false);
  const [categoryName, setCategoryName] = useState<string>("");
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);



  const toggleMobileDrawer = (open: boolean) => {
    setMobileDrawerOpen(open);
  };

  // Handle category click for mobile drawer
  const handleCategoryClick = (categoryId: number, categoryName: string) => {
    setCategoryName(categoryName);
    setMobileDrawerOpen(true); // Open the main mobile drawer when category is clicked
  };

  // Toggle sidebar filter visibility
  const toggleSidebarFilter = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const filterSubcategories = (categoryId: number) => {
    const filteredSubcategories = mainCategories?.filter(
      (sub: any) => sub.id === categoryId
    );

    const sortedSubcategories =
      filteredSubcategories.length > 0
        ? filteredSubcategories[0].Categories.sort(
            (a: any, b: any) => a.id - b.id
          )
        : null;

    setCurrentSubcategories(sortedSubcategories);

    setShowEmptyMessage(filteredSubcategories.length === 0);
  };

  const filteredSubcategories = (categoryId: number) => {
    if (currentCategories) {
      const filteredSubcategories = currentCategories?.filter(
        (sub: any) => sub.id === categoryId
      );

      const sortedSubcategories =
        filteredSubcategories[0]?.Subcategories.length > 0
          ? filteredSubcategories[0]?.Subcategories.sort(
              (a: any, b: any) => a.id - b.id
            )
          : null;

      setCurrentSubcategories(sortedSubcategories);

      setShowEmptyMessage(filteredSubcategories.length === 0);
    }
  };
  const filtercategories = (categoryId: number) => {
    const filteredcategories = mainCategories.filter(
      (sub: any) => sub.id === categoryId
    );
    const sortedcategories =
      filteredcategories[0]?.Categories.length > 0
        ? filteredcategories[0]?.Categories.sort(
            (a: any, b: any) => a.id - b.id
          )
        : null;

    setCurrentCategories(sortedcategories);
 
    setShowEmptyMessage(filteredcategories.length === 0);
  };

  // Handle desktop hover (when mouse enter)
  const handleHoverEnter = (categoryId: number) => {
    filterSubcategories(categoryId);

    // Cancel any pending close timeout
    if (menuCloseTimeout) {
      clearTimeout(menuCloseTimeout);
      setMenuCloseTimeout(null);
    }

    setMenuVisible(true); // Show the desktop menu
  };

  // desktop - Handle category mouse leave (when mouse leave)
  const handleMouseLeaveCategory = () => {
    const timeout = setTimeout(() => {
      setMenuVisible(false);
      setCurrentSubcategories(null);
      setShowEmptyMessage(false);
    }, 200); // Short delay, mouse to enter the menu

    setMenuCloseTimeout(timeout);
  };

  // desktop - Keep menu open when mouse enters the menu
  const handleMouseEnterMenu = () => {
    if (menuCloseTimeout) {
      clearTimeout(menuCloseTimeout);
      setMenuCloseTimeout(null);
    }
    setMenuVisible(true);
  };

  // desktop - Close menu when mouse leaves the menu
  const handleMouseLeaveMenu = () => {
    setMenuVisible(false);
    setCurrentSubcategories(null);
    setShowEmptyMessage(false);
  };



  const handleMainCategoryClick = (
    categoryId: number,
    categoryName: string
  ) => {
    setCategoryName(categoryName);
    filtercategories(categoryId);
    setMobileDrawerOpen(true);
  };

  // Handle subcategory selection for mobile drawer
  const handleSubcategoryClick = (
    subcategoryId: number,
    subcategoryName: string
  ) => {
    handleSubcategorySelect(subcategoryId, subcategoryName);
    setMobileSubcategoryDrawerOpen(false);
    setMobileDrawerOpen(false);
  };



  const toggleMobileDrawer2 = (open: boolean) => {
    setMainDrawerOpen(true);
    setMobileDrawerOpen(false);
  };
  const prevMainCategoryTitle = useRef("");

  return (
    <>
      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={toggleSidebarFilter} // Close the sidebar when clicking outside
        sx={{
          "& .MuiDrawer-paperAnchorLeft": {
            backgroundColor: "white !important",
          },
        }}
      >
        <Box sx={{ width: 280 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <div />
            <IconButton
              color="inherit"
              onClick={toggleSidebarFilter} // Close sidebar when clicking on close icon
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {/* Sidebar Filter */}
          <SidebarFilter
            categories={mainCategories}
            handleSubcategorySelect={handleSubcategorySelect}
          />
        </Box>
      </Drawer>
      <AppBar
        position="sticky"
        sx={{
          background:
            "linear-gradient(90deg, rgba(251, 176, 58) 0%, rgba(236, 32, 39, 1.0) 100%)",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", lg: "center" },
            alignItems: "center",
          }}
        >
          {/* mobile screen */}
          <Box
            sx={{
              display: { xs: "flex", lg: "none" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              marginLeft: 3,
              marginRight: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Categories1
            </Typography>
            <IconButton
              onClick={toggleSidebarFilter} // Open sidebar filter when expand icon is clicked
              sx={{ width: "25%" }}
            >
              <ExpandCircleDown sx={{ color: "black" }} />
            </IconButton>
          </Box>

          {/* desktop screen */}
          <Box
            sx={{
              display: { xs: "none", lg: "flex" },
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {mainCategories.map((category: any) => (
              <Button
                id={styles.headerButtonText}
                key={category.id}
                sx={{
                  fontFamily: "Outfit",
                  color: "black",
                  fontSize: "16px",
                  fontWeight: 400,
                  "&:hover": {
                    textDecoration: "underline",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
                onMouseEnter={() => handleHoverEnter(category.id)}
                onMouseLeave={handleMouseLeaveCategory}
                onClick={() => {
                  handleMainCategorySelect(category.id, category.name);
                  setMenuVisible(false);
                }}
              >
                {category.title}
              </Button>
            ))}
          </Box>
        </Toolbar>

        {/* desktop screen Subcategory Menu */}
        {menuVisible && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              maxWidth: "100vw",
              boxSizing: "border-box",
              backgroundColor: "white",
              position: "absolute",
              top: "64px",
              left: 0,
              padding: 2,
              boxShadow: 2,
            }}
            onMouseEnter={handleMouseEnterMenu}
            onMouseLeave={handleMouseLeaveMenu}
          >
            {showEmptyMessage ? (
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  color: "gray",
                  padding: 2,
                  fontWeight: 500,
                  fontSize: "18px",
                }}
              >
                No subcategories added yet.
              </Typography>
            ) : (
              <Grid
                container
                spacing={2}
                sx={{
                  maxWidth: "lg",
                  margin: "0 auto",
                }}
              >
                {currentSubcategories?.map((subcategory: any) => (
                  <Grid item xs={12} sm={6} md={3} key={subcategory.id}>
                    <Button
                      // fullWidth
                      onClick={() => {
                        handleCategorySelection(
                          subcategory.id,
                          subcategory.name
                        );

                        handleSubcategorySelect(
                          subcategory.id,
                          subcategory.name
                        );
                        setMenuVisible(false);
                      }}
                      sx={{
                        fontFamily: "Outfit",
                        fontSize: "15px",
                        fontWeight: 400,
                        padding: 1,
                        "&:hover": { backgroundColor: "#f0f0f0" },
                        textAlign: "center",
                        maxWidth: "300px",
                      }}
                    >
                      {subcategory.name}
                    </Button>
                    {subcategory.Subcategories?.map((subcategory1: any) => (
                      <Grid item xs={12} sm={6} md={4} key={subcategory1.id}>
                        <Button
                          onClick={() => {
                            handleSubcategorySelect(
                              subcategory1.id,
                              subcategory1.name
                            );
                            setMenuVisible(false);
                          }}
                          sx={{
                            fontFamily: "Outfit",
                            fontSize: "12px",
                            fontWeight: 300,
                            "&:hover": { backgroundColor: "#f0f0f0" },
                            textAlign: "center",
                            color: "#707072",
                            maxWidth: "200px",
                          }}
                        >
                          {subcategory1.name}
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        )}
      </AppBar>
      <Drawer
        anchor="left"
        open={mainDrawerOpen}
        onClose={() => toggleMobileDrawer(false)}
        sx={{
          "& .MuiDrawer-paperAnchorLeft": {
            backgroundColor: "white !important",
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <div />
            <IconButton
              color="inherit"
              onClick={() => toggleMobileDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Categories List */}
          <List sx={{ marginTop: 0, paddingTop: 0 }}>
            {mainCategories.map((category: any) => (
              <Box key={category.id}>
                <ListItem>
                  <ListItemButton
                    onClick={() =>
                      handleMainCategoryClick(category.id, category.title)
                    }
                  >
                    <ListItemText
                      primary={category.title}
                      primaryTypographyProps={{
                        color: "#FF5A00",
                        textAlign: "center",
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Box>
      </Drawer>
      {/* Mobile Categories Drawer */}
      <Drawer
        anchor="left"
        open={mobileDrawerOpen}
        onClose={() => toggleMobileDrawer2(false)}
        sx={{
          "& .MuiDrawer-paperAnchorLeft": {
            backgroundColor: "white !important",
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <div /> {/* Empty div for spacing */}
            <IconButton
              color="inherit"
              onClick={() => toggleMobileDrawer2(false)} // Close the drawer on click
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Categories List */}
          <List sx={{ marginTop: 0, paddingTop: 0 }}>
            {currentCategories?.map((category: any) => (
              <Box key={category.id}>
                <ListItem>
                  <ListItemButton
                    onClick={() =>
                      handleCategoryClick(category.id, category.name)
                    }
                  >
                    <ListItemText
                      primary={category.title}
                      secondary={category.name}
                      primaryTypographyProps={{
                        color: "#FF5A00",
                        textAlign: "center",
                      }}
                      secondaryTypographyProps={{
                        textAlign: "center",
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Mobile Subcategories Drawer */}
      <Drawer
        anchor="left"
        open={mobileSubcategoryDrawerOpen}
        onClick={() => setMobileSubcategoryDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paperAnchorLeft": {
            backgroundColor: "white !important",
          },
        }}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <IconButton
            color="inherit"
            onClick={() => {
              setMobileSubcategoryDrawerOpen(false);
              // toggleMobileDrawer(false);
            }}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            color="inherit"
            onClick={() => setMobileSubcategoryDrawerOpen(false)}
            sx={{ position: "absolute", top: 10, left: 10 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              marginTop: 7,
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "24px",
            }}
          >
            {categoryName}
          </Typography>
          <List sx={{ marginTop: 1 }}>
            {showEmptyMessage ? (
              <Typography sx={{ padding: 2, textAlign: "center" }}>
                No subcategories available.
              </Typography>
            ) : (
              currentSubcategories?.map((subcategory: any) => (
                <Box key={subcategory.id}>
                  <ListItem>
                    <ListItemButton
                      onClick={() =>
                        handleSubcategoryClick(subcategory.id, subcategory.name)
                      }
                    >
                      <ListItemText
                        primary={subcategory.name}
                        primaryTypographyProps={{
                          color: "#FF5A00",
                          textAlign: "center",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </Box>
              ))
            )}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default ProductNavbar;
