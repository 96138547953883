import React from "react";
import { Box, Typography, Container, Grid, Card, CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";



const TermsAndConditions = () => {
    const { t } = useTranslation() as { t: (key: string) => string };
    const termsData = {
      shopping: [
        {
          title: t("terms_and_conditions.Shopping.order_processing.title"),
          content: t("terms_and_conditions.Shopping.order_processing.content"),
        },
        {
          title: t("terms_and_conditions.Shopping.shipping.title"),
          content: t("terms_and_conditions.Shopping.shipping.content"),
        },
        {
          title: t("terms_and_conditions.Shopping.delivery.title"),
          content: t("terms_and_conditions.Shopping.delivery.content"),
        },
        {
          title: t("terms_and_conditions.Shopping.customs_duties.title"),
          content: t("terms_and_conditions.Shopping.customs_duties.content"),
        },
        {
          title: t("terms_and_conditions.Shopping.returns_exchanges.title"),
          content: t("terms_and_conditions.Shopping.returns_exchanges.content"),
        },
        {
          title: t("terms_and_conditions.Shopping.lost_stolen_packages.title"),
          content: t("terms_and_conditions.Shopping.lost_stolen_packages.content"),
        },
        {
          title: t("terms_and_conditions.Shopping.changes_shipping_info.title"),
          content: t("terms_and_conditions.Shopping.changes_shipping_info.content"),
        },
      ],
      community: [
        {
          title: t("terms_and_conditions.Community.user_conduct.title"),
          content: t("terms_and_conditions.Community.user_conduct.content"),
        },
        {
          title: t("terms_and_conditions.Community.group_creation_participation.title"),
          content: t("terms_and_conditions.Community.group_creation_participation.content"),
        },
        {
          title: t("terms_and_conditions.Community.posting_interaction.title"),
          content: t("terms_and_conditions.Community.posting_interaction.content"),
        },
        {
          title: t("terms_and_conditions.Community.joining_leaving_groups.title"),
          content: t("terms_and_conditions.Community.joining_leaving_groups.content"),
        },
        {
          title: t("terms_and_conditions.Community.account_suspension.title"),
          content: t("terms_and_conditions.Community.account_suspension.content"),
        },
      ],
    };
    
  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 5}}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h4" fontWeight="bold" fontFamily="Outfit">
        {t("terms_and_conditions.terms_and_conditions_lyfers")}
        </Typography>
        <Typography
          sx={{ lineHeight: 1.8, fontFamily: "Outfit", fontWeight: 400, fontSize: "18px", mt: 2 }}
        >
          {t("terms_and_conditions.Welcome_to_LYFERS")}
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {/* Shopping Policies */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h5" fontWeight="bold" fontFamily="Outfit" sx={{ mb: 2 }}>
            {t("terms_and_conditions.shopping_policies")}
            </Typography>
            {termsData.shopping.map((item, index) => (
              <Card key={index} sx={{ mb: 2, backgroundColor: "#f5f5f5" }}>
                <CardContent>
                  <Typography fontWeight="bold" fontSize="18px" fontFamily="Outfit">
                    {item.title}
                  </Typography>
                  <Typography sx={{ lineHeight: 1.8, fontFamily: "Outfit", fontSize: "16px" }}>
                    {item.content}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>

        {/* LYFERS Community Policies */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h5" fontWeight="bold" fontFamily="Outfit" sx={{ mb: 2 }}>
            {t("terms_and_conditions.LYFERS_Community_Policies")}
            </Typography>
            {termsData.community.map((item, index) => (
              <Card key={index} sx={{ mb: 2, backgroundColor: "#f5f5f5" }}>
                <CardContent>
                  <Typography fontWeight="bold" fontSize="18px" fontFamily="Outfit">
                    {item.title}
                  </Typography>
                  <Typography sx={{ lineHeight: 1.8, fontFamily: "Outfit", fontSize: "16px" }}>
                    {item.content}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ mt: 5, textAlign: "center" }}>
        <Typography fontWeight="bold" fontSize="20px" fontFamily="Outfit">
        {t("terms_and_conditions.Legal_Disclaimer")}
        </Typography>
        <Typography sx={{ lineHeight: 1.8, fontFamily: "Outfit", fontSize: "16px", mt: 2 }}>
        {t("terms_and_conditions.Legal_Disclaimer_desc")}
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
