import { all, fork } from "redux-saga/effects";
import authSaga from "./auth/saga"; 

import watchColorSagas from "./colors/colorSaga"
import watchSubCategorySagas from "./subCategories/subCategorySaga"
import watchProductSagas from "./products/productSaga";
import watchEventSagas from "./events/eventSaga";
import watchEventCategorySagas from "./eventCategories/eventCategorySaga";
import watchWishlistSagas from "./wishlist/wishlistSaga";
import watchCartSagas from "./cart/cartSaga";
import watchSubCategoryCategoriesSaga from "./subCategoryCategories/saga";
import watchSecurityQuestionsSaga from "./securityQuestions/saga";

import { notificationSaga } from "./notification/saga";
import watchCommunityGroupSaga from "./communityGroup/saga";
import watchCommunityPostSaga from "./communityPost/saga";
import watchCommunityGroupAndPostSaga from "./communityGroupAndPost/saga";
import watchGetCommunityPostsAuthorSaga from "./communityPostsAuthor/saga";

export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(watchColorSagas),
    fork(watchSubCategorySagas),
    fork(watchProductSagas),
    fork(watchEventSagas),
    fork(watchEventCategorySagas),
    fork(watchWishlistSagas),
    fork(watchCartSagas),
    fork(watchSubCategoryCategoriesSaga),
    fork(watchSecurityQuestionsSaga),
    fork(notificationSaga),
    fork(watchCommunityGroupSaga),
    fork(watchCommunityPostSaga),
    fork(watchCommunityGroupAndPostSaga),
    fork(watchGetCommunityPostsAuthorSaga),
  ]);
}
