import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
  Paper,
  Box,
  Checkbox,
  Button,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCartItemRequest,
  fetchCartRequest,
} from "../../store/cart/cartActions";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

interface Product {
  id: number;
  name: string;
  image: string;
  price: number;
  quantity: number;
  size: string;
  color: string;
  selected: boolean;
  productId: number;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
}

interface CartItemProduct {
  id: number;
  title: string;
  description: string;
  image: string;
  is_soldout: boolean;
  color: string | null;
  colors: { name: string; code: string }[];
  size: any;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
  price: number;
  quantity: number;
  subcategoryId: number;
  subcategory: {
    id: number;
    name: string;
  };
}

const CartPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();
  const buyItNowProductId = location?.state?.productId;

  const [cartItems, setCartItems] = useState<Product[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation() as { t: (key: string) => string };
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { cartItems: reduxCartItems } = useSelector(
    (state: any) => state?.cartReducer
  );

  useEffect(() => {
    dispatch(
      fetchCartRequest({
        userId: currentUser?.user.id,
        token: currentUser?.token,
        navigate,
        setLoading,
      })
    );
  }, []);

  useEffect(() => {
    if (reduxCartItems) {
      const uniqueItems = reduxCartItems.reduce((acc: any, item: any) => {
        const exists = acc.find(
          (prod: any) => prod.productId === item.Product.id
        );
        if (!exists) {
          let variantPrice = 0;
          try {
            if (typeof item?.variant === "string") {
              const parsedVariant = JSON.parse(item.variant);
              variantPrice = Number(parsedVariant?.price || 0);
            } else if (
              typeof item?.variant === "object" &&
              item.variant !== null
            ) {
              variantPrice = Number(item.variant?.price || 0);
            }
          } catch (error) {
            console.error("Error processing variant price:", error);
            variantPrice = 0;
          }

          acc.push({
            id: item.id,
            image:
              item.Product?.images[0]?.fullPath ??
              "https://via.placeholder.com/150",
            productId: item.Product.id,
            product: item.Product,
            name: item.Product.title,
            price: variantPrice + Number(item.Product.price),
            variant: item?.variant,
            quantity: item.quantity,
            size: item.size ?? "N/A",
            color: item.color || "N/A",
            designImageUrl: item.designImageUrl,
            backDesignImageUrl: item.backDesignImageUrl,

            selected: false,
          });
        }
        return acc;
      }, []);

      setCartItems(uniqueItems);
    }
  }, [reduxCartItems]);

  useEffect(() => {
    if (buyItNowProductId && !loading && cartItems.length > 0) {
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.productId === buyItNowProductId
            ? { ...item, selected: !item.selected }
            : item
        )
      );
    }
  }, [buyItNowProductId, reduxCartItems]);

  const handleIncrease = (id: number) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const handleDecrease = (id: number) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 }
          : item
      )
    );
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setCartItems((prevItems) =>
      prevItems.map((item) => ({ ...item, selected: checked }))
    );
  };

  const handleSelectItem = (id: number) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handleRemoveItem = (id: number) => {
    dispatch(
      deleteCartItemRequest({
        userId: currentUser.user?.id,
        productId: id,
        token: currentUser?.token,
        navigate,
      })
    );
  };

  // Calculate the subtotal
  const subtotal = cartItems
    .filter((item) => item.selected)
    .reduce((acc, item) => acc + item.price * item.quantity, 0);

  // Check if any items are selected
  const hasSelectedItems = cartItems.some((item) => item.selected);

  const handleContinueToPayment = () => {
    const selectedItems = cartItems.filter((item) => item.selected);
    if (selectedItems.length > 0) {
      let totalItems = 0;
      selectedItems.forEach((product) => {
        if (product.quantity) {
          totalItems = totalItems + product.quantity;
        }
      });

      navigate("/shippingInfo", {
        state: {
          cartItems: selectedItems,
          currentUser: currentUser,
          subtotal: subtotal,
          totalItems: totalItems,
        },
      });
    }
  };

  return (
    <Container maxWidth={"xl"}>
      <Grid
        container
        width="100%"
        direction="column"
        rowSpacing={2}
        flexShrink={0}
        sx={{ margin: "40px 0px" }}
      >
        <Grid item xs={12}>
          <Grid container direction="column" flexShrink={0}>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                marginBottom: { xs: "10px", sm: "15px", md: "20px" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Syne",
                  fontSize: { xs: "24px", sm: "24px", md: "32px" },
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "120%",
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                {t("cart")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {loading ? (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="body1">{t("loading")}...</Typography>
          </Grid>
        ) : (
          <>
            {reduxCartItems.length === 0 ? (
              <Grid item xs={12} display="flex" justifyContent="center">
                <Typography variant="body1">
                  {t("your_cart_is_empty")}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ margin: "40px 0px" }}>
                <Grid container direction={"row"} spacing={3}>
                  <Grid item xs={12} md={8}>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="none">
                              <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll}
                                inputProps={{ "aria-label": "select all" }}
                              />
                            </TableCell>
                            <TableCell sx={{ fontWeight: "bold", padding: 0 }}>
                              {t("product")}
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              {t("quantity")}
                            </TableCell>
                            <TableCell
                              sx={{ fontWeight: "bold", textAlign: "center" }}
                            >
                              {t("price")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cartItems.map((product) => (
                            <TableRow key={product.id}>
                              <TableCell padding="none">
                                <Checkbox
                                  checked={product.selected}
                                  onChange={() => handleSelectItem(product.id)}
                                  inputProps={{
                                    "aria-label": `select ${product.name}`,
                                  }}
                                />
                              </TableCell>
                              <TableCell sx={{ padding: 0 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", md: "row" }, // Column for mobile, row for larger screens
                                    alignItems: {
                                      xs: "center",
                                      md: "flex-start",
                                    }, // Center on mobile, align start on larger screens
                                    marginTop: { xs: "10px", md: "0" },
                                    textAlign: { xs: "center", md: "left" }, // Text alignment based on screen size
                                  }}
                                >
                                  {/* Image */}
                                  <Box
                                    component="img"
                                    src={product.image}
                                    alt={product.name}
                                    sx={{
                                      width: { xs: "15vw", md: "5vw" }, // 10vw for mobile, 5vw for larger screens
                                      height: "auto", // Maintain aspect ratio
                                      objectFit: "cover", // Ensure proper cropping
                                      marginBottom: { xs: "8px", md: "0" }, // Space for mobile only
                                      marginRight: { md: "16px" }, // Add spacing for larger screens
                                    }}
                                  />

                                  {/* Product Details */}
                                  <Box>
                                    {/* Product Name */}
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginBottom: "4px",
                                        maxWidth: "130px",
                                      }}
                                    >
                                      {product.name}
                                    </Typography>
                                    {/* Size and Color */}
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        padding: "4px 8px",
                                        backgroundColor: "#ffece2",
                                        borderRadius: 2,
                                      }}
                                    >
                                      <Typography
                                        variant="body2"
                                        sx={{ marginRight: 1 }}
                                      >
                                        {product.color}
                                      </Typography>
                                      <Box
                                        sx={{
                                          border: "1px solid",
                                          height: "15px",
                                          marginRight: 1,
                                        }}
                                      />
                                      <Typography variant="body2">
                                        {product.size}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </TableCell>

                              <TableCell>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <IconButton
                                      onClick={() => handleDecrease(product.id)}
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                    <Typography
                                      variant="body1"
                                      sx={{ marginX: 1 }}
                                    >
                                      {product.quantity}
                                    </Typography>
                                    <IconButton
                                      onClick={() => handleIncrease(product.id)}
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </Box>
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    sx={{
                                      marginTop: 1,
                                      textTransform: "capitalize",
                                    }}
                                    startIcon={<DeleteIcon />}
                                    onClick={() =>
                                      handleRemoveItem(product.productId)
                                    }
                                  >
                                    {t("removeButton")}
                                  </Button>
                                </Box>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    fontFamily: "Outfit",
                                    fontSize: {
                                      xs: "16px",
                                      sm: "22px",
                                      md: "22px",
                                    },
                                    fontStyle: "normal",
                                    lineHeight: "120%",
                                    textAlign: "center",
                                  }}
                                >
                                  ${" "}
                                  {(product.price * product.quantity).toFixed(
                                    2
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper
                      elevation={10}
                      sx={{
                        padding: { xs: 2, sm: 3, md: 4 },
                        backgroundColor: "#FFE7DB",
                        border: "1px solid",
                        borderRadius: "15px",
                      }}
                    >
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography variant="body1">
                            {t("your_order")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          container
                          justifyContent="flex-end"
                        ></Grid>
                        {/* Display the selected items with dynamic quantities */}
                        {cartItems
                          .filter((item) => item.selected)
                          .map((item) => (
                            <React.Fragment key={item.id}>
                              <Grid item xs={6} sm={6}>
                                <Box display={"flex"} flexDirection={"row"}>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{
                                      fontFamily: "Outfit",
                                      fontSize: { xs: "12px", sm: "24px" },
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "80%",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    gutterBottom
                                    sx={{
                                      fontFamily: "Outfit",
                                      fontSize: { xs: "12px", sm: "24px" },
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      maxWidth: "90%",
                                    }}
                                  >
                                    x {item.quantity}
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sm={6}
                                container
                                justifyContent="flex-end"
                              >
                                <Typography variant="body1">
                                  ${(item.price * item.quantity).toFixed(2)}
                                </Typography>
                              </Grid>
                            </React.Fragment>
                          ))}
                        <Grid item xs={6} sm={6}>
                          <Typography variant="body1">
                            {t("Subtotal")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          container
                          justifyContent="flex-end"
                        >
                          <Typography variant="body1">
                            ${subtotal.toFixed(2)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                          <Button
                            fullWidth
                            size="large"
                            variant="contained"
                            color="primary"
                            type="button"
                            disabled={!hasSelectedItems}
                            sx={{ padding: { xs: "10px", sm: "15px" } }}
                            onClick={() => handleContinueToPayment()}
                          >
                            {hasSelectedItems
                              ? t("continue_to_payment")
                              : t("select_items_to_process")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default CartPage;
