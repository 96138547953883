import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import baseUrl from "../../../config/apiConfig";
import ProductsComponent from "./ProductsComponent";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { handleApiError } from "../../common/Api-error-handler";
import { toast } from "react-toastify";
import { logout } from "../../../store/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsRequest } from "../../../store/products/productActions";

interface Product {
  id: number;
  title: string;
  description: string;
  variants: any;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
  price: number;
}

export default function Products() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const { products } = useSelector((state: any) => state?.productReducer);

  useEffect(() => {
    dispatch(fetchProductsRequest({ setLoading, navigate }));
  }, []);


  const filteredProducts = products.filter((product: Product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  // if (error) {
  //   return (
  //     <Grid
  //       container
  //       justifyContent="center"
  //       alignItems="center"
  //       height="100vh"
  //     >
  //       <Typography variant="h6" color="error">
  //         {error}
  //       </Typography>
  //     </Grid>
  //   );
  // }

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      <Grid item xs={12}>
        <Grid container direction="column" flexShrink={0}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
              marginRight:{xs:'0px', md:"35%"}
            }}
            mt={2}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: 1,
                color: "#000000",
                mt:{xs:1 , md:0 },
                display:'block'
              }}
            >
              All Products
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={"space-between"} marginBottom={3}>
          <Grid item alignItems={"center"} display={"flex"}>
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Outfit",
                fontSize: { xs: "20px" },
                fontWeight: "bold",
                display:{xs:'none', sm:'block', md:'block'},
                marginLeft:'10px'
              }}
            >
              All Products
            </Typography>
          </Grid>
          <Grid item display={"flex"} gap={2}>
            <TextField
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                padding: { xs: "10px", sm: "0px 15px" },
                textTransform: "capitalize",
                
              }}
              onClick={() => navigate("/admin/products/create")}
            >
              Add New Product
            </Button>
          </Grid>
        </Grid>
        <Grid container direction={"row"} spacing={3}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product: Product) => (
              <Grid item xs={12} sm={6} md={4} key={product.id}>
                <ProductsComponent product={product} />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" textAlign="center">
                No Products Found
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}