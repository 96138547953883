import React, { FC, useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { ReactComponent as LogoBlackSvg } from "../../assets/logos/LogoDefault.svg";
import styles from "./header.module.css";
import { Container, Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/actions";
import { getCartItemsCountRequest } from "../../store/cart/cartActions";
import { checkAuth } from "../../utils/authCheck";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Import the back icon
import LanguageSwitcher from "../../components/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import MobileLanguageSwitcher from "../../components/MobileLanguageSwitcher";

interface Props {}

const HomePage: FC<Props> = (props: Props) => {
  const { t } = useTranslation() as { t: (key: string) => string };

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const auth = useSelector((state: any) => state?.Auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const drawerWidth = 240;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { cartItems, cartItemsCount } = useSelector(
    (state: any) => state?.cartReducer
  );

  const pages = [
    { name: t("header.pages.home"), path: "/" },
    { name: t("header.pages.theSixpiller"), path: "/six-piller" },
    { name: t("header.pages.events"), path: "/events" },
    { name: t("header.pages.community"), path: "/community/posts" },
    { name: t("header.pages.becomeALyfer"), path: "/become-a-lyfer" },
    { name: t("header.pages.shop"), path: "/products" },
  ];

  useEffect(() => {
    if (auth?.currentUser) {
      dispatch(
        getCartItemsCountRequest({
          userId: auth?.currentUser?.user.id,
          token: auth?.currentUser?.token,
          navigate,
        })
      );
    }
  }, [cartItems]);

  const handleCartPage = () => {
    checkAuth(auth?.currentUser, () => {
      navigate("/cart");
    });
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDashboard = () => {
    if (auth.currentUser.user.user_type === "admin") {
      navigate("/admin/orders");
    } else {
      navigate("/dashboard");
    }
    handleClose();
  };
  const handleProfile = () => {
    if (auth.currentUser.user.user_type === "admin") {
      navigate("/admin/profile");
    } else {
      navigate("/profile");
    }

    handleClose();
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate("/");
  };
  const handleGoBack = () => {
    navigate(-1);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      height={"100%"}
      paddingY={2}
      sx={{ textAlign: "center", backgroundColor: "white" }}
    >
      <Stack spacing={3}>
        {pages.map((page) => (
          <React.Fragment key={page.name}>
            <Button
              onClick={() => navigate(page.path)}
              id={styles.headerButtonText}
              sx={{
                backgroundColor:
                  location.pathname === page.path ? "lightgray" : "transparent",
              }}
            >
              {page.name}
            </Button>
            <Divider variant="middle" />
          </React.Fragment>
        ))}

        {/* Add to Cart */}
        <Button
          onClick={() => navigate("/cart")}
          startIcon={<ShoppingCartCheckoutIcon />}
          sx={{
            backgroundColor: "transparent",
            textAlign: "center",
          }}
        >
          {t("header.cartbutton")}
        </Button>

        <Divider variant="middle" />

        {/* Conditional Authentication Options */}
        {auth.currentUser ? (
          <>
            <Button
              onClick={() => navigate("/dashboard")}
              sx={{
                backgroundColor: "transparent",
                textAlign: "center",
              }}
            >
              {t("header.pages.dashboard")}{" "}
            </Button>
            <Divider variant="middle" />

            <Button
              onClick={() => navigate("/profile")}
              sx={{
                backgroundColor: "white",
                borderRadius: 0,
                textAlign: "center",
              }}
            >
              {t("header.pages.profile")}{" "}
            </Button>
            <Divider variant="middle" />

            <Button
              onClick={handleLogout}
              sx={{
                backgroundColor: "white",
                borderRadius: 0,
                textAlign: "center",
              }}
            >
              {t("header.pages.logout")}{" "}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={() => navigate("/login")}
              sx={{
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              {t("header.pages.login")}{" "}
            </Button>
          </>
        )}
      </Stack>
    </Box>
  );

  return (
    <Box paddingBottom={window.location.href.includes("/products") ? 0 : 11}>
      <AppBar
        position={`${
          window.location.href.includes("/products") ? "static" : "fixed"
        }`}
      >
        <Toolbar disableGutters>
          <Grid container width="100%" display="flex">
            <Grid item xs={12}>
              <Box id={styles.headerBanner}>
                <Typography
                  id={styles.headerBannerTextNormal}
                  component="span"
                  sx={{
                    fontSize: { xs: "10px", sm: "12px", md: "16px" },
                  }}
                >
                  {t("header.topbarText")}{" "}
                  <Typography
                    id={styles.headerBannerTextClickable}
                    component="span"
                    sx={{
                      fontSize: { xs: "10px", sm: "12px", md: "16px" },
                    }}
                    onClick={() => navigate("/become-a-lyfer")}
                  >
                    {" "}
                    {t("header.clickHereButton")}{" "}
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Box sx={{display:'flex', justifyContent:"flex-end", alignItems:"center", width:"100%"}} >
            <Grid item xs={10} >
                <Container
                  maxWidth="lg"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Logo Section */}
                  <Grid item xs={6} sm={3} marginY={1}>
                    <Box
                      sx={{
                        height: { xs: 40, sm: 40, md: 50 },
                      }}
                    >
                      <Box
                        onClick={() => navigate("/")}
                        height="inherit"
                        component={LogoBlackSvg}
                      />
                    </Box>
                  </Grid>

                  {/* Navigation Buttons Section */}
                  <Grid
                    item
                    xs={0}
                    sm={6}
                    md={8}
                    display="flex"
                    justifyContent="center"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        lg: "flex",
                      },
                      gap: { xs: 1, sm: 1, md: 1 }, // Adjust gap to provide more spacing between page names
                    }}
                  >
                    <IconButton onClick={handleGoBack} sx={{ marginRight: 2 }}>
                      <ArrowBackIcon />
                    </IconButton>
                    {pages.map((page) => (
                      <Button
                        key={page.name}
                        onClick={() => navigate(page.path)}
                        id={styles.headerButtonText}
                        sx={{
                          fontFamily: "Outfit",
                          color:
                            location.pathname === page.path
                              ? "#FF5A00"
                              : "#000",
                          fontWeight:
                            location.pathname === page.path ? 700 : 500,
                          fontSize: { xs: "14px", whiteSpace: "nowrap" },
                        }}
                      >
                        {page.name}
                      </Button>
                    ))}
                  </Grid>

                  {/* Profile & Cart Section */}
                  <Grid
                    item
                    xs={12}
                    lg={4}
                    sx={{
                      display: {
                        xs: "none",
                        lg: "flex",
                      },
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    {auth.currentUser ? (
                      <>
                        <Avatar
                          onClick={handleClick}
                          sx={{ cursor: "pointer" }}
                        >
                          {auth.currentUser?.user?.firstName
                            ?.charAt(0)
                            ?.toUpperCase() || ""}
                        </Avatar>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handleDashboard}>
                            {t("header.pages.dashboard")}
                          </MenuItem>
                          <MenuItem onClick={handleProfile}>
                            {t("header.pages.profile")}
                          </MenuItem>
                          <MenuItem onClick={handleLogout}>
                            {t("header.pages.logout")}
                          </MenuItem>
                        </Menu>
                      </>
                    ) : (
                      <Button
                        onClick={() => navigate("/login")}
                        id={styles.headerButtonText}
                        sx={{
                          fontFamily: "Outfit",
                          minWidth: "110px",
                          padding: 0,
                        }}
                      >
                        {t("header.pages.login")}
                      </Button>
                    )}

                    <Button
                      size="large"
                      startIcon={<ShoppingCartCheckoutIcon />}
                      variant="contained"
                      id={styles.cartButton}
                      onClick={handleCartPage}
                      sx={{ position: "relative", minWidth: "150px" ,mr:2 }}
                    >
                      {t("header.cartbutton")}
                      <Badge
                        badgeContent={
                          cartItemsCount > 0 ? cartItemsCount : null
                        }
                        color="primary"
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        sx={{ position: "absolute", top: -3, right: 0 }}
                      />
                    </Button>

                    {auth.currentUser?.user?.user_type === "user" && (
                      <Button
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate("/dashboard/wishlist")}
                        startIcon={
                          <FavoriteBorderIcon
                            style={{ color: "primary", fontSize: 30 }}
                          />
                        }
                      />
                    )}
                  </Grid>

                  {/* Mobile Menu Section */}
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: {
                        xs: "flex",
                        lg: "none",
                      },
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2 }}
                    >
                      <MenuIcon sx={{ color: "black" }} />
                    </IconButton>
                    <MobileLanguageSwitcher />
                  </Grid>
                </Container>
              </Grid>
              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    ml: "auto",
                    mr: 1,
                    display: { xs: "none", sm: "none", md: "flex" },
                  }}
                >
                  <LanguageSwitcher />
                </Box>{" "}
              </Grid>
            </Box>
          </Grid>

          {/* Drawer for mobile screens */}
          <nav>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", lg: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
                "& .MuiDrawer-paperAnchorLeft": {
                  background: "white !important",
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default HomePage;
