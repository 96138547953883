import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

//get information of author for author posts page
export const getCommunityPostsAuthorRequest = (payload: dataTypes.getCommunityPostsAuthorPayload) => ({
    type: actionTypes.GET_COMMUNITY_POSTS_AUTHOR_REQUEST,
    payload,
});

export const getCommunityPostsAuthorSuccess = (data: dataTypes.User) => ({
    type: actionTypes.GET_COMMUNITY_POSTS_AUTHOR_SUCCESS,
    payload: data,
});

