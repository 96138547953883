import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import baseUrl from "../../config/apiConfig";
import axios from "axios";
import { handleApiError } from "../common/Api-error-handler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logout } from "../../store/auth/actions";
import { useTranslation } from "react-i18next";

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  qr_code: Text;
  points: number;
  UserReferralPoints: {
    id: number;
    referral_name: string;
    points: string;
  }[];
}

interface Testimonial {
  id: string;
  title: string;
  description: string;
  userId: number;
  image: string;
  createdAt: string;
  user: {
    id: number;
    firstName: string | null;
    lastName: string | null;
    email: string;
  };
}

function Dashboard() {
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const [users, setUsers] = useState<User | null>(null);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const { t } = useTranslation() as { t: (key: string) => string };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get<User>(
          `${baseUrl}/api/users/auth/userByIdWithPoints/${currentUser.user.id}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser?.token}`,
            },
          }
        );
        setUsers(response.data);
        const response1 = await axios.get(`${baseUrl}/api/users/testimonials`);
        setTestimonials(response1.data);
      } catch (error) {
        const { message, navigateTo } = handleApiError(error);
        toast.error(message);
        if (navigateTo) {
          if (navigateTo == "login") {
            dispatch(logout());
          }
          navigate(`/${navigateTo}`);
        }
      }
    };
    fetchUsers();
  }, []);

  const items = [
    {
      id: 1,
      title: "Satan Shirt x2",
      price: 50,
      color: "Black",
      size: "M",
      image: "/path/to/image.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pharetra finibus gravida. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque commodo finibus mi, non suscipit sem egestas eget sed consectetur.",
    },
    {
      id: 2,
      title: "Angel Shirt x1",
      price: 35,
      color: "White",
      size: "L",
      image: "/path/to/image2.jpg",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas pharetra finibus gravida. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque commodo finibus mi, non suscipit sem egestas eget sed consectetur.",
    },
  ];

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginBottom: "40px" }}
    >
      <Grid item xs={12}>
        <Grid container direction="column" flexShrink={0}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            sx={{
              padding: { xs: "8px", sm: "12px", md: "16px" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "24px", md: "32px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
                color: "#000000",
                marginLeft: { xs: 0, md: 20 },
              }}
            >
              {t("dashboard")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            padding: { xs: 1, sm: 2, md: 3 },
            borderRadius: 4,
            border: "1px solid",
          }}
        >
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            {" "}
            <img
              src={`data:image/png;base64,${users?.qr_code}`}
              alt="User QR Code"
              style={{ width: "200px", height: "200px" }}
            />
          </Box>

          <Typography variant="h6" fontFamily={"Outfit"} fontWeight="bold">
            {t("membership_points")}
          </Typography>
          <Box
            sx={{
              padding: 1,
              borderRadius: 3,
              border: "1px solid",
              borderColor: "grey.300",
              marginTop: 2,
            }}
          >
            <Typography variant="h5" color="primary" fontWeight="bold">
              {users?.points}
            </Typography>
          </Box>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            {t("qr_scan_details")}
          </Typography>

          {users?.UserReferralPoints.map((user, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: 1,
                borderRadius: 3,
                border: "1px solid",
                borderColor: "grey.300",
                marginTop: 1,
              }}
            >
              <Typography>{user?.referral_name}</Typography>
              <Typography color="primary" fontWeight="bold">
                {user?.points}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
