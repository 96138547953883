import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CombinedState } from "redux";
import { fetchNotificationsRequest, markNotificationAsRead } from "../../store/notification/actions";
import { Avatar, Divider, MenuItem, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface NotificationState {
  notifications: Notification[];
  unreadCount: number;
}

interface Notification {
  id: number;
  message: string;
  readStatus: boolean;
  createdAt: any;
  User: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export default function Notifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation() as { t: (key: string) => string };

  const notifications = useSelector(
    (state: CombinedState<{ Auth: any; Notification: NotificationState }>) =>
      state.Notification.notifications
  );

  const handleMarkAsRead = async (notificationId: number) => {
    dispatch(markNotificationAsRead(notificationId));
  };

  useEffect(() => {
    dispatch(fetchNotificationsRequest());
  }, [dispatch]);

  return (
    <>
    <Container maxWidth={"xl"} >
      {notifications?.length ? (
        <>
        <h1 style={{ display:'flex', justifyContent:'center', alignItems:'center'}} >
            {notifications.length} {t("notifications.notifications")}
        </h1>
          {/* <Divider /> */}
          {/* Display first 10 notifications */}
          {notifications.map((notification) => (
            <>
              <MenuItem
                key={notification.id}
                  onClick={() => handleMarkAsRead(notification.id)}
                sx={{
                  backgroundColor: notification.readStatus
                    ? "#f4f4f4"
                    : "#FFEBEB",
                  "&:hover": {
                    backgroundColor: notification.readStatus
                      ? "#f4f4f4"
                      : "#FFDDDD",
                  },
                  padding: { xs: "8px", sm: "15px" },
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  display={"flex"}
                  gap={{ xs: 1, md: 2 }}
                  alignItems={"center"}
                >
                  <Avatar>
                    {notification.User?.firstName?.charAt(0) ?? ""}
                  </Avatar>
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "start", md: "center" }}
                    gap={{ xs: 0, md: 2 }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontSize: {
                          xs: "12px",
                          md: "14px",
                        },
                      }}
                    >
                      {notification.User?.firstName ?? ""}{" "}
                      {notification.User?.lastName ?? ""} {notification.message}
                    </Typography>
                    <Box
                      sx={{
                        fontSize: { xs: "12px", md: "16px" },
                        pb: {},
                      }}
                    >
                      {(() => {
                        const createdAt: any = new Date(notification.createdAt);
                        const now: any = new Date();
                        const diffMs = now - createdAt;
                        const diffMinutes = Math.floor(diffMs / 60000);
                        const diffHours = Math.floor(diffMinutes / 60);

                        if (diffMinutes < 1) return "just now";
                        if (diffMinutes < 60)
                          return `${diffMinutes} minute${
                            diffMinutes > 1 ? "s" : ""
                          } ago`;
                        if (diffHours < 24)
                          return `${diffHours} hour${
                            diffHours > 1 ? "s" : ""
                          } ago`;

                        const diffDays = Math.floor(diffHours / 24);
                        return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
                      })()}
                    </Box>
                  </Box>
                </Box>

                <Box></Box>
              </MenuItem>
              <Divider />
            </>
          ))}
        </>
      ) : (
        <MenuItem>{t("notifications.no_new_notifications")}</MenuItem>
      )}
    </Container>
    </>
  );
}
