import * as actionTypes from "./colorActionTypes";
import * as dataTypes from "./colorTypes";

//for fetching colors
export const fetchColorRequest = (payload: dataTypes.FetchColorPayload) => ({
    type: actionTypes.FETCH_COLOR_REQUEST,
    payload: payload,
});

export const fetchColorSuccess = (data: dataTypes.Color[]) => ({
    type: actionTypes.FETCH_COLOR_SUCCESS,
    payload: data,
});


// for deleting color
export const deleteColorRequest = (payload: dataTypes.DeleteColorPayload) => ({
    type: actionTypes.DELETE_COLOR_REQUEST,
    payload,
});

export const deleteColorSuccess = (id: number) => ({
    type: actionTypes.DELETE_COLOR_SUCCESS,
    payload: { id }
});


// For Creating Color
export const createColorRequest = (payload: dataTypes.CreateColorPayload) => ({
    type: actionTypes.CREATE_COLOR_REQUEST,
    payload,
});

export const createColorSuccess = (newColor: dataTypes.Color) => ({
    type: actionTypes.CREATE_COLOR_SUCCESS,
    payload: { newColor }
});


// For Updating Color
export const updateColorRequest = (payload: dataTypes.UpdateColorPayload) => ({
    type: actionTypes.UPDATE_COLOR_REQUEST,
    payload,
});

export const updateColorSuccess = (currentColorId: number, updatedColor: dataTypes.Color) => ({
    type: actionTypes.UPDATE_COLOR_SUCCESS,
    payload: { currentColorId, updatedColor }
});