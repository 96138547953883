import * as actionTypes from "./wishlistActionTypes";
import * as dataTypes from "./wishlistDataTypes";

// For get wishlists
export const getWishlistsRequest = (payload: dataTypes.GetWishlistsPayload) => ({
    type: actionTypes.GET_WISHLISTS_REQUEST,
    payload,
});

export const getWishlistsSuccess = (wishlist: dataTypes.WishlistItem[] | null) => ({
    type: actionTypes.GET_WISHLISTS_SUCCESS,
    payload: wishlist
});


// For deleting wishlist
export const deleteWishlistRequest = (payload: dataTypes.DeleteWishlistPayload) => ({
    type: actionTypes.DELETE_WISHLIST_REQUEST,
    payload,
});

export const deleteWishlistSuccess = (productId: number) => ({
    type: actionTypes.DELETE_WISHLIST_SUCCESS,
    payload: { productId }
});


// For Creating Wishlist
export const createWishlistRequest = (payload: dataTypes.CreateWishlistPayload) => ({
    type: actionTypes.CREATE_WISHLIST_REQUEST,
    payload,
});

export const createWishlistSuccess = (newWishlist: dataTypes.WishlistItem) => ({
    type: actionTypes.CREATE_WISHLIST_SUCCESS,
    payload: { newWishlist }
});