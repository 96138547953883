import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotificationsRequest } from "../store/notification/actions";
import { CombinedState } from "redux";

interface Notification {
  id: string;
  message: string;
}

interface NotificationState {
  notifications: Notification[];
}

const NotificationComponent: React.FC = () => {
  const dispatch = useDispatch();

  const notifications = useSelector(
    (state: CombinedState<{ Auth: any; Notification: NotificationState }>) =>
      state.Notification.notifications
  );

  useEffect(() => {
    dispatch(fetchNotificationsRequest());
  }, [dispatch]);

  const handleAddNotification = () => {
    // logic to add a notification
  };

  return (
    <div>
      <button onClick={handleAddNotification}>Add Notification</button>
      <ul>
        {notifications.map((notification: Notification) => (
          <li key={notification.id}>{notification.message}</li>
        ))}
      </ul>
    </div>
  );
};

export default NotificationComponent;
