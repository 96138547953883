import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import * as dataTypes from "./dataTypes";
import * as actions from "./actions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";


function* fetchCommunityPosts(action: dataTypes.FetchCommunityPostsAction): Generator<any, void, any> {
    const { setLoading, navigate, token } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/posts`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        yield put(actions.fetchCommunityPostsSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* createCommunityPost(action: dataTypes.CreateCommunityPostAction): Generator<any, void, any> {
    const { createCommunityPostData, token, navigate } = action.payload;
    try {
        const response = yield call(axios.post, `${baseUrl}/api/community/posts`, createCommunityPostData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });

        const message = response.data.message || "community post created successfully.";
        toast.success(message);
        navigate("/community/posts");

    } catch (error: any) {
        const { message, navigateTo } = apiErrorHandler(error);
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* getCommunityPostDetail(action: dataTypes.GetCommunityPostDetailAction): Generator<any, void, any> {
    const { postId, setLoading, navigate, token } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/posts/${postId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        yield put(actions.getCommunityPostDetailSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* deleteCommunityPost(action: dataTypes.DeleteCommunityPostAction): Generator<any, void, any> {
    const { postId, token, navigate } = action.payload;
    try {

        const response = yield call(axios.delete, `${baseUrl}/api/community/posts/${postId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const message = response.data.message || "community post deleted successfully.";
        yield put(actions.deleteCommunityPostSuccess(postId));
        toast.success(message);

    } catch (error: any) {
        console.log("check error", error)
        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* deleteCommentInPost(action: dataTypes.DeleteCommentInPostAction): Generator<any, void, any> {
    const { commentId, token, navigate } = action.payload;
    try {

        const response = yield call(axios.delete, `${baseUrl}/api/community/comments/${commentId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const message = response.data.message || "comment deleted successfully.";
        yield put(actions.deleteCommentInPostSuccess(commentId));
        toast.success(message);

    } catch (error: any) {
        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* getCommunityPostsByAuthor(action: dataTypes.GetCommunityPostsByAuthorAction): Generator<any, void, any> {
    const { userId, setLoading, navigate, token } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/posts/user/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        yield put(actions.getCommunityPostsByAuthorSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}

export default function* watchCommunityPostSaga() {
    yield takeLatest(actionTypes.FETCH_COMMUNITY_POSTS_REQUEST, fetchCommunityPosts);
    yield takeLatest(actionTypes.CREATE_COMMUNITY_POST_REQUEST, createCommunityPost);
    yield takeLatest(actionTypes.GET_COMMUNITY_POST_DETAIL_REQUEST, getCommunityPostDetail);
    yield takeLatest(actionTypes.DELETE_COMMUNITY_POST_REQUEST, deleteCommunityPost);
    yield takeLatest(actionTypes.DELETE_COMMENT_IN_POST_REQUEST, deleteCommentInPost);
    yield takeLatest(actionTypes.GET_COMMUNITY_POSTS_BY_AUTHOR_REQUEST, getCommunityPostsByAuthor);
}


