import { produce } from "immer";
import * as types from "./subCategoryActionTypes";
import * as dataTypes from "./subCategoryTypes";

const initialState: dataTypes.InitialStateSubCategory = {
  subCategories: [],
};

const subCategoryReducer = produce((state, action) => {
  switch (action.type) {
    
    case types.FETCH_SUB_CATEGORY_SUCCESS:
      state.subCategories = action.payload;
      break;

    case types.DELETE_SUB_CATEGORY_SUCCESS:
      state.subCategories = state.subCategories.filter(
        (item) => item.id !== action.payload.id
      );
      break;

    case types.CREATE_SUB_CATEGORY_SUCCESS:
      state.subCategories = [
        ...state.subCategories,
        action.payload.newSubCategory,
      ];
      break;

    case types.UPDATE_SUB_CATEGORY_SUCCESS:
      state.subCategories = state.subCategories.map((item) =>
        item.id === action.payload.currentSubCategoryId
          ? action.payload.UpdatedSubCategory
          : item
      );
      break;

    default:
      break;
  }
}, initialState);

export default subCategoryReducer;
