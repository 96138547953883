import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Divider,
  Link,
  Button,
} from "@mui/material";
import { orange } from "@mui/material/colors";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface GroupCardProps {
  id: number;
  name: string;
  description: string;
  lastActivity: string;
  postsCount: number;
  image: string;
}

const MAX_LENGTH = 200;

const GroupCard: React.FC<GroupCardProps> = ({
  id,
  name,
  description,
  lastActivity,
  postsCount,
  image,
}) => {
  const navigate = useNavigate();
  const [isReadMore, setIsReadMore] = useState(false);
  const { t } = useTranslation() as { t: (key: string) => string };
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        overflow: "hidden",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#FFF7F2",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "200px",
        }}
      >
        {image ? (
          <img
            src={image}
            alt={name}
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              sx={{
                bgcolor: orange[500],
                width: 80,
                height: 80,
                fontSize: "32px",
              }}
            >
              {name.charAt(0).toUpperCase()}
            </Avatar>
          </Box>
        )}
      </Box>

      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            cursor: "pointer",
            textAlign: "center",
            mb: 1,
          }}
          onClick={() => navigate(`/community/posts/group/${id}`)}
        >
          {name}
        </Typography>

        <Divider sx={{ mb: 2, borderColor: orange[200] }} />

        <Typography
          variant="body1"
          color="text.secondary"
          paragraph
          sx={{ lineBreak: "anywhere" }}
        >
          {description}
          {description.length > MAX_LENGTH && (
            <Link
              component="button"
              variant="body2"
              onClick={toggleReadMore}
              sx={{ ml: 1, color: "primary.main", cursor: "pointer" }}
            >
              {isReadMore ? "Read Less" : "Read More"}
            </Link>
          )}
        </Typography>

        <Divider sx={{ my: 2, borderColor: orange[200] }} />

        <Box display="flex" justifyContent="space-between">
          <Typography variant="body2" color="textSecondary">
            <strong>Last Activity:</strong>{" "}
            {lastActivity ? dayjs(lastActivity).format("MMMM DD, YYYY") : "N/A"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>{postsCount}</strong> 
            {t("community.post")}
          </Typography>
        </Box>

        <Button
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            backgroundColor: "#FF5A00",
            "&:hover": {
              backgroundColor: orange[600],
            },
            textTransform:'capitalize'
          }}
          onClick={() => navigate(`/community/posts/group/${id}`)}
        >
          {t("community.visit_group")}
        </Button>
      </CardContent>
    </Card>
  );
};

export default GroupCard;
