import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "./actionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import * as dataTypes from "./dataTypes";
import * as actions from "./actions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";

function* fetchCommunityGroups(action: dataTypes.FetchCommunityGroupsAction): Generator<any, void, any> {
    const { setLoading, navigate, token } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/groups/allGroupListing`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        yield put(actions.fetchCommunityGroupsSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* fetchApprovedCommunityGroups(action: dataTypes.FetchApprovedCommunityGroupsAction): Generator<any, void, any> {
    const { setLoading, navigate } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/groups/allGroupListingForGroupPage`);
        yield put(actions.fetchApprovedCommunityGroupsSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* deleteCommunityGroup(action: dataTypes.DeleteCommunityGroupAction): Generator<any, void, any> {
    const { groupId, token, navigate, onClose } = action.payload;
    try {
        const response = yield call(axios.delete, `${baseUrl}/api/community/groups/${groupId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const message = response.data.message || "community group deleted successfully.";
        yield put(actions.deleteCommunityGroupSuccess(groupId));
        toast.success(message);

        if (onClose) onClose(); // Call the onClose callback function to close the dialog

    } catch (error: any) {
        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* createCommunityGroup(action: dataTypes.CreateCommunityGroupAction): Generator<any, void, any> {
    const { communityGroupData, token, navigate } = action.payload;
    try {
        const response = yield call(axios.post, `${baseUrl}/api/community/groups`, communityGroupData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        });

        const newCommunityGroup = response.data.newGroup;
        const message = response.data.message || "community group created successfully.";
        yield put(actions.createCommunityGroupSuccess(newCommunityGroup))
        toast.success(message);
        navigate("/community/groups");

    } catch (error: any) {
        const { message, navigateTo } = apiErrorHandler(error);
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* updateCommunityGroup(action: dataTypes.UpdateCommunityGroupAction): Generator<any, void, any> {
    const { groupId, payload, token, navigate } = action.payload;
    try {
        const response = yield call(axios.put, `${baseUrl}/api/community/groups/${groupId}`, payload, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const updatedCommunityGroup = response.data.group;
        const message = response.data.message || "community group updated successfully.";
        yield put(actions.updateCommunityGroupSuccess(groupId, updatedCommunityGroup))
        toast.success(message);

    } catch (error: any) {
        const { message, navigateTo } = apiErrorHandler(error);
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


function* fetchUserJoinedGroups(action: dataTypes.FetchUserJoinedGroupsAction): Generator<any, void, any> {
    const { userId, setLoading, navigate, token } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/groups/user/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

        yield put(actions.fetchUserJoinedGroupsSuccess(response.data))

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}


// for community group detail with user and check IsMember for group posts page
function* getCommunityGroupDetail(action: dataTypes.GetCommunityGroupDetailAction): Generator<any, void, any> {
    const { userId, groupId, setIsMember, setLoading, navigate, token } = action.payload;
    try {
        setLoading(true);

        const response = yield call(axios.get, `${baseUrl}/api/community/groups/${groupId}/user/${userId}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        setIsMember(response.data.isMember);

        setLoading(false);
    } catch (error: any) {
        setLoading(false);

        const { message, navigateTo } = apiErrorHandler(error)
        toast.error(React.createElement('pre', null, message));
        if (navigateTo) {
            if (navigateTo === "login") {
                yield put(logout());
            }
            if (navigateTo === "access-denied") {
                navigate(`/${navigateTo}`);
            }
        }
    }
}

export default function* watchCommunityGroupSaga() {
    yield takeLatest(actionTypes.FETCH_COMMUNITY_GROUPS_REQUEST, fetchCommunityGroups);
    yield takeLatest(actionTypes.FETCH_APPROVED_COMMUNITY_GROUPS_REQUEST, fetchApprovedCommunityGroups);
    yield takeLatest(actionTypes.DELETE_COMMUNITY_GROUP_REQUEST, deleteCommunityGroup);
    yield takeLatest(actionTypes.CREATE_COMMUNITY_GROUP_REQUEST, createCommunityGroup);
    yield takeLatest(actionTypes.UPDATE_COMMUNITY_GROUP_REQUEST, updateCommunityGroup);
    yield takeLatest(actionTypes.FETCH_USER_JOINED_GROUPS_REQUEST, fetchUserJoinedGroups);
    yield takeLatest(actionTypes.GET_COMMUNITY_GROUP_DETAIL_REQUEST, getCommunityGroupDetail);
}


