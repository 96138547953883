export const FETCH_COMMUNITY_POSTS_REQUEST = "fetch_community_posts_request";
export const FETCH_COMMUNITY_POSTS_SUCCESS = "fetch_community_posts_success";

export const CREATE_COMMUNITY_POST_REQUEST = "create_community_post_request";

export const GET_COMMUNITY_POST_DETAIL_REQUEST = "get_community_post_detail_request";
export const GET_COMMUNITY_POST_DETAIL_SUCCESS = "get_community_post_detail_success";

export const DELETE_COMMUNITY_POST_REQUEST = "delete_community_post_request";
export const DELETE_COMMUNITY_POST_SUCCESS = "delete_community_post_success";

export const DELETE_COMMENT_IN_POST_REQUEST = "delete_comment_in_post_request";
export const DELETE_COMMENT_IN_POST_SUCCESS = "delete_comment_in_post_success";

export const GET_COMMUNITY_POSTS_BY_AUTHOR_REQUEST = "get_community_posts_by_author_request";
export const GET_COMMUNITY_POSTS_BY_AUTHOR_SUCCESS = "get_community_posts_by_author_success";