import * as React from "react";
import Box from "@mui/material/Box";
import Header from "./Header"
import Footer from "./Footer";

interface Props {
  window?: () => Window;
  children: any;
}

export default function Layout(props: Props) {
  const { children } = props;
 

  return (
    <>
    <Box>
        <Header></Header>
    </Box>
    <Box sx={{minHeight:"75vh"}} >
        {children}
    </Box>
    <Box>
        <Footer ></Footer>
    </Box>
    
    </>
  );
}
