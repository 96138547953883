import React from "react";
import { Grid, Typography } from "@mui/material";
import love from "../../assets/images/Love1.webp";
import youthfull from "../../assets/images/Youthful.png";
import forgive from "../../assets/images/Forgiving.png";
import empower from "../../assets/images/Empower.webp";
import resillence from "../../assets/images/Resilence.png";
import strength from "../../assets/images/Strength.png";
import { Carousel } from "react-responsive-carousel";
import { useTranslation } from "react-i18next";

export default function MobileViewOfSixPiller() {
  const { t } = useTranslation() as { t: (key: string) => string };

  return (
    <>
      <Carousel
        autoPlay={true}
        showThumbs={false}
        swipeScrollTolerance={1}
        infiniteLoop={true}
        emulateTouch={true}
      >
        <Grid
          item
          xs={12}
          marginBottom="20px"
          sx={{
            backgroundColor: "#FFB892",
            borderRadius: "36px",
            padding: { xs: "18px", sm: "30px", md: "40px" },
          }}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                alignSelf="stretch"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "24px", sm: "30px", md: "40px" },
                  fontWeight: 700,
                  fontStyle: "normal",
                  lineHeight: "120%",
                }}
              >
                {t("sixPiller.sixPillersText.love.title")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "16px", sm: "20px", md: "24px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "120%",
                  textAlign: "justify",
                }}
              >
                {t("sixPiller.sixPillersText.love.description")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <img
                src={love}
                alt="Love"
                style={{
                  width: "100%",
                  height: "250px",

                  borderRadius: "30px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          marginBottom="20px"
          sx={{
            backgroundColor: "#FFA16D",
            borderRadius: "36px",
            padding: { xs: "20px", sm: "30px", md: "40px" },
          }}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="start"
            >
              <img
                src={youthfull}
                alt="Youthful"
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "30px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                alignSelf="stretch"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "24px", sm: "30px", md: "40px" },
                  fontWeight: 700,
                  fontStyle: "normal",
                  lineHeight: "120%",
                }}
              >
                {t("sixPiller.sixPillersText.yothfullness.title")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "16px", sm: "20px", md: "24px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "120%",
                  textAlign: "justify",
                }}
              >
                {t("sixPiller.sixPillersText.yothfullness.description")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          marginBottom="20px"
          sx={{
            backgroundColor: "#FF8949",
            borderRadius: "36px",
            padding: { xs: "20px", sm: "30px", md: "40px" },
          }}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "24px", sm: "30px", md: "40px" },
                  fontWeight: 700,
                  fontStyle: "normal",
                  lineHeight: "120%",
                }}
              >
                {t("sixPiller.sixPillersText.forgiveness.title")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "14px", sm: "20px", md: "24px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "120%",
                  textAlign: "justify",
                }}
              >
                {t("sixPiller.sixPillersText.forgiveness.description")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <img
                src={forgive}
                alt="Forgiving"
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "30px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          marginBottom="20px"
          sx={{
            backgroundColor: "#FAA217",
            borderRadius: "36px",
            padding: { xs: "20px", sm: "30px", md: "40px" },
          }}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="start"
            >
              <img
                src={empower}
                alt="Empowering"
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "30px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                alignSelf="stretch"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "24px", sm: "30px", md: "40px" },
                  fontWeight: 700,
                  fontStyle: "normal",
                  lineHeight: "120%",
                }}
              >
                {t("sixPiller.sixPillersText.empowering.title")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "16px", sm: "20px", md: "24px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "120%",
                  textAlign: "justify",
                }}
              >
                {t("sixPiller.sixPillersText.empowering.description")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          marginBottom="20px"
          sx={{
            backgroundColor: "#FCC061",
            borderRadius: "36px",
            padding: { xs: "20px", sm: "30px", md: "40px" },
          }}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                alignSelf="stretch"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "24px", sm: "30px", md: "40px" },
                  fontWeight: 700,
                  fontStyle: "normal",
                  lineHeight: "120%",
                }}
              >
                {t("sixPiller.sixPillersText.resilience.title")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "16px", sm: "20px", md: "24px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "120%",
                  textAlign: "justify",
                }}
              >
                {t("sixPiller.sixPillersText.resilience.description")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              <img
                src={resillence}
                alt="Resilient"
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "30px",
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          marginBottom="30px"
          sx={{
            backgroundColor: "#FDD089",
            borderRadius: "36px",
            padding: { xs: "20px", sm: "30px", md: "40px" },
          }}
        >
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={strength}
                alt="Strong"
                style={{
                  width: "100%",
                  height: "250px",
                  borderRadius: "30px",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Typography
                alignSelf="stretch"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "24px", sm: "30px", md: "40px" },
                  fontWeight: 700,
                  fontStyle: "normal",
                  lineHeight: "120%",
                }}
              >
                {t("sixPiller.sixPillersText.strength.title")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: { xs: "16px", sm: "20px", md: "24px" },
                  fontWeight: 400,
                  fontStyle: "normal",
                  lineHeight: "120%",
                  textAlign: "justify",
                }}
              >
                {t("sixPiller.sixPillersText.strength.description")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Carousel>
    </>
  );
}
