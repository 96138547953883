// notification/types.ts

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";

export interface Notification {
  id: number;
  userId: number;
  message: string;
  type: string;
  readStatus: boolean;
}

export interface NotificationState {
  notifications: Notification[];
  unreadCount: number;
  loading: boolean;
  error: string | null;
}

export interface FetchNotificationsRequest {
  type: typeof FETCH_NOTIFICATIONS_REQUEST;
}

export interface FetchNotificationsSuccess {
  type: typeof FETCH_NOTIFICATIONS_SUCCESS;
  payload: Notification[];
}

export interface FetchNotificationsFailure {
  type: typeof FETCH_NOTIFICATIONS_FAILURE;
  payload: string;
}

export interface AddNotification {
  type: typeof ADD_NOTIFICATION;
  payload: Notification;
}
export interface MarkNotificationAsRead {
  type: typeof MARK_NOTIFICATION_AS_READ;
  payload: number; // Ensure the payload is a number (notification ID)
}

export type NotificationActions =
  | FetchNotificationsRequest
  | FetchNotificationsSuccess
  | FetchNotificationsFailure
  | AddNotification
  | MarkNotificationAsRead;
