import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./productActionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";

import * as dataTypes from "./productTypes";
import * as actions from "./productActions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";

function* fetchProducts(action: dataTypes.FetchProductsAction): Generator<any, void, any> {
  const { setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/products`);
    yield put(actions.fetchProductsSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* getFeatureProducts(action: dataTypes.GetFeatureProductsAction): Generator<any, void, any> {
  const { setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/products/features`);
    yield put(actions.getFeatureProductsSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* getProductsByCategory(action: dataTypes.GetProductsByCategoryAction): Generator<any, void, any> {
  const { subcategoryId, token, setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/products/category/${subcategoryId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(actions.getProductsByCategorySuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* getRandomProducts(action: dataTypes.GetRandomProductsAction): Generator<any, void, any> {
  const { token, setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/products/random`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(actions.getRandomProductsSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* getProduct(action: dataTypes.GetProductAction): Generator<any, void, any> {
  const { productId, token, navigate, setLoading } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/products/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(actions.getProductSuccess(response.data))

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* deleteProduct(action: dataTypes.DeleteProductAction): Generator<any, void, any> {
  const { selectedProductId, token, navigate } = action.payload;
  try {
    const response = yield call(axios.delete, `${baseUrl}/api/shop/products/${selectedProductId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const message = response.data.message || "product deleted successfully.";
    yield put(actions.deleteProductSuccess(selectedProductId));
    toast.success(message);

  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* createProduct(action: dataTypes.CreateProductAction): Generator<any, void, any> {
  const { formData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.post, `${baseUrl}/api/shop/products`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    const newProduct = response.data.product;
    const message = response.data.message || "product created successfully.";

    yield put(actions.createProductSuccess(newProduct));
    toast.success(message);
    navigate("/admin/products");
  } catch (error: any) {

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

function* updateProduct(action: dataTypes.UpdateProductAction): Generator<any, void, any> {
  const { productId, formData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.put, `${baseUrl}/api/shop/products/${productId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });

    const updatedProduct = response.data.product;
    const message = response.data.message || "product updated successfully.";

    yield put(actions.updateProductSuccess(productId, updatedProduct));
    toast.success(message);
    navigate("/admin/products");
  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchProductSagas() {
  yield takeLatest(types.FETCH_PRODUCTS_REQUEST, fetchProducts);
  yield takeLatest(types.DELETE_PRODUCT_REQUEST, deleteProduct);
  yield takeLatest(types.CREATE_PRODUCT_REQUEST, createProduct);
  yield takeLatest(types.UPDATE_PRODUCT_REQUEST, updateProduct);
  yield takeLatest(types.GET_PRODUCT_REQUEST, getProduct);
  yield takeLatest(types.GET_PRODUCTS_BY_CATEGORY_REQUEST, getProductsByCategory);
  yield takeLatest(types.GET_RANDOM_PRODUCTS_REQUEST, getRandomProducts);
  yield takeLatest(types.GET_FEATURE_PRODUCTS_REQUEST, getFeatureProducts);
}
