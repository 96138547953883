import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getCommunityPostDetailRequest } from "../../store/communityPost/actions";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Image {
  id: number;
  url: string;
}

interface PostDetails {
  title: string;
  description: string;
  PostImages: Image[];
}

interface RootState {
  Auth: {
    currentUser: {
      token: string;
      user: {
        id: number;
      };
    };
  };
  communityPostReducer: {
    communityPostDetail: PostDetails;
  };
}

const MAX_IMAGES = 5;

export default function UpdatePostPage(): JSX.Element {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [existingImages, setExistingImages] = useState<Image[]>([]);
  const [postLoading, setPostLoading] = useState<boolean>(true);
  const [removedImageIds, setRemovedImageIds] = useState<number[]>([]);
  const { t } = useTranslation() as { t: (key: string) => string };
  const { postId } = useParams<{ postId: string }>();
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const postDetails = useSelector(
    (state: RootState) => state.communityPostReducer.communityPostDetail
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (postId) {
      dispatch(
        getCommunityPostDetailRequest({
          postId: Number(postId),
          setLoading: setPostLoading,
          navigate,
          token: currentUser?.token,
        })
      );
    }
  }, [postId, dispatch, currentUser, navigate]);

  useEffect(() => {
    if (postDetails) {
      setTitle(postDetails.title);
      setDescription(postDetails.description);
      setExistingImages(postDetails.PostImages);
    }
  }, [postDetails]);

  const handleImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const files = event.target.files;
    if (files) {
      const imagesArray = Array.from(files).filter((file) =>
        ["image/jpeg", "image/png", "image/gif"].includes(file.type)
      );
      if (
        selectedImages.length + existingImages.length + imagesArray.length >
        MAX_IMAGES
      ) {
        alert(`You can upload up to ${MAX_IMAGES} images.`);
        return;
      }
      setSelectedImages((prevImages) => [...prevImages, ...imagesArray]);
    }
  };

  const handleRemoveImage = (index: number): void => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleRemoveExistingImage = (id: number): void => {
    setExistingImages((prevImages) =>
      prevImages.filter((image) => image.id !== id)
    );
    setRemovedImageIds((prevRemovedIds) => [...prevRemovedIds, id]); // Track removed image IDs
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    if (!title) newErrors.title = t("community.posts.validation_error.title");
    if (!description) newErrors.description = t("community.posts.validation_error.description");
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    // Validate form fields
    if (validateForm()) {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      if (currentUser?.user?.id) {
        formData.append("userId", String(currentUser.user.id));
      }
      formData.append(
        "existingImageIds",
        JSON.stringify(existingImages.map((img) => img.id))
      );
      formData.append("removedImageIds", JSON.stringify(removedImageIds));

      // Append selected images to FormData
      selectedImages.forEach((image) => {
        formData.append("images", image);
      });

      try {
        // Make the API request to update the post
        const response = await axios.put(
          `${baseUrl}/api/community/posts/${postId}`, // Replace with your API endpoint
          formData,
          {
            headers: {
              Authorization: `Bearer ${currentUser?.token}`,
              "Content-Type": "multipart/form-data", // Make sure you're sending the FormData correctly
            },
          }
        );

        if (response.status === 200) {
          toast.success("Post updated successfully!");
          navigate(`/community/posts/${postId}`);
        }
      } catch (error) {
        toast.error("Error updating post");
        // Handle errors (e.g., show an error message)
      }
    }
  };

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: "0 0", sm: "0 0", md: "0 15%" }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginTop: "40px" }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: "Syne",
            fontSize: { xs: "20px", sm: "24px", md: "32px" },
            fontWeight: 700,
            color: "#000",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          {t("community.posts.update_post")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          borderRadius: 4,
          border: "1px solid",
          marginBottom: "60px",
          margin: 2,
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          padding={3}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                  fullWidth
                  label={t("community.posts.input_labels.title")}
                  value={title}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue.length <= 40) {
                      setTitle(inputValue);
                    }
                  }}
                  error={!!errors.title}
                  helperText={errors.title || `${title.length}/40`}
                  onFocus={() => setErrors({ ...errors, title: "" })}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("community.posts.input_labels.description")}
                multiline
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                error={!!errors.description}
                helperText={errors.description}
                onFocus={() => setErrors({ ...errors, description: "" })}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  justifyContent: "center",
                }}
              >
                {existingImages.map((image) => (
                  <Box
                    key={image.id}
                    sx={{
                      position: "relative",
                      width: "150px",
                      height: "150px",
                    }}
                  >
                    <Box
                      component="img"
                      src={image.url}
                      alt={`Existing Image ${image.id}`}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "8px",
                        boxShadow: 2,
                      }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => handleRemoveExistingImage(image.id)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
                {selectedImages.map((image, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      width: "150px",
                      height: "150px",
                    }}
                  >
                    <Box
                      component="img"
                      src={URL.createObjectURL(image)}
                      alt={`Image ${index + 1}`}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "8px",
                        boxShadow: 2,
                      }}
                    />
                    <IconButton
                      size="small"
                      sx={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                      }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button variant="outlined" component="label" fullWidth>
                {t("community.posts.upload_images")}
                <input
                  type="file"
                  hidden
                  multiple
                  accept="image/jpeg, image/png, image/gif"
                  onChange={handleImageUpload}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                sx={{ minWidth: "120px" }}
              >
                {t("community.posts.update")}
                </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
