import React from 'react';
import { call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { toast } from "react-toastify";
import { apiErrorHandler } from "../apiErrorHandler";
import { logout } from "../auth/actions";
import * as types from "./actionTypes";
import * as dataTypes from "./dataTypes";
import * as actions from "./actions";

function* fetchSubCategoryCategories(action: dataTypes.FetchSubCategoryCategoriesAction): Generator<any, void, any> {
  const { setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/subcategories/categories`);
    yield put(actions.fetchSubCategoryCategoriesSuccess(response.data));

    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchSubCategoryCategoriesSaga() {
  yield takeLatest(types.FETCH_SUBCATEGORY_CATEGORIES_REQUEST, fetchSubCategoryCategories);
}
