import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  Modal,
  IconButton,
} from "@mui/material";
import noImage from "../../assets/images/no-image.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import {
  createWishlistRequest,
  deleteWishlistRequest,
  getWishlistsRequest,
} from "../../store/wishlist/wishlistActions";
import {
  createCartItemRequest,
  deleteCartItemRequest,
} from "../../store/cart/cartActions";
import { checkAuth } from "../../utils/authCheck";

//change size to any beacuse its not string []
interface ProductProps {
  product: {
    id: number;
    title: string;
    description: string;
    image: string;
    is_soldout: boolean;
    variants: any;
    colors: { name: string; code: string }[];
    size: any;
    images: {
      fullPath: string;
      id: number;
      productId: number;
      image: string;
    }[];
    price: number;
  };
  userId: number;
}

interface WishlistItem {
  Product: {
    id: number;
    title: string;
    description: string;
    image: string;
    is_soldout: boolean;
    colors: { name: string; code: string }[];
    size: any;
    images: {
      fullPath: string;
      id: number;
      productId: number;
      image: string;
    }[];
    price: number;
    quantity: number;
    subcategoryId: number;
  };
  userId: number;
  id: number;
  productId: number;
  createdAt: string;
  updatedAt: string;
}

const ProductComponent: React.FC<ProductProps> = ({ product, userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFavorited, setIsFavorited] = useState(false);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [openLargeImageModel, setOpenLargeImageModel] =
    useState<boolean>(false);

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { wishlists } = useSelector((state: any) => state?.wishlistReducer);
  const { t } = useTranslation() as { t: (key: string) => string };

  useEffect(() => {
    const fetchWishlist = () => {
      if (currentUser) {
        dispatch(
          getWishlistsRequest({
            userId: currentUser.user?.id,
            token: currentUser?.token,
            setLoading,
            navigate,
          })
        );
      }
    };
    fetchWishlist();
  }, [userId, product.id]);

  useEffect(() => {
    if (currentUser) {
      if (wishlists) {
        const wishlistProductIds = wishlists?.map(
          (item: WishlistItem) => item.productId
        );
        if (wishlistProductIds.includes(product.id)) {
          setIsFavorited(true);
        }
      }
    }
  }, [wishlists]);

  // Handle adding/removing the product from the wishlist
  const handleFavoriteClick = () => {
    checkAuth(currentUser, () => {
      if (isFavorited) {
        // Remove product from wishlist
        dispatch(
          deleteWishlistRequest({
            userId,
            productId: product.id,
            token: currentUser?.token,
            navigate,
          })
        );
      } else {
        // Add product to wishlist
        const data = {
          userId,
          productId: product.id,
        };
        dispatch(
          createWishlistRequest({
            userId,
            productId: product.id,
            token: currentUser?.token,
            navigate,
          })
        );
      }
      setIsFavorited(!isFavorited);
    });
  };

  const handleRemoveFromCart = (pId: number) => {
    checkAuth(currentUser, () => {
      dispatch(
        deleteCartItemRequest({
          userId,
          productId: pId,
          token: currentUser?.token,
          navigate,
        })
      );
      setIsAddedToCart(false);
    });
  };

  const handleViewDetail = () => {
    checkAuth(currentUser, () => {
      navigate(`/productDetail/${product.id}`);
    });
  };
  const showInlargedImage = () => {
    console.log("hello");
    setOpenLargeImageModel(true);
  };

  return (
    <Card
      sx={{
        borderRadius: "26px",
        position: "relative",
        width: { xs: "90%", sm: "70%", md: "100%" },
        margin: "0 auto",
      }}
    >
      <Box sx={{ position: "relative" }} onClick={showInlargedImage}>
        <CardMedia
          sx={{
            height: { xs: 120, sm: 270 },
            objectFit: "cover",
            backgroundSize: "contain",
            transition: "0.5s ease-in-out",
            ...(product.is_soldout && {
              filter: "grayscale(100%)",
            }),
          }}
          image={product.images?.[0]?.fullPath ?? noImage}
          title={product.title}
        />

        {/* Sold Out Overlay */}
        {product.is_soldout && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              color: "white",
              fontSize: { xs: "18px", sm: "24px" }, // Smaller font size for mobile
              fontWeight: "bold",
              textTransform: "uppercase",
              transition: "background-color 0.3s ease",
            }}
          >
            {t("shop.soldOut")}
          </Box>
        )}
      </Box>

      {/* Favorite Icon */}
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: 60, sm: 100 },
          right: 17,
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "5px 5px 0px 5px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleFavoriteClick}
      >
        {isFavorited ? (
          <FavoriteIcon style={{ color: "red", fontSize: 28, marginTop: 1 }} />
        ) : (
          <FavoriteBorderIcon
            style={{ color: "black", fontSize: 28, marginTop: 0 }}
          />
        )}
      </Box>

      <Box sx={{ padding: { xs: "2px 8px", sm: "12px 20px" } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row", md: "row" },
            justifyContent: {
              xs: "flex-start",
              sm: "space-between",
              md: "space-between",
            },
            alignItems: {
              xs: "flex-start",
              sm: "space-between",
              md: "space-between",
            },
            overflow: "hidden",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontFamily: "Outfit",
              fontSize: { xs: "10px", sm: "16px" },
              fontWeight: 600,
              lineHeight: "28px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: { xs: "60%", sm: "48%", md: "70%" },
            }}
          >
            {product.title}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              fontFamily: "Syne",
              fontSize: { xs: "12px", sm: "24px" },
              fontWeight: 700,
              lineHeight: "28px",
              color: "#FF5A00",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            ${" "}
            {(
              Number(product.price) +
              (product?.variants ? Number(product?.variants[0]?.price) : 0)
            ).toFixed(2)}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", marginTop: "8px" }}>
          <Typography
            color="text.secondary"
            sx={{
              fontFamily: "Outfit",
              fontSize: "12px",
              color: "#FBB03A",
              cursor: "pointer",
            }}
            onClick={handleViewDetail}
          >
            {t("view_detail_button")}
          </Typography>
          <ArrowRightAltIcon
            style={{
              color: "#FBB03A",
              fontSize: 16,
              marginTop: "4px",
            }}
          />
        </Box>
      </Box>
      <Modal
        open={openLargeImageModel}
        onClose={() => {
          setOpenLargeImageModel(false);
        }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            width: 400,
            maxWidth: 600,
            margin: 4,
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 3,
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => {
              setOpenLargeImageModel(false);
            }}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              backgroundColor: "white",
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            id="image"
            src={product.images?.[0]?.fullPath ?? noImage}
            alt="larger image"
            style={{ maxWidth: "100%" }}
          />
        </Box>
      </Modal>
    </Card>
  );
};

export default ProductComponent;
