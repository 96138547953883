import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import noImage from "../../../assets/images/no-image.png";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteProductRequest } from "../../../store/products/productActions";

interface ProductProps {
  product: {
    id: number;
    title: string;
    description: string;
    variants: any;
    images: {
      fullPath: string;
      id: number;
      productId: number;
      image: string;
    }[];
    price: number;
  };
}
const ProductsComponent: React.FC<ProductProps> = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number | null>(
    null
  );
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);

  const handleDeleteClick = (productId: number) => {
    setSelectedProductId(productId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmDelete = () => {
    if (selectedProductId !== null) {
      dispatch(
        deleteProductRequest({
          selectedProductId,
          token: currentUser?.token,
          navigate,
        })
      );
      setOpenDialog(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: {
            xs: "15px",
            sm: "20px",
            md: "36px",
          },
          height: {
            xs: 300, // Mobile screen height
            sm: 350, // iPad and larger screens height
            md: 330,
          },
        }}
      >
        <Box sx={{ position: "relative" }}>
          <CardMedia
            sx={{
              height: 200,
            }}
            image={product.images?.[0]?.fullPath ?? noImage}
            title={product.title}
          />
        </Box>
        <Box sx={{ padding: "12px 20px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Outfit",
                fontSize: 18,
                fontWeight: 600,
                lineHeight: "28px",
                maxWidth: "70%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {product.title.length > 20
                ? `${product.title.slice(0, 20)}...`
                : product.title}
            </Typography>
            <Typography
              color="text.secondary"
              sx={{
                fontFamily: "Syne",
                fontSize: {
                  sx: 20,
                  sm: 20,
                  md: 20,
                },
                fontWeight: 700,
                lineHeight: "30px",
                color: "#FF5A00",
              }}
            >
              ${" "}
              {(
                Number(product.price) +
                (product?.variants ? Number(product?.variants[0]?.price) : 0)
              ).toFixed(2)}{" "}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box
              sx={{
                display: "flex",
                marginTop: {
                  xs: "1px",
                  sm: "4px",
                  md: "8px",
                },
              }}
            >
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: {
                    xs: "10px",
                    sm: "14px",
                    md: "18px",
                  },
                  color: "#FBB03A",
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/admin/products/edit/${product.id}`)}
              >
                Edit
              </Typography>
              <ArrowRightAltIcon
                sx={{
                  color: "#FBB03A",
                  fontSize: {
                    xs: "14px",
                    sm: "18px",
                    md: "20px",
                  },
                  marginTop: {
                    xs: "2px",
                    sm: "0px",
                    md: "4px",
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                marginTop: {
                  xs: "1px",
                  sm: "4px",
                  md: "8px",
                },
              }}
            >
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "Outfit",
                  fontSize: {
                    xs: "10px",
                    sm: "14px",
                    md: "18px",
                  },
                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => handleDeleteClick(product.id)}
              >
                Delete
              </Typography>
              <ArrowRightAltIcon
                sx={{
                  color: "red",
                  fontSize: {
                    xs: "14px",
                    sm: "18px",
                    md: "20px",
                  },
                  marginTop: {
                    xs: "2px",
                    sm: "0px",
                    md: "4px",
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Card>
      {/* Confirmation Modal */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          textAlign="center"
          fontWeight="bold"
          sx={{
            backgroundColor: "#FBB03A",
            color: "black",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            padding: "16px",
          }}
        >
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" mt={2}>
            Are you sure you want to delete this product? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "16px" }}>
          <Button
            onClick={handleCloseDialog}
            color="primary"
            sx={{
              backgroundColor: "#cccccc",
              color: "#000",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#b3b3b3",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={confirmDelete}
            color="secondary"
            autoFocus
            sx={{
              backgroundColor: "#d32f2f",
              color: "white",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: "#c62828",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductsComponent;
