import {
  Box,
  Button,
  Typography,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import productsCover from "../../assets/images/conver.jpeg";
import ProductComponent from "./ProductComponent";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProductsRequest } from "../../store/products/productActions";
import { fetchSubCategoryRequest } from "../../store/subCategories/subCategoryActions";
import ProductNavbar from "./ProductNavbar";
import { fetchSubCategoryCategoriesRequest } from "../../store/subCategoryCategories/actions";
import SidebarFilter from "./SidebarFilter";
import { useTranslation } from "react-i18next";

interface Product {
  id: number;
  title: string;
  description: string;
  image: string;
  colors: { name: string; code: string }[];
  size: string[];
  is_soldout: boolean;
  variants: any;
  images: {
    fullPath: string;
    id: number;
    productId: number;
    image: string;
  }[];
  price: number;
  subcategoryId: number;
  categoryId: number;
  mianCategoryId: number;
}

interface Category {
  id: number;
  name: string;
  title: string;
}

function ProductsPage() {
  const { t } = useTranslation() as { t: (key: string) => string };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [mainCategories, setMainCategories] = useState<any[]>([]);

  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<number | null>(
    null
  );
  const [selectedMainCategory, setSelectedMainCategory] = useState<
    number | null
  >(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState<
    string | null
  >(null);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<string>("");

  const [productsLoading, productsSetLoading] = useState<boolean>(true);
  const [subcategoryLoading, subcategorySetLoading] = useState<boolean>(true);
  const [subCategoryCategoriesLoading, subCategoryCategoriesSetLoading] =
    useState<boolean>(true);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { products } = useSelector((state: any) => state?.productReducer);
  const { subCategories: categories } = useSelector(
    (state: any) => state?.subCategoryReducer
  );
  const { subCategoryCategories } = useSelector(
    (state: any) => state?.subCategoryCategoriesReducer
  );

  useEffect(() => {
    dispatch(
      fetchProductsRequest({ setLoading: productsSetLoading, navigate })
    );
    dispatch(
      fetchSubCategoryRequest({ setLoading: subcategorySetLoading, navigate })
    );
    dispatch(
      fetchSubCategoryCategoriesRequest({
        setLoading: subCategoryCategoriesSetLoading,
        navigate,
      })
    );
  }, []);

  type Category = {
    id: number;
    title: string;
    Categories: {
      id: number;
      name: string;
      categoryId?: number;
      printfull_cat_id?: number;
      parent_id?: number;
      image_url?: string;
      catalog_position?: number;
      size?: string;
      createdAt?: string;
      updatedAt?: string;
      Subcategories?: {
        id: number;
        name: string;
      }[];
    }[];
  };

  type FormattedCategory = {
    id: number;
    title: string;
    Categories: {
      id: number;
      name: string;
      categoryId?: number;
      printfull_cat_id?: number;
      parent_id?: number;
      image_url?: string;
      catalog_position?: number;
      size?: string;
      createdAt?: string;
      updatedAt?: string;
      Subcategories?: {
        id: number;
        name: string;
      }[];
    }[];
  };

  const formatApiResponse = (apiResponse: Category[]): FormattedCategory[] => {
    const requiredCategories = [
      "Men's clothing",
      "Women's clothing",
      "Kids clothing",
      "Home & living",
      "Hats",
    ];
    const requiredSubcategories = [
      "T-shirts",
      "Hoodies",
      "Sweatshirts",
      "Jackets",
      "Water bottles",
      "Crop tops",
      "Mugs",
      "Beanies",
      "Trucker hats",
      "Snapbacks",
      "Dad hats / baseball caps",
      "Beanies",
      "Bucket hats",
      "Hoodies & sweatshirts",
    ];

    const temp = apiResponse
      .filter((category) => requiredCategories.includes(category.title))
      .map((category) => ({
        ...category,
        Categories: category.Categories.map((cat) => ({
          ...cat,
          Subcategories:
            cat.Subcategories?.filter((sub) =>
              requiredSubcategories.includes(sub.name)
            ) || [],
        }))
          // Remove categories with empty subcategories, except "Jackets & vests"
          .filter(
            (cat) =>
              cat.Subcategories.length > 0 ||
              cat.name.includes("Jackets & vests") ||
              cat.name === "Hoodies & sweatshirts"
          ),
      }));

    console.log("temp", temp);

    const filteredArray = temp.map((mainCatContainer) => {
      const temp = { ...mainCatContainer };
      temp.Categories = [];
      mainCatContainer.Categories.forEach((subCat) => {
        // console.log(" subCat.name", subCat.name);
        if (
          !subCat.name.includes("Jackets & vests") &&
          subCat.name !== "Hoodies & sweatshirts"
        ) {
          subCat.Subcategories.forEach((lastCat) => {
            temp.Categories.push(lastCat as any);
          });
        } else {
          temp.Categories.push(subCat);
        }
      });
      return temp;
    });

    // merging hats and mugs with in gifts and accessories :
    const hatsIndex = filteredArray.findIndex(
      (element) => element.title === "Hats"
    );
    const homeIndex = filteredArray.findIndex(
      (element) => element.title === "Home & living"
    );

    filteredArray.push({
      Categories: [
        ...filteredArray[homeIndex].Categories,
        ...filteredArray[hatsIndex].Categories,
      ],
      id: 999,
      title: "Gifts & Accessories",
    });
    return filteredArray.filter(
      (element, index) => index !== homeIndex && index !== hatsIndex
    );
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/shop/subcategories/mainCategories`,
          {}
        );
        console.log("main cats", response);
        // removing un-used main cats :

        console.log("main cats2", formatApiResponse(response.data));

        setMainCategories(formatApiResponse(response.data));
        // setMainCategories(response.data);
      } catch (error) {}
    };

    fetchPosts();
  }, []);

  if (productsLoading || subcategoryLoading || subCategoryCategoriesLoading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }
  let filteredProducts: any = [];

  if (selectedCategory) {
    filteredProducts = products.filter(
      (product: Product) => product.subcategoryId === selectedCategory
    );
  } else if (selectedSubCategory) {
    filteredProducts = products.filter(
      (product: Product) => product.categoryId === selectedSubCategory
    );
  } else if (selectedMainCategory) {
    filteredProducts = products.filter(
      (product: Product) => product.mianCategoryId === selectedMainCategory
    );
  } else {
    filteredProducts = products; // If no category or subcategory is selected, return all products.
  }

  // Function to handle sorting
  const sortedProducts = () => {
    switch (sortOrder) {
      case "priceLowToHigh":
        console.log(
          "1",
          [...filteredProducts].sort(
            (a, b) =>
              Number(a.price) +
              (a?.variants ? Number(a?.variants[0]?.price) : 0) -
              Number(b.price) +
              (b?.variants ? Number(b?.variants[0]?.price) : 0)
          )
        );
        return [...filteredProducts].sort(
          (a, b) =>
            Number(a.price) +
            (a?.variants ? Number(a?.variants[0]?.price) : 0) -
            (Number(b.price) +
              (b?.variants ? Number(b?.variants[0]?.price) : 0))
        );
      case "priceHighToLow":
        console.log(
          "2",
          [...filteredProducts].sort(
            (a, b) =>
              Number(b.price) +
              (b?.variants ? Number(b?.variants[0]?.price) : 0) -
              Number(a.price) +
              (a?.variants ? Number(a?.variants[0]?.price) : 0)
          )
        );
        return [...filteredProducts].sort(
          (a, b) =>
            Number(b.price) +
            (b?.variants ? Number(b?.variants[0]?.price) : 0) -
            (Number(a.price) +
              (a?.variants ? Number(a?.variants[0]?.price) : 0))
        );
      case "nameAtoZ":
        return [...filteredProducts].sort((a, b) =>
          a.title.localeCompare(b.title)
        );
      case "nameZtoA":
        return [...filteredProducts].sort((a, b) =>
          b.title.localeCompare(a.title)
        );
      default:
        return filteredProducts;
    }
  };

  const handleCategorySelect = (
    categoryId: number | null,
    categoryName: string
  ) => {
    setSelectedCategory(categoryId);
    setSelectedCategoryName(categoryName);
    // setSidebarOpen(false);
  };

  const clearFilter = () => {
    setSelectedCategory(null);
    setSelectedCategoryName(null);
    setSelectedSubCategory(null);
    setSelectedMainCategory(null);
  };

  const handleSubcategorySelect = (
    categoryId: number | null,
    categoryName: string
  ) => {
    setSelectedCategory(categoryId);
    setSelectedCategoryName(categoryName);
    setSelectedSubCategory(null);
    setSelectedMainCategory(null);
    setSidebarOpen(false);
  };

  const handleMainCategorySelect = (
    categoryId: number | null,
    categoryName: string
  ) => {
    setSelectedMainCategory(categoryId);
    setSelectedCategoryName(categoryName);
    setSelectedSubCategory(null);
    setSelectedCategory(null);
  };

  const handleCategorySelection = (
    categoryId: number | null,
    categoryName: string
  ) => {
    setSelectedSubCategory(categoryId);
    setSelectedCategoryName(categoryName);
    setSelectedCategory(null);
    setSelectedMainCategory(null);
  };

  return (
    <>
      <ProductNavbar
        handleSubcategorySelect={handleSubcategorySelect}
        handleCategorySelection={handleCategorySelection}
        handleMainCategorySelect={handleMainCategorySelect}
        subcategories={categories}
        categories={subCategoryCategories}
        mainCategories={mainCategories}
      />

      <Grid
        item
        xs={9.2}
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexShrink={0}
        mb={2}
      >
        <Box style={{ borderRadius: "0px" }}>
          <Carousel
            autoPlay={false}
            showThumbs={false}
            swipeScrollTolerance={1}
            infiniteLoop={true}
            emulateTouch={true}
          >
            <img
              src={productsCover}
              alt="Event"
              style={{ objectFit: "cover", borderRadius: "0px" }}
            />
            <img
              src={productsCover}
              alt="Event"
              style={{ objectFit: "cover", borderRadius: "0px" }}
            />
            <img
              src={productsCover}
              alt="Event"
              style={{ objectFit: "cover", borderRadius: "0px" }}
            />
          </Carousel>
        </Box>
      </Grid>
      {/* Sidebar Filter */}
      <Drawer
        anchor="left"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        sx={{
          "& .MuiDrawer-paperAnchorLeft": {
            backgroundColor: "white !important",
          },
        }}
      >
        <Box sx={{ width: 350, padding: 2 }}>
          <Typography textAlign={"center"} variant="h6">
            {t("shop.filters.Filter_by_Category")}
          </Typography>
          <List>
            <ListItem button onClick={() => handleCategorySelect(null, "All")}>
              <ListItemText
                sx={{ textAlign: "center" }}
                primary="All Products"
              />
            </ListItem>
          </List>
          <SidebarFilter
            categories={mainCategories}
            handleSubcategorySelect={handleSubcategorySelect}
          />
        </Box>
      </Drawer>

      <Container maxWidth={"lg"} sx={{ mt: { sx: 4, lg: 8 } }}>
        <Grid container direction="column" rowSpacing={2} flexShrink={0}>
          <Grid item xs={12}>
            <Grid container direction="column" flexShrink={0}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ marginBottom: { xs: "10px", sm: "15px", md: "20px" } }}
              >
                <Typography
                  sx={{
                    fontFamily: "Syne",
                    fontSize: { xs: "16px", sm: "24px", md: "32px" },
                    fontWeight: 700,
                    lineHeight: "120%",
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {t("shop.Buy_LYFERS_Merchandise")}
                </Typography>
              </Grid>
              <Grid item  xs={12}>
                {/* Filters Button with Selected Category Name */}
                <Box display="flex" alignItems="center" sx={{ margin: 2 }} gap={2}>
                  <Button
                    variant="outlined"
                    onClick={() => setSidebarOpen(true)}
                  >
                    {t("filters")}
                  </Button>
                  {selectedCategoryName && (
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ marginLeft: 2 }}
                    >
                      <Typography variant="body1">
                        {selectedCategoryName}
                      </Typography>
                      <IconButton onClick={clearFilter}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  )}
                  {/* Sorting Dropdown */}
                  <Select
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                    displayEmpty
                    sx={{ marginLeft: "auto", minWidth: 100 }}
                  >
                    <MenuItem value="" disabled>
                      {t("sorting")}
                    </MenuItem>
                    <MenuItem value="priceLowToHigh">
                      {t("shop.filters.Price_Low_to_High")}
                    </MenuItem>
                    <MenuItem value="priceHighToLow">
                      {t("shop.filters.Price_High_to_Low")}
                    </MenuItem>
                    <MenuItem value="nameAtoZ">
                      {" "}
                      {t("shop.filters.Name_A_to_Z")}
                    </MenuItem>
                    <MenuItem value="nameZtoA">
                      {" "}
                      {t("shop.filters.Name_Z_to_A")}
                    </MenuItem>
                  </Select>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Products Grid */}
          <Grid item xs={12} sx={{ marginBottom: "80px" }}>
            <Grid container direction={"row"} spacing={4}>
              {sortedProducts().length > 0 ? (
                sortedProducts().map((product: Product) => (
                  <Grid item xs={6} sm={6} md={4} key={product.id}>
                    <ProductComponent
                      product={product}
                      userId={currentUser?.user?.id ?? 0}
                    />
                  </Grid>
                ))
              ) : (
                <Typography
                  variant="h6"
                  sx={{ width: "100%", textAlign: "center" }}
                >
                  {t("no_records_found")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default ProductsPage;
