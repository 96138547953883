import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import GroupCard from "./GroupCard";
import PopularGroups from "./PopularGroups";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import Masonry from "@mui/lab/Masonry";
import {
  fetchApprovedCommunityGroupsRequest,
  fetchUserJoinedGroupsRequest,
} from "../../store/communityGroup/actions";
import { checkAuth } from "../../utils/authCheck";
import { useTranslation } from "react-i18next";

// Define the types
interface Group {
  id: number;
  name: string;
  description: string;
  lastActivity: string;
  postsCount: number;
  image: string;
  user: any;
}

const GroupPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [alphabetFilter, setAlphabetFilter] = useState("");
  const [sortOption, setSortOption] = useState("");
  const [approvedGroupsloading, setApprovedGroupsLoading] = useState(true);
  const [userJoinedGroupsloading, setUserJoinedGroupsLoading] = useState(false);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { t } = useTranslation() as { t: (key: string) => string };
  const { approvedCommunityGroups: groups, userJoinedGroups } = useSelector(
    (state: any) => state?.communityGroupReducer
  );

  useEffect(() => {
    const fetchGroups = () => {
      dispatch(
        fetchApprovedCommunityGroupsRequest({
          setLoading: setApprovedGroupsLoading,
          navigate,
        })
      );

      if (currentUser) {
        dispatch(
          fetchUserJoinedGroupsRequest({
            userId: currentUser?.user.id,
            setLoading: setUserJoinedGroupsLoading,
            navigate,
            token: currentUser?.token,
          })
        );
      }
    };

    fetchGroups();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setAlphabetFilter("");
    // setSelectedAlphabet(null);
  };

  const sortGroups = (groups: Group[], option: string) => {
    let sortedGroups = [...groups];

    switch (option) {
      case "Recent":
        sortedGroups.sort((a, b) => {
          return (
            new Date(b.lastActivity).getTime() -
            new Date(a.lastActivity).getTime()
          );
        });
        break;
      case "a - z":
        sortedGroups.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case "z - a":
        sortedGroups.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case "Popular":
        sortedGroups.sort((a, b) => b.postsCount - a.postsCount);
        break;
      default:
        break;
    }
    return sortedGroups;
  };

  // // Alphabet filter handler
  // const handleFilterChange = (letter: string) => {
  //   setSearchTerm("");
  //   if (selectedAlphabet === letter) {
  //     // If already selected, reset filters
  //     setSelectedAlphabet(null);
  //     setAlphabetFilter("");
  //   } else {
  //     // Set the selected alphabet
  //     setSelectedAlphabet(letter);
  //     setAlphabetFilter(letter);
  //   }
  // };

  // Filter logic based on search term and alphabet
  useEffect(() => {
    if (groups.length > 0) {
      let filtered = groups;

      // Filter by search term
      if (searchTerm) {
        filtered = filtered.filter((group: Group) =>
          group.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      // Filter by alphabet
      if (alphabetFilter) {
        filtered = filtered.filter((group: Group) =>
          group.name.toLowerCase().startsWith(alphabetFilter.toLowerCase())
        );
      }
      const sortedGroups = sortGroups(filtered, sortOption);

      setFilteredGroups(sortedGroups);
    }
  }, [searchTerm, alphabetFilter, groups, sortOption]);

  const handleCreateGroup = () => {
    checkAuth(currentUser, () => {
      navigate(`/community/group/create`);
    });
  };

  return (
    <Container maxWidth={"xl"}>
      <Box
        p={3}
        sx={{
          marginBottom: "40px",
        }}
      >
        {/* Title */}
        <Typography
          sx={{
            fontFamily: "Syne",
            fontSize: { xs: "20px", sm: "28px", md: "40px" },
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
          }}
          textAlign="center"
          mb={3}
        >
          {t("community.groups")}
        </Typography>

        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          gap={{ xs: 2, md: 0 }}
          paddingX={1}
          paddingY={{xs:10, sm: 0}}
        >
          <Box
            sx={{
              flex: { xs: 1, sm: "unset" },
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Select
              onChange={(e) => setSortOption(e.target.value)}
              value={sortOption}
              displayEmpty
              sx={{
                border: "1px solid",
                borderRadius: "10px",
                backgroundColor: "#FFE7DB",
                padding: "0px 40px",
                width: "100%",
              }}
            >
              <MenuItem value="" disabled>
                {t("filters")}
              </MenuItem>
              <MenuItem value="Recent">
                {t("community.filters.latest")}
              </MenuItem>
              {/* <MenuItem value="a - z">A - Z</MenuItem> */}
              <MenuItem value="z - a">
                {t("community.filters.Name_Z_to_A")}
              </MenuItem>
              <MenuItem value="Popular">
                {" "}
                {t("community.filters.popular")}{" "}
              </MenuItem>
            </Select>
          </Box>
        </Box>

        <Grid container spacing={4} mt={1}>
          <Grid item xs={12} md={8}>
            {" "}
            {/* Adjust this to span full width on small screens */}
            <Grid container>
              <Grid item md={12} width={"97%"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  paddingX={1}
                  width={"100%"}
                >
                  <Typography
                    sx={{
                      fontFamily: "Syne",
                      fontSize: { xs: "16px", sm: "24px", md: "24px" },
                      fontStyle: "normal",
                      fontWeight: 700,
                      lineHeight: "160%",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {t("community.group_directory")}
                  </Typography>
                </Box>
              </Grid>

              <Grid item md={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  flexWrap="wrap"
                  my={2}
                  sx={{
                    border: "1px solid ",
                    borderRadius: "25px",
                    backgroundColor: "#fff",
                    padding: "1rem",
                    minHeight: 0,
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent={{ xs: "center", sm: "space-between" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent={{ xs: "center", sm: "flex-end" }}
                      alignItems="center"
                      flexDirection="row"
                      sx={{ mb: { xs: 2, sm: 0 } }} // Add margin for spacing on mobile
                    >
                      <Typography
                        sx={{
                          fontFamily: "Syne",
                          fontSize: { xs: "20px", sm: "20px", md: "20px" },
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "120%",
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {t("community.all_groups")}
                      </Typography>
                    </Box>
                  </Grid>

                  {/* Group Cards*/}
                  {approvedGroupsloading || userJoinedGroupsloading ? (
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      height="100vh"
                    >
                      <CircularProgress />
                    </Grid>
                  ) : groups.length === 0 ? (
                    <Grid item xs={12}>
                      <Typography variant="h6" align="center" sx={{ mt: 2 }}>
                        {t("no_records_found")}
                      </Typography>
                    </Grid>
                  ) : (
                    <Masonry
                      columns={{ xs: 1, sm: 2, md: 2 }}
                      spacing={2}
                      sx={{ mt: 1 }}
                    >
                      {filteredGroups.map((group) => (
                        <GroupCard
                          key={group.id}
                          id={group.id}
                          name={group.name}
                          image={group.image}
                          description={group.description}
                          lastActivity={group.lastActivity}
                          postsCount={group.postsCount}
                        />
                      ))}
                    </Masonry>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                border: "1px solid",
                borderRadius: "25px",
                padding: "1rem",
              }}
            >
              <PopularGroups groups={currentUser ? userJoinedGroups : groups} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default GroupPage;
