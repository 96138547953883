import * as actionTypes from "./eventActionTypes";
import * as dataTypes from "./eventDataTypes";

// For fetching events
export const fetchEventsRequest = (payload: dataTypes.FetchEventsPayload) => ({
    type: actionTypes.FETCH_EVENTS_REQUEST,
    payload,

});

export const fetchEventsSuccess = (data: dataTypes.Event[]) => ({
    type: actionTypes.FETCH_EVENTS_SUCCESS,
    payload: data,
});


// For deleting event
export const deleteEventRequest = (payload: dataTypes.DeleteEventPayload) => ({
    type: actionTypes.DELETE_EVENT_REQUEST,
    payload,
});

export const deleteEventSuccess = (selectedEventId: number) => ({
    type: actionTypes.DELETE_EVENT_SUCCESS,
    payload: { selectedEventId }
});


// For Creating Event
export const createEventRequest = (payload: dataTypes.CreateEventPayload) => ({
    type: actionTypes.CREATE_EVENT_REQUEST,
    payload,
});

export const createEventSuccess = (newEvent: dataTypes.Event) => ({
    type: actionTypes.CREATE_EVENT_SUCCESS,
    payload: { newEvent }
});


// For Updating Event
export const updateEventRequest = (payload: dataTypes.UpdateEventPayload) => ({
    type: actionTypes.UPDATE_EVENT_REQUEST,
    payload,
});

export const updateEventSuccess = (eventId: number | string, updatedEvent: dataTypes.Event) => ({
    type: actionTypes.UPDATE_EVENT_SUCCESS,
    payload: { eventId, updatedEvent }
});


// For get event
export const getEventRequest = (payload: dataTypes.GetEventPayload) => ({
    type: actionTypes.GET_EVENT_REQUEST,
    payload,
});

export const getEventSuccess = (event: dataTypes.Event | null) => ({
    type: actionTypes.GET_EVENT_SUCCESS,
    payload: event,
});


// For get upcoming events
export const getUpcomingEventsRequest = (payload: dataTypes.GetUpcomingEventsPayload) => ({
    type: actionTypes.GET_UPCOMING_EVENTS_REQUEST,
    payload,
});

export const getUpcomingEventsSuccess = (data: dataTypes.Event[]) => ({
    type: actionTypes.GET_UPCOMING_EVENTS_SUCCESS,
    payload: data,
});