import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, CircularProgress } from "@mui/material";
import PostCard from "./PostCard";
import PopularGroups from "./PopularGroups";
import { Container } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GroupCard from "./GroupCard";
import { getCommunityGroupsAndPostsSearchRequest } from "../../store/communityGroupAndPost/actions";
import {
  fetchApprovedCommunityGroupsRequest,
  fetchUserJoinedGroupsRequest,
} from "../../store/communityGroup/actions";
import { useTranslation } from "react-i18next";

interface Group {
  id: number;
  name: string;
  description: string;
  lastActivity: string;
  postsCount: number;
  image: string;
}

interface Post {
  id: number;
  title: string;
  userId: string;
  description: string;
  createdAt: string;
  user: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
  Group: {
    id: number;
    name: string;
  };
  PostImages: any[];
  Comments: any[];
}

const GernalSearchPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { keyword } = useParams<{ keyword: string }>();
  const { t } = useTranslation() as { t: (key: string) => string };
  const [loading, setLoading] = useState(true);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const { communityGroupAndPost } = useSelector(
    (state: any) => state?.communityGroupAndPostReducer
  );

  const { approvedCommunityGroups, userJoinedGroups } = useSelector(
    (state: any) => state?.communityGroupReducer
  );

  // Fetch search data from API
  useEffect(() => {
    const fetchSearch = async () => {
      dispatch(
        getCommunityGroupsAndPostsSearchRequest({
          setLoading,
          navigate,
          keyword,
        })
      );

      if (currentUser) {
        dispatch(
          fetchUserJoinedGroupsRequest({
            userId: currentUser?.user.id,
            setLoading: setGroupsLoading,
            navigate,
            token: currentUser?.token,
          })
        );
      } else {
        dispatch(
          fetchApprovedCommunityGroupsRequest({
            setLoading: setGroupsLoading,
            navigate,
          })
        );
      }
    };
    fetchSearch();
  }, [keyword]);

  return (
    <>
      <Container
        maxWidth={"xl"}
        sx={{
          marginBottom: "40px",
        }}
      >
        <Grid
          container
          width="100%"
          padding={{ xs: 2, sm: 3, md: 4 }}
          flexShrink={0}
        >
          <Grid
            item
            xs={12}
            display="flex"
            sx={{
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Syne",
                fontSize: { xs: "20px", sm: "28px", md: "40px" },
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "120%",
              }}
            >
              {t("community.search_result")}
            </Typography>
          </Grid>
        </Grid>
        {loading || groupsLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "Outfit",
                  fontSize: {
                    xs: "16px",
                    sm: "20px",
                    md: "20px",
                    lg: "28px",
                    overflowWrap: "break-word",
                  },
                }}
              >
                {keyword}
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              md={8}
              sx={{
                gap: "25px",
                flexDirection: "column",
                justifyContent: "center",
                display: {
                  sm: "none",
                  xs: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "25px",
                  padding: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: {
                      xs: "16px",
                      sm: "20px",
                      md: "20px",
                      lg: "28px",
                      overflowWrap: "break-word",
                    },
                  }}
                >
                  {keyword} {t("community.groups")}
                </Typography>
                <Grid container spacing={2} mt={1}>
                  {communityGroupAndPost.groups.length > 0 ? (
                    communityGroupAndPost.groups.map((group: any) => (
                      <Grid item xs={12} md={6} key={group.id}>
                        <GroupCard
                          id={group.id}
                          name={group.name}
                          description={group.description}
                          lastActivity={group.lastActivity}
                          postsCount={group.postsCount}
                          image={group.image}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography textAlign={"center"}>
                        {t("community.no_posts_available")}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "25px",
                  padding: "1rem",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: {
                      xs: "16px",
                      sm: "20px",
                      md: "20px",
                      lg: "28px",
                      overflowWrap: "break-word",
                    },
                  }}
                >
                  {keyword} {t("community.post")}
                </Typography>
                {communityGroupAndPost.posts.length > 0 ? (
                  communityGroupAndPost.posts.map((post: any) => (
                    <PostCard key={post.id} post={post} showComments={false} />
                  ))
                ) : (
                  <Typography textAlign={"center"}>
                    {t("community.no_posts_available")}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  border: "1px solid",
                  borderRadius: "24px",
                  padding: "1rem",
                }}
              >
                <PopularGroups
                  groups={
                    currentUser ? userJoinedGroups : approvedCommunityGroups
                  }
                />
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default GernalSearchPage;
