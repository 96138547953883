import { produce } from "immer";
import * as types from "./colorActionTypes";
import * as dataTypes from "./colorTypes";

const initialState: dataTypes.InitialStatecolor = {
  colors: [],
};

const colorReducer = produce((state, action) => {
  switch (action.type) {

    case types.FETCH_COLOR_SUCCESS:
      state.colors = action.payload;
      break;

    case types.DELETE_COLOR_SUCCESS:
      state.colors = state.colors.filter(
        (item) => item.id !== action.payload.id
      );
      break;

    case types.CREATE_COLOR_SUCCESS:
      state.colors = [...state.colors, action.payload.newColor];
      break;

    case types.UPDATE_COLOR_SUCCESS:
      state.colors = state.colors.map((item) =>
        item.id === action.payload.currentColorId
          ? action.payload.updatedColor
          : item
      );
      break;

    default:
      break;
  }
}, initialState);

export default colorReducer;