import {useSelector } from "react-redux";

export function PublicRoute({ children }: any) {
  const currentUser = useSelector((state: any) => state?.Auth.currentUser);
  
  if (currentUser) {
    return children;
    // dispatch(changePath("/dashboard"))
    // return <Navigate to={"/dashboard"} replace />;
  } else {
    return children;
  }
}
