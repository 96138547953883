import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItem, Button, Menu, Box } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const MobileLanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    handleClose();
  };

  return (
    <Box>
      {/* Button styled like the design */}
      <Button
        variant="outlined"
        onClick={handleClick}
        sx={{
          borderColor: "#2b2b2b",
          color: "#2b2b2b",
          fontWeight: "bold",
          textTransform: "uppercase",
          borderRadius: "8px",
          padding: "6px 12px",
          display: "flex",
          alignItems: "center",
          gap: "4px",
        }}
      >
        {i18n.language.toUpperCase()}
        <ArrowDropDownIcon />
      </Button>

      {/* Dropdown Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        <MenuItem onClick={() => changeLanguage("en")}>🇺🇸 English</MenuItem>
        <MenuItem onClick={() => changeLanguage("ru")}>🇷🇺 Русский</MenuItem>
        <MenuItem onClick={() => changeLanguage("fr")}>🇫🇷 Français</MenuItem>
        <MenuItem onClick={() => changeLanguage("pt")}>🇵🇹 Português</MenuItem>
        <MenuItem onClick={() => changeLanguage("zh")}>🇨🇳 中文</MenuItem>
        <MenuItem onClick={() => changeLanguage("vi")}>🇻🇳 Tiếng Việt</MenuItem>
        <MenuItem onClick={() => changeLanguage("es")}>🇪🇸 Español</MenuItem>
        <MenuItem onClick={() => changeLanguage("ja")}>🇯🇵 日本語</MenuItem>
        <MenuItem onClick={() => changeLanguage("ko")}>🇰🇷 한국어</MenuItem>
      </Menu>
    </Box>
  );
};

export default MobileLanguageSwitcher;
