import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tabs,
  Tab,
  Avatar,
  Modal,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteCommunityGroupRequest,
  fetchCommunityGroupsRequest,
  updateCommunityGroupRequest,
} from "../../../store/communityGroup/actions";

interface Group {
  id: number;
  name: string;
  createdBy: string;
  description: string;
  reason: string;
  createdAt: string;
  status: string;
  user: any;
}

const AdminGroupPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false); // New state for status update confirmation
  const [statusFilter, setStatusFilter] = useState<string>("All");
  const [statusToUpdate, setStatusToUpdate] = useState<string | null>(null); // Track new status
  const [rejectionReason, setRejectionReason] = useState(""); // State for rejection reason
  const [groupDetailModalOpen, setGroupDetailModalOpen] = useState(false); // Group Detail Modal open state
  const [selectedGroupDetail, setSelectedGroupDetail] = useState<any | null>(
    null
  ); // Store selected group details

  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);

  const { communityGroup } = useSelector(
    (state: any) => state?.communityGroupReducer
  );

  useEffect(() => {
    dispatch(
      fetchCommunityGroupsRequest({
        setLoading,
        navigate,
        token: currentUser?.token,
      })
    );
  }, []);

  const filteredGroups = communityGroup.filter(
    (group: Group) =>
      (statusFilter === "All" || group.status === statusFilter) &&
      group.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setStatusFilter(newValue);
  };

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    groupId: number
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedGroupId(groupId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openStatusUpdateDialog = (currentStatus: string) => {
    setStatusToUpdate(currentStatus);
    setRejectionReason("");
    setOpenStatusDialog(true);
    handleMenuClose();
  };

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
    setRejectionReason("");
  };

  const approveGroup = async () => {
    if (selectedGroupId !== null && statusToUpdate) {
      const payload = {
        status: statusToUpdate,
        reason: statusToUpdate === "denied" ? rejectionReason : null,
      };

      dispatch(
        updateCommunityGroupRequest({
          groupId: selectedGroupId,
          payload,
          token: currentUser?.token,
          navigate,
        })
      );

      handleCloseStatusDialog();
    }
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
    handleMenuClose();
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleDeleteGroup = () => {
    if (selectedGroupId !== null) {
      dispatch(
        deleteCommunityGroupRequest({
          groupId: selectedGroupId,
          token: currentUser?.token,
          navigate,
          onClose: handleCloseConfirmationDialog,
        })
      );
      // handleCloseConfirmationDialog();
    }
  };

  // Open Modal with selected group details
  const handleGroupDetailModalOpen = (group: any) => {
    // !achorEl for action menu
    if (!anchorEl) {
      setSelectedGroupDetail(group);
      setGroupDetailModalOpen(true);
    }
  };

  // Close Modal of group details
  const handleGroupDetailModalClose = () => {
    setGroupDetailModalOpen(false);
    setSelectedGroupDetail(null);
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: 0, sm: 0, md: 4 }}
      rowSpacing={3}
      flexShrink={0}
    >
      <Grid item xs={12}>
        <Typography variant="h4" fontWeight="bold" sx={{ marginBottom: 3 }}>
          All Groups
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Tabs value={statusFilter} onChange={handleTabChange}>
            <Tab label="All" value="All" />
            <Tab label="Approved" value="approved" />
            <Tab label="Pending" value="pending" />
            <Tab label="Denied" value="denied" />
          </Tabs>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ whiteSpace: "nowrap" }}>
                <TableCell>Name</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>Group Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Date Created
                </TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroups.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography>No groups found</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                filteredGroups.map((group: Group) => (
                  <TableRow
                    key={group.id}
                    hover
                    onClick={() => handleGroupDetailModalOpen(group)} // Add onClick handler
                    sx={{ cursor: "pointer" }} // Show pointer cursor
                  >
                    {/* image & name */}
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        {/* Avatar image for show first character of user name */}
                        <Avatar
                          sx={{ bgcolor: "primary.main", marginRight: 2 }}
                        >
                          {group.user.firstName
                            ? group.user.firstName[0].toUpperCase()
                            : "X"}
                        </Avatar>
                        {/* Full Name */}
                        {group.user.firstName && group.user.lastName
                          ? `${group.user.firstName} ${group.user.lastName}`
                          : group.user.firstName}
                      </Box>
                    </TableCell>

                    {/* group name */}

                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {group.name}
                    </TableCell>

                    {/* group status */}

                    <TableCell>{group.status}</TableCell>

                    {/* group reason */}

                    <TableCell>
                      <Box
                        sx={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "",
                          WebkitLineClamp: 2,
                          lineBreak: "anywhere",
                        }}
                      >
                        {group.reason}
                      </Box>
                    </TableCell>

                    {/* Group date created */}

                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {new Date(group.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </TableCell>

                    {/* menu for approve, delete... */}

                    <TableCell align="right">
                      <IconButton onClick={(e) => handleMenuClick(e, group.id)}>
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl && selectedGroupId === group.id)}
                        onClose={handleMenuClose}
                      >
                        {group.status === "pending" && (
                          <>
                            <MenuItem
                              onClick={() => openStatusUpdateDialog("approved")}
                            >
                              Approve
                            </MenuItem>
                            <MenuItem
                              onClick={() => openStatusUpdateDialog("denied")}
                            >
                              Denied
                            </MenuItem>
                          </>
                        )}

                        <MenuItem onClick={handleOpenConfirmationDialog}>
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {/* Modal for group detail */}
      <Modal open={groupDetailModalOpen} onClose={handleGroupDetailModalClose}>
        <Box
          sx={{
            minWidth: "500px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: 3,
            boxShadow: 24,
            overflow: "hidden",
          }}
        >
          {selectedGroupDetail && (
            <>
              {/* Close Button */}
              <Box
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                }}
              >
                <IconButton
                  onClick={handleGroupDetailModalClose}
                  sx={{
                    color: "gray",
                    "&:hover": { color: "black" },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              {/* Avatar and Group Title */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 3,
                  padding: 2,
                  bgcolor: "lightgray",
                }}
              >
                <Avatar
                  src={selectedGroupDetail.image || undefined} // Use image if available, otherwise use first letter of group name
                  alt="Group"
                  sx={{
                    width: 84,
                    height: 84,
                    marginRight: 2,
                    fontSize: 32,
                    fontWeight: "fontWeight",
                    color: "black",
                  }}
                >
                  {!selectedGroupDetail.image &&
                    selectedGroupDetail.name?.charAt(0).toUpperCase()}
                </Avatar>

                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    textAlign: "center",
                    flexGrow: 1,
                  }}
                >
                  Group Details
                </Typography>
              </Box>

              <Grid container spacing={3} padding={2}>
                {/* Group Name */}
                <Grid item xs={1.8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "text.secondary" }}
                  >
                    Group Name :
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={selectedGroupDetail.name}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Group Status */}
                <Grid item xs={1.8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "text.secondary" }}
                  >
                    Status :
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={selectedGroupDetail.status}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Group Reason */}
                <Grid item xs={1.8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "text.secondary" }}
                  >
                    Reason :
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={selectedGroupDetail.reason}
                    multiline
                    maxRows={2}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                {/* Group Description */}
                <Grid item xs={1.8}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "text.secondary" }}
                  >
                    Description :
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    multiline
                    maxRows={3}
                    value={selectedGroupDetail.description}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>

      {/* Status Update Confirmation Dialog */}
      <Dialog open={openStatusDialog} onClose={handleCloseStatusDialog}>
        <DialogTitle>Confirm Status Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {statusToUpdate} this group?
          </DialogContentText>
          {statusToUpdate === "denied" && (
            <TextField
              label="Rejection Reason"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseStatusDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={approveGroup} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
      >
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this group? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteGroup} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AdminGroupPage;
