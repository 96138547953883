import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import { createCommunityGroupRequest } from "../../store/communityGroup/actions";
import { useTranslation } from "react-i18next";

export default function CreateGroupPage() {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const currentUser = useSelector((state: any) => state?.Auth?.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation() as { t: (key: string) => string };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!name.trim())
      newErrors.name = t("community.group.validation_error.name");
    if (!description.trim())
      newErrors.description = t("community.group.validation_error.description");
    if (!reason.trim())
      newErrors.reason = t("community.group.validation_error.reason");

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);

      // Create a preview URL for the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Grid>
    );
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("reason", reason);
      formData.append("userId", currentUser?.user?.id);

      if (imageFile) {
        formData.append("image", imageFile);
      }

      dispatch(
        createCommunityGroupRequest({
          communityGroupData: formData,
          token: currentUser?.token,
          navigate,
        })
      );
      // Reset form fields after submission (optional)
      setName("");
      setDescription("");
      setImageFile(null);
      setReason("");
      setLoading(false);
    }
  };

  const handleNameInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setName(event.target.value);
  };

  const handleReasonInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReason(event.target.value);
  };

  const handleDescriptionInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const isSubmitDisabled = !name || !description || !reason || loading;

  return (
    <Grid
      container
      width="100%"
      direction="column"
      padding={{ xs: "0 0", sm: "0 0", md: "0 15%" }}
      rowSpacing={3}
      flexShrink={0}
      sx={{ marginTop: "40px" }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: "Syne",
            fontSize: { xs: "20px", sm: "24px", md: "32px" },
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "120%",
            color: "#000000",
            textAlign: "center",
            marginBottom: "40px",
          }}
        >
          {t("community.group.create_new_group")}
        </Typography>
      </Grid>
      <Grid
        item
        xs={10}
        sx={{
          margin: "15px",
          borderRadius: 4,
          border: "1px solid",
          padding: 3,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          marginBottom: "400px",
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 3 }}>
          {t("community.group.new_group")}
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" alignItems="center" gap={2}>
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  borderRadius: "8px",
                  width: "130px",
                  height: "130px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                {imagePreview ? (
                  <img
                    src={imagePreview}
                    alt="Selected"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <>
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                      sx={{ width: "100%", height: "100%" }}
                    >
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                      />
                      <ImageIcon sx={{ fontSize: 40, color: "#ccc" }} />
                    </IconButton>
                  </>
                )}
              </Box>

              <TextField
                fullWidth
                label={t("community.group.input_labels.name")}
                value={name}
                onChange={handleNameInput}
                error={!!errors.name}
                helperText={errors.name || `${name.length}/20`}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("community.group.input_labels.reason")}
                value={reason}
                onChange={handleReasonInput}
                error={!!errors.reason}
                helperText={errors.reason || `${reason.length}/100`}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("community.group.input_labels.description")}
                multiline
                rows={3}
                value={description}
                onChange={handleDescriptionInput}
                error={!!errors.description}
                helperText={errors.description || `${description.length}/200`}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="warning"
                size="large"
                sx={{
                  borderRadius: "8px",
                  fontWeight: "bold",
                  paddingY: 1.5,
                  backgroundColor: "#ff6d00",
                  "&:hover": { backgroundColor: "#e65c00" },
                  textTransform: "capitalize",
                }}
                type="submit"
                disabled={isSubmitDisabled}
              >
                {t("submit_button")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
