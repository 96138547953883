import { produce } from "immer";
import * as types from "./actionTypes";
import * as dataTypes from "./dataTypes";

const initialState: dataTypes.InitialStateCommunityPost = {
  communityPosts: [],
  communityPostDetail: null,
  communityPostsByAuthor: [],
};

const communityPostReducer = produce((state, action) => {
  switch (action.type) {
    case types.FETCH_COMMUNITY_POSTS_SUCCESS:
      state.communityPosts = action.payload;
      break;

    case types.GET_COMMUNITY_POST_DETAIL_SUCCESS:
      state.communityPostDetail = action.payload;
      break;

    case types.DELETE_COMMUNITY_POST_SUCCESS:
      state.communityPosts = state.communityPosts.filter(
        (item) => item.id !== action.payload.postId
      );
      break;

    case types.DELETE_COMMENT_IN_POST_SUCCESS:
      if (state.communityPostDetail) {
        state.communityPostDetail.Comments =
          state.communityPostDetail.Comments.filter(
            (item) => item.id !== action.payload.commentId
          );
      }
      break;

    case types.GET_COMMUNITY_POSTS_BY_AUTHOR_SUCCESS:
      state.communityPostsByAuthor = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default communityPostReducer;
