import { produce } from "immer";
import * as types from "./actionTypes";
import * as dataTypes from "./dataTypes";

const initialState: dataTypes.InitialStateCommunityGroupAndPost = {
  communityGroupAndPost: [],
  communityGroupAndItsPosts: [],
  communityGroupMembers: [],
};

const communityGroupAndPostReducer = produce((state, action) => {
  switch (action.type) {

    case types.GET_COMMUNITY_GROUPS_AND_POSTS_SEARCH_SUCCESS:
      state.communityGroupAndPost = action.payload;
      break;

    case types.GET_COMMUNITY_GROUP_AND_ITS_POSTS_SUCCESS:
      state.communityGroupAndItsPosts = action.payload;
      break;

    case types.JOIN_COMMUNITY_GROUP_SUCCESS:
      state.communityGroupAndItsPosts.group.membersCount += 1;
      break;

    case types.LEAVE_COMMUNITY_GROUP_SUCCESS:
      const { userId } = action.payload;

      state.communityGroupAndItsPosts.group.membersCount -= 1;

      if (userId) {
        state.communityGroupMembers = state.communityGroupMembers.filter(
          (item) => item.id !== userId
        );
      }
      break;

    case types.GET_COMMUNITY_GROUP_MEMBERS_SUCCESS:
      state.communityGroupMembers = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default communityGroupAndPostReducer;
