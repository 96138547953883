import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./cartActionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";

import * as dataTypes from "./cartDataTypes";
import * as actions from "./cartActions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";


function* fetchCart(action: dataTypes.FetchCartAction): Generator<any, void, any> {
  const { userId, token, setLoading, navigate } = action.payload;
  try {
    setLoading(true);
    const response = yield call(axios.get, `${baseUrl}/api/orders/cart/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if(response.data){
      yield put(actions.fetchCartSuccess(response.data.CartItems))

    }
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* getCartItemsCount(action: dataTypes.GetCartItemsCountAction): Generator<any, void, any> {
  const { userId, token, navigate } = action.payload;
  try {
    const response = yield call(axios.get, `${baseUrl}/api/orders/cart/cartItemsCount/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(actions.getCartItemsCountSuccess(response.data.cartItemCount));
  } catch (error: any) {
    // toast.error("error in cart item count")
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

function* deleteCartItem(action: dataTypes.DeleteCartItemAction): Generator<any, void, any> {
  const { userId, productId, token, navigate } = action.payload;
  try {
    const response = yield call(axios.delete, `${baseUrl}/api/orders/cart/remove/${userId}/${productId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const message = response.data.message || "cart item deleted successfully.";
    yield put(actions.deleteCartItemSuccess(productId));
    toast.success(message);
  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

function* createCartItem(action: dataTypes.CreateCartItemAction): Generator<any, void, any> {
  const { itemData, token, navigate } = action.payload;
  try {

    //console.log("check item data in create cart item saga",itemData);
    const response = yield call(axios.post, `${baseUrl}/api/orders/cart/add`, itemData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

   // console.log("check cart in saga", response.data)
    const newCartItem = response.data.cartItem;
    const message = response.data.message || "cart item created successfully.";

    yield put(actions.createCartItemSuccess(newCartItem));
    toast.success(message);
  } catch (error: any) {
    //console.log("check error", error)
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        //console.log("yes logout")
      //  navigate(`/${navigateTo}`);
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchCartSagas() {
  yield takeLatest(types.FETCH_CART_REQUEST, fetchCart);
  yield takeLatest(types.DELETE_CART_ITEM_REQUEST, deleteCartItem);
  yield takeLatest(types.CREATE_CART_ITEM_REQUEST, createCartItem);
  yield takeLatest(types.GET_CART_ITEMS_COUNT_REQUEST, getCartItemsCount);
}
