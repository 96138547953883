import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Subcategory {
  id: number;
  name: string;
  categoryId: number;
  printfull_cat_id: number;
  parent_id: number;
  image_url: string;
}

interface Category {
  id: number;
  name: string;
  mainCategoryId: number;
  printfull_cat_id: number;
  parent_id: number;
  image_url: string;
  Subcategories: Subcategory[];
}

interface MainCategory {
  id: number;
  title: string;
  printfull_cat_id: number;
  parent_id: number;
  image_url: string;
  Categories: Category[];
}

interface Props {
  categories: MainCategory[];
  handleSubcategorySelect: (
    subcategoryId: number,
    categoryName: string
  ) => void;
}

const SidebarFilter: React.FC<Props> = ({
  categories,
  handleSubcategorySelect,
}) => {
  const [expandedMain, setExpandedMain] = useState<number | null>(null);
  const [expandedSub, setExpandedSub] = useState<number | null>(null);

  const handleMainCategoryClick = (categoryId: number) => {
    setExpandedMain(expandedMain === categoryId ? null : categoryId);
  };

  const handleSubcategoryClick = (subcategoryId: number) => {
    setExpandedSub(expandedSub === subcategoryId ? null : subcategoryId);
  };
  const handleSelectSubcategoryClick = (
    subcategoryId: number,
    categoryName: string
  ) => {
    handleSubcategorySelect(subcategoryId, categoryName);
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {categories.map((mainCategory) => (
        <Accordion
          key={mainCategory.id}
          expanded={expandedMain === mainCategory.id}
          onChange={() => handleMainCategoryClick(mainCategory.id)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{mainCategory.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List component="div" disablePadding>
              {mainCategory.Categories.map((category) => (
                <Accordion
                  key={category.id}
                  expanded={expandedSub === category.id}
                  onChange={() => handleSubcategoryClick(category.id)}
                >
                  <ListItemButton key={category.printfull_cat_id}>
                    <ListItemText
                      primary={category.name}
                      onClick={() =>
                        handleSelectSubcategoryClick(category.id, category.name)
                      }
                    />
                  </ListItemButton>
                </Accordion>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </List>
  );
};

export default SidebarFilter;
