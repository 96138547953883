import React from 'react';
import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./colorActionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import { toast } from "react-toastify";
import { apiErrorHandler } from "../apiErrorHandler";
import { logout } from "../auth/actions";
import * as dataTypes from "./colorTypes";
import * as actions from "./colorActions";


function* fetchColors(action: dataTypes.FetchColorAction): Generator<any, void, any> {
  const {setLoading, navigate} = action.payload;
  try {
    setLoading(true);
    const response = yield call(axios.get, `${baseUrl}/api/shop/colors`);
    yield put(actions.fetchColorSuccess(response.data))    
    setLoading(false);
  } catch (error: any) {
    setLoading(false);
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* deleteColor(action: dataTypes.DeleteColorAction ): Generator<any, void, any> {
  const { id, token, navigate } = action.payload;
  try {
    const response = yield call(axios.delete, `${baseUrl}/api/shop/colors/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const message = response.data.message || "color deleted successfully.";
    yield put(actions.deleteColorSuccess(id))    
    toast.success(message);
  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* createColor(action: dataTypes.CreateColorAction): Generator<any, void, any> {
  const { colorData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.post, `${baseUrl}/api/shop/colors`, colorData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const newColor = response.data.color;
    const message = response.data.message || "color created successfully.";
    yield put(actions.createColorSuccess(newColor))
    toast.success(message);
  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error);
    toast.error(React.createElement('pre', null, message));
    // toast.error(<pre>{message}</pre> as React.ReactNode);
    // toast.error(<pre>{message}</pre>);
    // toast.error(message);
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* updateColor(action: dataTypes.UpdateColorAction): Generator<any, void, any> {
  const { currentColorId, colorData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.put, `${baseUrl}/api/shop/colors/${currentColorId}`, colorData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const updatedColor = response.data.color;
    const message = response.data.message || "color updated successfully.";

    yield put(actions.updateColorSuccess(currentColorId,updatedColor))
    toast.success(message);

  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error);
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchColorSagas() {
  yield takeLatest(types.FETCH_COLOR_REQUEST, fetchColors);
  yield takeLatest(types.DELETE_COLOR_REQUEST, deleteColor);
  yield takeLatest(types.CREATE_COLOR_REQUEST, createColor);
  yield takeLatest(types.UPDATE_COLOR_REQUEST, updateColor);
}
