import * as actionTypes from "./eventCategoryActionTypes";
import * as dataTypes from "./eventCategoryDataTypes";

// For fetching event categories
export const fetchEventCategoryRequest = (payload: dataTypes.FetchEventCategoryPayload) => ({
    type: actionTypes.FETCH_EVENT_CATEGORY_REQUEST,
    payload,
});

export const fetchEventCategorySuccess = (data: dataTypes.Category[]) => ({
    type: actionTypes.FETCH_EVENT_CATEGORY_SUCCESS,
    payload: data,
});
