import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "./subCategoryActionTypes";
import axios from "axios";
import baseUrl from "../../config/apiConfig";
import * as dataTypes from "./subCategoryTypes";
import * as actions from "./subCategoryActions";
import { apiErrorHandler } from "../apiErrorHandler";
import { toast } from "react-toastify";
import { logout } from "../auth/actions";
import React from "react";

function* fetchSubCategories(action: dataTypes.FetchSubCategoryAction): Generator<any, void, any> {
  const { setLoading, navigate } = action.payload;
  try {
    setLoading(true);

    const response = yield call(axios.get, `${baseUrl}/api/shop/subcategories`);
    yield put(actions.fetchSubCategorySuccess(response.data))
    
    setLoading(false);
  } catch (error: any) {
    setLoading(false);

    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* deleteSubCategory(action: dataTypes.DeleteSubCategoryAction): Generator<any, void, any> {
  const { id, token, navigate } = action.payload;
  try {
    const response = yield call(axios.delete, `${baseUrl}/api/shop/subcategories/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const message = response.data.message || "sub category deleted successfully.";
    yield put(actions.deleteSubCategorySuccess(id));
    toast.success(message);

  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error)
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* createSubCategory(action: dataTypes.CreateSubCategoryAction): Generator<any, void, any> {
  const { subCategoryData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.post, `${baseUrl}/api/shop/subcategories`, subCategoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const newSubCategory = response.data.subcategory;
    const message = response.data.message || "sub category created successfully.";
    yield put(actions.createSubCategorySuccess(newSubCategory))
    toast.success(message);

  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error);
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}


function* updateSubCategory(action: dataTypes.UpdateSubCategoryAction): Generator<any, void, any> {
  const { currentSubCategoryId, subCategoryData, token, navigate } = action.payload;
  try {
    const response = yield call(axios.put, `${baseUrl}/api/shop/subcategories/${currentSubCategoryId}`, subCategoryData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const UpdatedSubCategory = response.data.subcategory;
    const message = response.data.message || "sub category updated successfully.";
    yield put(actions.updateSubCategorySuccess(currentSubCategoryId, UpdatedSubCategory))
    toast.success(message);

  } catch (error: any) {
    const { message, navigateTo } = apiErrorHandler(error);
    toast.error(React.createElement('pre', null, message));
    if (navigateTo) {
      if (navigateTo === "login") {
        yield put(logout());
      }
      if (navigateTo === "access-denied") {
        navigate(`/${navigateTo}`);
      }
    }
  }
}

export default function* watchSubCategorySagas() {
  yield takeLatest(types.FETCH_SUB_CATEGORY_REQUEST, fetchSubCategories);
  yield takeLatest(types.DELETE_SUB_CATEGORY_REQUEST, deleteSubCategory);
  yield takeLatest(types.CREATE_SUB_CATEGORY_REQUEST, createSubCategory);
  yield takeLatest(types.UPDATE_SUB_CATEGORY_REQUEST, updateSubCategory);

}


