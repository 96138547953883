import * as actionTypes from "./actionTypes";
import * as dataTypes from "./dataTypes";

// for fetching community posts
export const fetchCommunityPostsRequest = (payload: dataTypes.FetchCommunityPostsPayload) => ({
    type: actionTypes.FETCH_COMMUNITY_POSTS_REQUEST,
    payload,
});
export const fetchCommunityPostsSuccess = (data: dataTypes.Post[]) => ({
    type: actionTypes.FETCH_COMMUNITY_POSTS_SUCCESS,
    payload: data,
});


// for creating community post
export const createCommunityPostRequest = (payload: dataTypes.CreateCommunityPostPayload) => ({
    type: actionTypes.CREATE_COMMUNITY_POST_REQUEST,
    payload,
});


// get community post detail
export const getCommunityPostDetailRequest = (payload: dataTypes.GetCommunityPostDetailPayload) => ({
    type: actionTypes.GET_COMMUNITY_POST_DETAIL_REQUEST,
    payload,
});
export const getCommunityPostDetailSuccess = (data: dataTypes.PostDetail) => ({
    type: actionTypes.GET_COMMUNITY_POST_DETAIL_SUCCESS,
    payload: data,
});


// delete community post
export const deleteCommunityPostRequest = (payload: dataTypes.DeleteCommunityPostPayload) => ({
    type: actionTypes.DELETE_COMMUNITY_POST_REQUEST,
    payload,
});
export const deleteCommunityPostSuccess = (postId: number) => ({
    type: actionTypes.DELETE_COMMUNITY_POST_SUCCESS,
    payload: { postId },
});


// delete comment in post
export const deleteCommentInPostRequest = (payload: dataTypes.DeleteCommentInPostPayload) => ({
    type: actionTypes.DELETE_COMMENT_IN_POST_REQUEST,
    payload,
});
export const deleteCommentInPostSuccess = (commentId: number) => ({
    type: actionTypes.DELETE_COMMENT_IN_POST_SUCCESS,
    payload: { commentId },
});


// get community posts by author
export const getCommunityPostsByAuthorRequest = (payload: dataTypes.GetCommunityPostsByAuthorPayload) => ({
    type: actionTypes.GET_COMMUNITY_POSTS_BY_AUTHOR_REQUEST,
    payload,
});
export const getCommunityPostsByAuthorSuccess = (data: dataTypes.Post[]) => ({
    type: actionTypes.GET_COMMUNITY_POSTS_BY_AUTHOR_SUCCESS,
    payload: data,
});