import { produce } from "immer";
import * as types from "./wishlistActionTypes";
// import * as dataTypes from "./wishlistDataTypes";

const initialState: any = {
  wishlists: [],
};

const wishlistReducer = produce((state, action) => {
  switch (action.type) {

    case types.GET_WISHLISTS_SUCCESS:
      state.wishlists = action.payload;
      break;

    case types.DELETE_WISHLIST_SUCCESS:
      state.wishlists = state.wishlists.filter(
        (item: { productId: any }) =>
          item.productId !== action.payload.productId
      );
      break;

    case types.CREATE_WISHLIST_SUCCESS:
      state.wishlists = [...state.wishlists, action.payload.newWishlist];
      break;

    default:
      break;
  }
}, initialState);

export default wishlistReducer;