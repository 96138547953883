import { produce } from "immer";
import * as types from "./eventCategoryActionTypes";
import * as dataTypes from "./eventCategoryDataTypes";

const initialState: dataTypes.InitialStateEventCategory = {
  eventCategory: [],
};

const eventCategoryReducer = produce((state, action) => {
  switch (action.type) {
    
    case types.FETCH_EVENT_CATEGORY_SUCCESS:
      state.eventCategory = action.payload;
      break;

    default:
      break;
  }
}, initialState);

export default eventCategoryReducer;
